<div
    (click)="onContextClick.emit(true)"
    [ngStyle]="{
        color: (context.user.type | clientStatus)?.fg,
        background: (context.user.type | clientStatus)?.bg,
        border: (context.user.type | clientStatus)?.border
    }"
    [matTooltip]="(context.user.type | clientStatus)?.label"
    class="context user"
    *ngIf="context.user; else customerCheck"
>
    {{ context.user.fname }} {{ context.user.lname }}
</div>

<ng-template #customerCheck>
    <div
        [ngStyle]="{
            color: (context.customer.type | clientStatus)?.fg,
            background: (context.customer.type | clientStatus)?.bg,
            border: (context.customer.type | clientStatus)?.border
        }"
        (click)="onContextClick.emit(true)"
        [matTooltip]="(context.customer.type | clientStatus)?.label"
        class="context customer"
        *ngIf="context.customer; else resourceCheck"
    >
        {{ context.customer.name }}
    </div>
</ng-template>
<ng-template #resourceCheck>
    <div (click)="onContextClick.emit(true)" class="context" *ngIf="context.resource; else taskCheck">
        {{ context.resource.title }}
    </div>
</ng-template>
<ng-template #taskCheck>
    <div (click)="onContextClick.emit(true)" class="context" *ngIf="context.task; else none">
        {{ context.task.body }}
    </div>
</ng-template>
<ng-template #none>
    <div class="none">None</div>
</ng-template>
