import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Resource, ResourceService } from '@klickdata/core/resource';
import { Filter, GlobalFilterProperty, SelectFilterOption } from '@klickdata/core/table';
import { Utils } from '@klickdata/core/util';
import { BehaviorSubject, Observable, Subject, combineLatest, map, merge, startWith, switchMap, tap } from 'rxjs';

@Component({
    selector: 'app-resources-sub-grid-base',
    templateUrl: './resources-sub-grid-base.component.html',
    styleUrls: ['./resources-sub-grid-base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourcesSubGridBaseComponent implements AfterViewInit {
    @Input() tableFilterItems: Filter<any>[];
    @Input() showOccasion: boolean;
    @Input() guestMode: boolean;
    @Input() hasThumbnailHoverEffect: boolean;
    @Input() label: string;
    @Input() resBlockWidth: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    @Input() lastPaginatorPage: PageEvent;
    @Input() pageEvent: Subject<PageEvent>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    GlobalFilterProperty = GlobalFilterProperty;
    public appliedFilters: Subject<Filter<any>[]> = new Subject<Filter<any>[]>();
    public sectionSortingOptions: SelectFilterOption[];
    public resources$: Observable<Resource[]>;
    @Output() onPaginatorPageChange: EventEmitter<{ pageSize: number; pageIndex: number }> = new EventEmitter<{
        pageSize: number;
        pageIndex: number;
    }>();
    @Output() onEmptyResources: EventEmitter<boolean> = new EventEmitter<boolean>();
    constructor(protected resourceService: ResourceService, protected cdr: ChangeDetectorRef) {
        this.sectionSortingOptions = Utils.getSectionsSortingOptions();
    }

    ngAfterViewInit(): void {
        this.resources$ = combineLatest([
            merge(this.pageEvent.asObservable(), this.paginator.page),
            this.appliedFilters.asObservable().pipe(startWith([])),
        ]).pipe(
            switchMap(([pageEvent, filters]) => {
                this.lastPaginatorPage = pageEvent;
                return (
                    this.guestMode
                        ? this.resourceService.getGuestResourcesByFilters(
                              [
                                  ...filters,
                                  ...this.tableFilterItems,
                                  { property: 'page', items: [pageEvent.pageIndex] },
                                  { property: 'short_name', items: ['kunskap'] },
                              ],
                              pageEvent.pageSize
                          )
                        : this.resourceService.getResourcesByFilters(
                              [
                                  ...filters,
                                  ...this.tableFilterItems,
                                  { property: 'page', items: [pageEvent.pageIndex] },
                              ],
                              pageEvent.pageSize
                          )
                ).pipe(tap((response) => this.handleOnEmptyResource(response.data.length, filters)));
            }),
            tap((response) => {
                this.lastPaginatorPage.length = response.total_count;
                this.updatePaginator(this.lastPaginatorPage);
            }),
            map((response) => response.data),
            tap(() => setTimeout(() => this.cdr.detectChanges()))
        );
    }

    private updatePaginator(page: PageEvent) {
        this.paginator.length = page.length;
        this.paginator.pageIndex = page.pageIndex;
        this.paginator.pageSize = page.pageSize;

        const pageOptions = [];

        for (let i = 1; i <= 3; i++) {
            const sizeOption = page.pageSize * i;
            if (sizeOption < page.length) {
                pageOptions.push(sizeOption);
            }
        }
        pageOptions.push(page.length);

        this.paginator.pageSizeOptions = pageOptions;

        // #161 Hack to fix when current paginator page more then total pages paginator doesn't make correction by default.
        // https://github.com/angular/material2/issues/5812
        // https://github.com/angular/material2/issues/10227
        if (this.paginator.pageIndex !== 0 && this.paginator.pageIndex >= this.paginator.getNumberOfPages()) {
            this.paginator.previousPage();
        }
        this.cdr.detectChanges();
    }
    private handleOnEmptyResource(resourcesCount: number, filters: Filter<any>[]) {
        const filtersAreNotEmpty = filters.find((filter) => !!filter?.items?.length && filter.property);
        if (resourcesCount == 0 && !filtersAreNotEmpty) {
            this.onEmptyResources.emit(true);
        }
    }
}
