<div *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start start" fxLayoutGap="5px">
        <mat-icon>fact_check</mat-icon>
        <div>Are you done?</div>
    </div>
    <div class="desc" i18n>Do you want to mark this material as done ?</div>
    <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
        <button
            fxFlex="0 0 auto"
            class="mobile-button positive"
            (click)="auDoneMaterial = true; doneBtnClicked = true"
            mat-button
            i18n
        >
            Yes, I'm done
        </button>
        <button fxFlex="0 0 auto" class="mobile-button negative" (click)="onClose.emit()" mat-button i18n>No</button>
    </div>
    <ng-container *ngIf="auDoneMaterial">
        <div fxLayoutGap="20px" fxLayout="column" class="mt-1">
            <span i18n>What do you want to do next ?</span>
            <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
                <button
                    fxFlex="0 0 auto"
                    class="mobile-button positive"
                    (click)="
                        onClose.emit({
                            data: {
                                type: navData.data.type,
                                response: TakeResActionValue.CONTINUE
                            }
                        })
                    "
                    mat-button
                    i18n
                >
                    Continue
                </button>
                <button
                    *ngIf="canGoToNextMandatory"
                    fxFlex="0 0 auto"
                    class="mobile-button neg-btn"
                    (click)="
                        onClose.emit({
                            data: {
                                type: navData.data.type,
                                response: TakeResActionValue.GO_TO_NEXT_MANDATORY
                            }
                        })
                    "
                    mat-button
                    i18n
                >
                    Go to next mandatory
                </button>
                <button
                    fxFlex="0 0 auto"
                    class="mobile-button negative"
                    (click)="
                        onClose.emit({
                            data: {
                                type: navData.data.type,
                                response: TakeResActionValue.CONTINUE_LATER
                            }
                        })
                    "
                    mat-button
                    i18n
                >
                    Continue later
                </button>
            </div>
        </div>
    </ng-container>
    <ng-container *ngFor="let action of navData.data.actions">
        <ng-container *ngIf="doneBtnClicked">
            <div
                *ngIf="action.customBtn && navData.data.showCustomBtnAfterClickDone"
                fxLayoutGap="20px"
                fxLayout="column"
                class="mt-1"
            >
                <span *ngIf="action.specs?.desc">{{ action.specs.desc }}</span>
                <div *ngIf="action.specs.hasFeedback" class="input-field-parent" fxLayout="column" fxLayoutGap="5px">
                    <mat-form-field cdkFocusInitial appearance="outline" floatLabel="never" class="w-100" fxFlex="grow">
                        <textarea
                            #taskInput
                            matInput
                            type="text"
                            [formControl]="feedback"
                            placeholder="Write feedback to admin"
                            i18n-placeholder
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="4"
                            cdkAutosizeMaxRows="10"
                        ></textarea>
                    </mat-form-field>
                </div>
                <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <button
                        fxFlex="0 0 auto"
                        class="mobile-button positive {{ action.class }}"
                        (click)="
                            onClose.emit({
                                data: {
                                    type: navData.data.type,
                                    response: action.value,
                                    feedback: feedback.value
                                }
                            })
                        "
                        mat-button
                    >
                        {{ action.label }}
                    </button>
                </div>
            </div>
        </ng-container>
        <div
            *ngIf="action.customBtn && !navData.data.showCustomBtnAfterClickDone"
            fxLayoutGap="20px"
            fxLayout="column"
            class="mt-1"
        >
            <span *ngIf="action.specs.desc">{{ action.specs.desc }}</span>
            <div *ngIf="action.specs.hasFeedback" class="input-field-parent" fxLayout="column" fxLayoutGap="5px">
                <mat-form-field cdkFocusInitial appearance="outline" floatLabel="never" class="w-100" fxFlex="grow">
                    <textarea
                        #taskInput
                        matInput
                        type="text"
                        [formControl]="feedback"
                        placeholder="Write feedback to admin"
                        i18n-placeholder
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="4"
                        cdkAutosizeMaxRows="10"
                    ></textarea>
                </mat-form-field>
            </div>
            <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
                <button
                    fxFlex="0 0 auto"
                    class="mobile-button positive {{ action.class }}"
                    (click)="
                        onClose.emit({
                            data: {
                                type: navData.data.type,
                                response: action.value,
                                feedback: feedback.value
                            }
                        })
                    "
                    mat-button
                >
                    {{ action.label }}
                </button>
            </div>
        </div>
    </ng-container>
</div>
