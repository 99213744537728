import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { LanguageService } from '@klickdata/core/localization';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { Language } from '@klickdata/core/localization/src/language';
import { BehaviorSubject, combineLatest, Observable, of, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-language-viewier',
    templateUrl: './language-viewier.component.html',
    styleUrls: ['./language-viewier.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageViewierComponent extends OnDestroyHandler implements OnInit {
    @Input() languageId: number | string;
    @Input() showLabel: boolean;
    public currentLanguage: BehaviorSubject<Language> = new BehaviorSubject<Language>(null);

    constructor(protected localizationService: LanguageService, protected cdr: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {
        if (this.languageId) {
            this.localizationService
                .getLanguageByKey(this.languageId)
                .pipe(takeUntil(this.destroy))
                .subscribe((language) => {
                    this.currentLanguage.next(language);
                    this.cdr.detectChanges();
                });
        }
    }
}
