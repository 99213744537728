<div *ngIf="navData" fxLayoutGap="10px" class="data-wrap pt-0" fxLayout="column">
    <div class="title mt-0" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
        <mat-icon class="title-icon {{ navData.data.class }}">{{ navData.data.icon }}</mat-icon>
        <div class="title-label {{ navData.data.class }}" fxFlex="grow">{{ navData.data.title }}</div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <app-image-view
            [ngStyle]="{ minWidth: '50px' }"
            [isCircularImg]="true"
            [media_id]="navData.data.enrollment?.applicant?.media"
            paddingBottom="50px"
        ></app-image-view>
        <div fxLayout="column" fxLayoutAlign="center start">
            <span class="small">{{ navData.data.enrollment?.applicant?.name }}</span>
            <span class="dark-blue small">{{ navData.data.resource.title }}</span>
            <span
                *ngIf="navData.data.enrollment?.requested_at"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="3px"
                class="small signoff-date"
                ><span i18n>Requested at</span>
                <span>{{ navData.data.enrollment.requested_at | kdDateFormat : 'YYYY-MM-DD' }}</span></span
            >
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="5px" *ngIf="navData.data.applicantMsg">
        <span i18n>Message:</span>
        <span class="user-msg">{{ navData.data.applicantMsg }}</span>
    </div>
    <div class="input-field-parent mt-1" fxLayout="column" fxLayoutGap="5px">
        <mat-form-field cdkTrapFocus appearance="outline" floatLabel="never" class="w-100" fxFlex="grow">
            <textarea
                cdkFocusInitial
                #taskInput
                matInput
                type="text"
                [formControl]="value"
                placeholder="Message to the applicant"
                i18n-placeholder
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="3"
                cdkAutosizeMaxRows="50"
                required
            ></textarea>
        </mat-form-field>
    </div>
</div>
<ng-container [ngTemplateOutlet]="actions"></ng-container>
<ng-template #actions>
    <div class="mb-1" fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="5px">
        <button
            fxFlex="0 0 auto"
            class="sub-btn rej"
            (click)="
                onClose.emit({
                    data: {
                        type: navData.data.type,
                        approved: false,
                        message: !!value.value && !!value.value.length ? { body: value.value } : null
                    }
                })
            "
            mat-button
        >
            <mat-icon>cancel</mat-icon>
            <span i18n>Reject</span>
        </button>
        <button
            fxFlex="0 0 auto"
            class="sub-btn"
            (click)="
                onClose.emit({
                    data: {
                        type: navData.data.type,
                        approved: true,
                        message: !!value.value && !!value.value.length ? { body: value.value } : null
                    }
                })
            "
            mat-button
        >
            <mat-icon>assignment_turned_in</mat-icon>
            <span i18n>Approve</span>
        </button>
    </div>
</ng-template>
