<div *ngIf="customerLanding$ | async; let customerLanding; else: loading" class="login" fxLayout="column">
    <ng-container *ngIf="displayMode === 'login'">
        <app-login-form [isDialogMode]="true" (onLoginSuccess)="onLoginOrSignupSuccess()"></app-login-form>
        <p *ngIf="customerLanding.enable_anonymous_register" class="pointer red mt-0 register">
            <strong>
                <span class="pointer mt-0 red" i18n (click)="displayMode = 'signup'"> Create account </span>
            </strong>
        </p>
    </ng-container>
    <ng-container *ngIf="displayMode === 'signup'">
        <app-signup-form [isDialogMode]="true" (onSignupSuccess)="onLoginOrSignupSuccess()"></app-signup-form>
        <p *ngIf="customerLanding.enable_anonymous_register" class="pointer red mt-0 register">
            <strong>
                <span class="pointer mt-0 red" i18n (click)="displayMode = 'login'"> Already has account </span>
            </strong>
        </p>
    </ng-container>
</div>
<ng-template #loading>
    <app-kd-logo-loading></app-kd-logo-loading>
</ng-template>
