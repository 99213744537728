<div *ngIf="user" class="main-wrapper">
    <div class="top-bg-wrapper">
        <div class="lay-over"></div>
        <app-image-view
            [paddingBottom]="'450px'"
            [media_id]="(resource.medias?.background?.length && resource.medias?.background[0]) || resource.media"
        >
        </app-image-view>
    </div>
    <div class="main-content-wrapper" fxLayout="row" fxLayoutAlign="space-between start">
        <div class="left-part" fxLayout="column">
            <div class="kd-lib">
                <ng-container [ngTemplateOutlet]="academyLogo"></ng-container>
            </div>
            <app-image-view [media_id]="resource.media$"></app-image-view>
            <div class="res-items mt-1">
                <ng-content select="[itemsHeader]"></ng-content>
                <ng-content select="[resourceItemsSection]"></ng-content>
            </div>
        </div>
        <div class="right-part" fxLayout="column" fxLayoutGap="1.2em">
            <div class="upper-part" fxLayoutAlign="space-between start" fxLayout="row">
                <div class="title-wrapper" fxLayout="column" fxLayoutAlign="center start">
                    <h2>
                        {{ resource.title }}
                    </h2>
                    <div class="publishedBy" *ngIf="resource.publishers && resource.publishers?.length">
                        <span i18n>Published by</span>
                        <span class="publisher">{{ resource.publishers[0].name }}</span>
                    </div>

                    <span *ngIf="resource.bullets" class="bullets">{{ resource.bullets }}</span>
                </div>
                <div class="action-items-wrapper">
                    <div
                        class="main-action-wrapper"
                        fxLayoutGap="0.4em"
                        fxLayout="column"
                        fxLayoutAlign="space-between start"
                    >
                        <button *ngIf="!resourceIsAvailable" class="take invalid" mat-raised-button>
                            <mat-icon>event_busy</mat-icon> <span>{{ resourceNotAvailableLabel }}</span>
                        </button>

                        <button
                            *ngIf="resourceIsAvailable"
                            (click)="takeResource(resource, user)"
                            class="take"
                            mat-raised-button
                        >
                            <mat-icon>play_circle</mat-icon>
                            <ng-container [ngTemplateOutlet]="actionBtn"></ng-container>
                        </button>

                        <ng-container *ngIf="customer">
                            <div
                                *ngIf="customer.license_type !== 'sitelicense' && resource.price && !resource.purchased"
                                class="price-wrap"
                            >
                                {{ resource.price }}&nbsp;{{ resource.currency }}
                            </div>

                            <div
                                class="free-price-wrapper"
                                *ngIf="resource.price && customer.license_type === 'sitelicense'"
                            >
                                <div fxLayout="row" fxLayoutAlign="center center">
                                    <div class="price-wrap">FREE!</div>
                                    <button
                                        [ngClass]="{ flip: showOriginalPrice }"
                                        class="event-btn"
                                        mat-icon-button
                                        (click)="showOriginalPrice = !showOriginalPrice"
                                    >
                                        <mat-icon>expand_less</mat-icon>
                                    </button>
                                </div>
                                <div fxLayout="column" fxLayoutAlign="center center" *ngIf="showOriginalPrice">
                                    <span>Free for Site license clients</span>
                                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                                        <span> Ordninary price:</span
                                        ><span>{{ resource.price }}&nbsp;{{ resource.currency }}</span>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="free-price-wrapper" *ngIf="resource.price && resource.purchased">
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <div class="price-wrap">Purchased!</div>
                                <button
                                    [ngClass]="{ flip: showOriginalPrice }"
                                    class="event-btn"
                                    mat-icon-button
                                    (click)="showOriginalPrice = !showOriginalPrice"
                                >
                                    <mat-icon>expand_less</mat-icon>
                                </button>
                            </div>
                            <div fxLayout="column" fxLayoutAlign="center center" *ngIf="showOriginalPrice">
                                <!-- <span>Free for being purchased</span> -->
                                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                                    <span> Ordninary price:</span
                                    ><span>{{ resource.price }}&nbsp;{{ resource.currency }}</span>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="item" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                            <mat-icon>flag</mat-icon>
                            <div fxFlex="grow" class="item-title" fxLayout="row" fxLayoutAlign="space-between center">
                                <span>
                                    {{ resourceLabel }}
                                    <span i18n="@@Language">Language </span>
                                </span>
                                <app-language-selector
                                    [language_id]="resource.language_id"
                                    class="item-lang-flag"
                                    fxFlex="0 0 auto"
                                ></app-language-selector>
                            </div>
                        </div> -->
                        <!-- <div
                            *ngIf="resource.staff?.publisher && resource.staff?.publisher[0]"
                            class="item"
                            fxLayout="row"
                            fxLayoutAlign="start center"
                            fxLayoutGap="5px"
                        >
                            <mat-icon matTooltip="Publisher" i18n-matTooltip>attribution</mat-icon>
                            <div fxFlex="grow" class="item-title" fxLayout="row" fxLayoutAlign="space-between center">
                                <ng-container
                                    *ngIf="$any(resource.publishers$ | async); let publishers; else: smallLoading"
                                >
                                    <span class="publisher" matTooltip="Publisher" i18n-matTooltip>{{
                                        publishers[0].name
                                    }}</span>
                                </ng-container>
                            </div>
                        </div> -->
                        <ng-content select="[resourceDates]"></ng-content>
                        <!-- <ng-container [ngTemplateOutlet]="circles"></ng-container> -->
                    </div>
                </div>
            </div>
            <div class="lower-part" fxLayoutAlign="space-between start" fxLayoutGap="2em" fxLayout="row">
                <div class="lower-left">
                    <div fxLayout="column" *ngIf="resource.description">
                        <div fxLayoutAlign="start center" fxLayoutGap="5px" fxLayout="row" class="title">
                            <mat-icon>description</mat-icon>
                            <span>
                                {{ resourceLabel }}
                                <span i18n="@@description">description</span>
                            </span>
                        </div>
                        <div class="info" [innerHtml]="resource.description | innerHtml : 'info'"></div>
                    </div>
                    <div fxLayout="column" *ngIf="resource.goal">
                        <div fxLayoutAlign="start center" fxLayoutGap="5px" fxLayout="row" class="title">
                            <mat-icon>task_alt</mat-icon>
                            <span>
                                {{ resourceLabel }}
                                <span i18n="@@goal">goal</span>
                            </span>
                        </div>
                        <div class="info" [innerHtml]="resource.goal | innerHtml : 'info'"></div>
                    </div>
                    <div fxLayout="column" *ngIf="resource.audience">
                        <div fxLayoutAlign="start center" fxLayoutGap="5px" fxLayout="row" class="title">
                            <mat-icon>ads_click</mat-icon>
                            <span>
                                {{ resourceLabel }}
                                <span i18n>audience</span>
                            </span>
                        </div>
                        <span class="info">{{ resource.audience }} </span>
                    </div>
                    <div fxLayout="column" *ngIf="resource.episode">
                        <div fxLayoutAlign="start center" fxLayoutGap="5px" fxLayout="row" class="title">
                            <mat-icon>calendar_view_month</mat-icon>
                            <span>
                                {{ resourceLabel }}
                                <span i18n>occasions</span>
                            </span>
                        </div>
                        <span class="info">{{ resource.episode }} </span>
                    </div>
                    <div fxLayout="column" *ngIf="resource.other_info">
                        <div fxLayoutAlign="start center" fxLayoutGap="5px" fxLayout="row" class="title">
                            <mat-icon>info</mat-icon>
                            <span>
                                {{ resourceLabel }}
                                <span i18n="@@otherInformation">other information</span>
                            </span>
                        </div>
                        <div class="info" [innerHtml]="resource.other_info | innerHtml : 'info'"></div>
                    </div>
                    <div fxLayout="column" *ngIf="resource.instructions">
                        <div fxLayoutAlign="start center" fxLayoutGap="5px" fxLayout="row" class="title">
                            <mat-icon>subject</mat-icon>
                            <span>
                                {{ resourceLabel }}
                                <span i18n="@@instruction">instruction</span>
                            </span>
                        </div>
                        <div class="info" [innerHtml]="resource.instructions | innerHtml : 'info'"></div>
                    </div>
                    <div fxLayout="column" *ngIf="resource.pre_skills">
                        <div fxLayoutAlign="start center" fxLayoutGap="5px" fxLayout="row" class="title">
                            <mat-icon>tips_and_updates</mat-icon>
                            <span>
                                {{ resourceLabel }}
                                <span i18n="@@preskills">preskills</span>
                            </span>
                        </div>
                        <div class="info" [innerHtml]="resource.pre_skills | innerHtml : 'info'"></div>
                    </div>
                    <div
                        fxLayout="column"
                        *ngIf="resource.medias?.screenshot?.length > 0 && resource.medias?.screenshot[0]"
                    >
                        <div fxLayoutAlign="start center" fxLayoutGap="5px" fxLayout="row" class="title">
                            <mat-icon>center_focus_strong</mat-icon>
                            <span>
                                <span i18n="@@screenshotImage">Screenshot image</span>
                            </span>
                        </div>
                        <div class="screenshoot-img-wrapper">
                            <app-image-view
                                (click)="showImgModal(resource.medias?.screenshot[0])"
                                [media_id]="resource.medias?.screenshot[0]"
                                [proportional]="false"
                            >
                            </app-image-view>
                        </div>
                    </div>
                    <div *ngIf="showQuotesItems && user" fxLayout="column">
                        <div fxLayoutAlign="start center" fxLayoutGap="5px" fxLayout="row" class="title">
                            <mat-icon>format_quote</mat-icon>
                            <span i18n>What learners say? </span>
                        </div>

                        <div id="sliderWrapper" class="quotes-slider-wrapper">
                            <owl-carousel-o class="cd-carousel" [options]="customOptions">
                                <ng-container *ngFor="let quote of quotes">
                                    <ng-template carouselSlide [id]="quote.id.toString()" [width]="scrolledItemWidth">
                                        <app-image-view
                                            class="quote-img"
                                            [ngStyle]="{ minWidth: '80px' }"
                                            [isCircularImg]="true"
                                            [media_id]="quote.quotee.media"
                                            paddingBottom="72px"
                                        ></app-image-view>
                                        <div class="quote-wrapper" fxLayout="column" fxLayoutAlign="center center">
                                            <span class="name">{{ quote.quotee.name }}</span>
                                            <mat-icon>format_quote</mat-icon>
                                            <div>{{ quote.quote }}</div>
                                            <p class="date">{{ quote.created_at | kdDateFormat : 'MMM D, YYYY' }}</p>
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </owl-carousel-o>
                        </div>
                    </div>
                </div>
                <div class="lower-right">
                    <div *ngIf="!!resource.managers?.length" class="tutor-wrapper">
                        <ng-container
                            [ngTemplateOutlet]="author"
                            [ngTemplateOutletContext]="{
                                staff: resource.managers
                            }"
                        ></ng-container>
                    </div>
                    <ng-container [ngTemplateOutlet]="circles"></ng-container>
                    <div fxLayoutAlign="center center" class="social-icons">
                        <button
                            class="grey"
                            matTooltip="Add to favorite"
                            i18n-matTooltip="@@addToFavorite"
                            (click)="favoriteResource(resource)"
                            mat-icon-button
                            fxFlex="0 0 auto"
                        >
                            <mat-icon>{{ resource.favorite ? 'star' : 'star_border' }}</mat-icon>
                        </button>
                        <!-- <button
                            class="grey"
                            matTooltip="Like"
                            i18n-matTooltip="@@like"
                            (click)="likeResource(resource)"
                            mat-icon-button
                            fxFlex="0 0 auto"
                        >
                            <mat-icon>{{ resource.like ? 'favorite' : 'favorite_border' }}</mat-icon>
                        </button> -->
                        <!-- Share btns here -->
                        <ng-container
                            *ngIf="resource.last_publish"
                            [ngTemplateOutlet]="shareTemp"
                            [ngTemplateOutletContext]="{ resource: resource }"
                        ></ng-container>
                        <button
                            *ngIf="resource.downloads"
                            class="grey"
                            matTooltip="Download"
                            i18n-matTooltip="@@download"
                            (click)="download(resource.downloads)"
                            mat-icon-button
                            fxFlex="0 0 auto"
                        >
                            <mat-icon>save_alt</mat-icon>
                        </button>
                        <ng-container
                            *ngIf="resource.published"
                            [ngTemplateOutletContext]="{ resource: resource }"
                            [ngTemplateOutlet]="inviteBtn"
                        ></ng-container>
                        <button
                            *ngIf="
                                resource.user_collaboration?.tutor &&
                                user.role_value !== 'user' &&
                                user.role_value !== 'guest' &&
                                user.canAssign(resource)
                            "
                            matTooltip="Assign"
                            i18n-matTooltip="@@assign"
                            (click)="assign(resource)"
                            mat-icon-button
                            fxFlex="0 0 auto"
                            class="green"
                        >
                            <mat-icon *ngIf="!resource.assign_id">assignment_ind</mat-icon>
                            <img
                                *ngIf="resource.assign_id"
                                src="assets/images/edit-assign-green.svg"
                                width="20"
                                alt="Edit Assign"
                            />
                        </button>
                        <ng-container>
                            <div fxFlex="0 0 auto" *ngIf="resource.user_collaboration?.edit && user.canEdit(resource)">
                                <button
                                    (click)="editResource(resource, user.isAdmin())"
                                    matTooltip="Edit"
                                    i18n-matTooltip="edit"
                                    class="green"
                                    mat-icon-button
                                >
                                    <mat-icon>create</mat-icon>
                                </button>
                            </div>
                        </ng-container>
                    </div>

                    <div class="res-small-details">
                        <div class="details-elem">
                            <div class="pre">
                                <mat-icon>account_circle</mat-icon>
                                <span i18n>Created by </span>
                            </div>
                            <span class="normal"
                                >{{ resource.author_name || $any(resource.author$ | async)?.name }}
                            </span>
                        </div>
                        <div *ngIf="!!resource.educators?.length" class="details-elem">
                            <div class="pre">
                                <mat-icon>engineering</mat-icon>
                                <span i18n="@@educators">Educators</span>
                            </div>
                            <span class="normal">
                                <ng-container
                                    [ngTemplateOutlet]="staff"
                                    [ngTemplateOutletContext]="{
                                        staff: resource.educators,
                                        icon: 'engineering'
                                    }"
                                ></ng-container>
                            </span>
                        </div>
                        <div *ngIf="!!resource.hosts?.length" class="details-elem">
                            <div class="pre">
                                <mat-icon>record_voice_over</mat-icon>
                                <span i18n="@@hosts">Hosts</span>
                            </div>
                            <span class="normal">
                                <ng-container
                                    [ngTemplateOutlet]="staff"
                                    [ngTemplateOutletContext]="{
                                        staff: resource.hosts,
                                        icon: 'record_voice_over'
                                    }"
                                ></ng-container>
                            </span>
                        </div>
                        <div *ngIf="resource.organizers?.length" class="details-elem">
                            <div class="pre">
                                <mat-icon>person_4</mat-icon>
                                <span i18n="@@organizer">Organizer</span>
                            </div>
                            <span class="normal">
                                <ng-container
                                    [ngTemplateOutlet]="staff"
                                    [ngTemplateOutletContext]="{
                                        staff: resource.organizers,
                                        icon: 'person_4'
                                    }"
                                ></ng-container>
                            </span>
                        </div>
                        <div *ngIf="resource.tags_attached" class="details-elem">
                            <div class="pre">
                                <mat-icon>label</mat-icon>
                                <span i18n="@@tags">Tags </span>
                            </div>
                            <span class="normal">
                                <mat-chip-list class="mt-1">
                                    <mat-chip
                                        *ngFor="let tag of resourceTags$ | async"
                                        (click)="showTagResources(tag)"
                                        class="res-chip-item tag-chip"
                                        ><span>{{ tag.name }}</span>
                                        <app-language-selector
                                            class="flag tag-chip-lang-select"
                                            fxFlex="0 0 auto"
                                            [language_id]="tag.language_id"
                                        >
                                        </app-language-selector
                                    ></mat-chip>
                                </mat-chip-list>
                            </span>
                        </div>
                        <div *ngIf="resourceCategories$ | async; let cats" class="details-elem">
                            <div class="pre">
                                <mat-icon>apps</mat-icon>
                                <span i18n="@@categories">Categories </span>
                            </div>
                            <span class="normal">
                                <mat-chip-list class="mt-1">
                                    <mat-chip
                                        class="res-chip-item cat-chip"
                                        *ngFor="let cat of cats"
                                        (click)="showCatgeoryResources(cat)"
                                        >{{ cat.title }}</mat-chip
                                    >
                                </mat-chip-list>
                            </span>
                        </div>
                        <ng-content select="[resourceDuration]"></ng-content>
                        <div *ngIf="resource.time_limit" class="details-elem">
                            <div class="pre">
                                <mat-icon>timer</mat-icon>
                                <span i18n="@@playTime">Play time </span>
                            </div>
                            <span class="normal">{{ resource?.time_limit | formatIsoDuration }} </span>
                        </div>
                        <div *ngIf="resource.examination && resource.examination.label" class="details-elem">
                            <div class="pre">
                                <mat-icon>military_tech</mat-icon>
                                <span i18n="@@endReward">End reward </span>
                            </div>
                            <span class="normal">{{ resource.examination?.label }} </span>
                        </div>
                        <div *ngIf="resource.article_code" class="details-elem">
                            <div class="pre">
                                <mat-icon>center_focus_strong</mat-icon>
                                <ng-content select="[resCodeLabel]"></ng-content>
                            </div>
                            <span class="normal">{{ resource.article_code }} </span>
                        </div>
                        <div *ngIf="resource.total_seats" class="details-elem">
                            <div class="pre">
                                <mat-icon>event_seat</mat-icon>
                                <span i18n>Available seats</span>
                            </div>
                            <span class="normal">
                                {{ resource.total_seats }}
                            </span>
                        </div>
                        <div *ngIf="resource.extra_seats" class="details-elem">
                            <div class="pre">
                                <mat-icon>weekend</mat-icon>
                                <span i18n>Extra seats</span>
                            </div>
                            <span class="normal">
                                {{ resource.extra_seats }}
                            </span>
                        </div>
                        <div class="details-elem">
                            <div class="pre">
                                <mat-icon>qr_code_scanner</mat-icon>
                                <span i18n>Qr code </span>
                            </div>
                            <ngx-qrcode qrc-element-type="url" [value]="qrCodeData"> </ngx-qrcode>
                        </div>
                        <div
                            *ngIf="resource.quote_ids && user.role_value != 'guest'"
                            class="details-elem pt-0 pb-0"
                            fxLayoutAlign="space-between center"
                            fxLayout="row"
                        >
                            <div class="pre">
                                <mat-icon>format_quote</mat-icon>
                                <span i18n="@@quotes">Quotes</span>
                            </div>
                            <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px" class="normal">
                                <mat-slide-toggle (change)="toggleQuotes($event.checked)"></mat-slide-toggle>
                                <button class="quotes-show m-0" mat-icon-button (click)="showQuotesInSlide()">
                                    <mat-icon>view_list</mat-icon>
                                </button>
                            </span>
                        </div>
                        <div
                            *ngIf="user.role_value != 'guest'"
                            class="details-elem pt-0 pb-0"
                            fxLayoutAlign="space-between center"
                            fxLayout="row"
                        >
                            <div class="pre">
                                <mat-icon>history_toggle_off</mat-icon>
                                <span i18n>History</span>
                            </div>
                            <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px" class="normal">
                                <button class="quotes-show m-0" mat-icon-button (click)="showResLog()">
                                    <mat-icon>touch_app</mat-icon>
                                </button>
                            </span>
                        </div>
                        <!-- <div
                            *ngIf="
                                resource.medias?.exercise &&
                                !!resource.medias.exercise.length &&
                                resource.opportunity_id
                            "
                            class="details-elem pt-0 pb-0"
                            fxLayoutAlign="space-between center"
                            fxLayout="row"
                        >
                            <div class="pre">
                                <mat-icon>topic</mat-icon>
                                <span i18n>Exercise documents</span>
                            </div>
                            <span
                                fxLayout="row"
                                fxLayoutAlign="start center"
                                fxLayoutGap="4px"
                                class="normal relative pl-0"
                            >
                                <span class="doc-count">{{ resource.medias.exercise.length }}</span>
                                <button
                                    class="quotes-show m-0"
                                    mat-icon-button
                                    (click)="download(resource.exercise_downloads)"
                                >
                                    <mat-icon>file_download</mat-icon>
                                </button>
                            </span>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #academyLogo>
    <ng-container *ngIf="resource.public">
        <img src="assets/images/KD_KOL_logo_long_white.png" alt="KlickData" />
    </ng-container>
    <ng-container *ngIf="!resource.public">
        <ng-container *ngIf="!!resource.publishers?.length">
            <app-image-view
                class="publisher-logo-img"
                [scope]="AppScope.RESOURCES"
                [media_id]="resource.publishers[0].media$"
                [proportional]="false"
            >
            </app-image-view>
        </ng-container>
    </ng-container>
</ng-template>
<ng-template #smallLoading>
    <app-kd-logo-loading class="small-load-header" size="small"></app-kd-logo-loading>
</ng-template>
<ng-template #actionBtn>
    <span *ngIf="!resource.opportunity_id">
        <span *ngIf="resource.user_collaboration?.enroll_approval_required" i18n="@@enroll">Enroll</span>
        <span *ngIf="!resource.user_collaboration?.enroll_approval_required" i18n="@@start">Start</span>
    </span>
    <span *ngIf="resource.opportunity_id">
        <span i18n="@@continue">Continue</span>
    </span>
</ng-template>
<ng-template #staff let-staff="staff" let-icon="icon">
    <div class="member-wrapper" fxLayoutAlign="center center" fxLayout="column">
        <div fxFlex="row" fxLayoutAlign="start center" class="w-100" fxLayoutGap="10px">
            <ng-container *ngFor="let member of staff; let index = index">
                <ng-container *ngIf="index < 2">
                    <app-image-view
                        (click)="showManagers(staff)"
                        [ngClass]="{ 'img-comp': index === 1 }"
                        [scope]="AppScope.USERS"
                        [media_id]="member.media$"
                        [paddingBottom]="'62px'"
                    >
                    </app-image-view>
                </ng-container>
            </ng-container>
            <span class="remain-managers" (click)="showManagers(staff)" *ngIf="staff.length > 2"
                >+ {{ staff.length - 2 }}</span
            >
        </div>
        <div class="manager-name" (click)="showManagers(staff)">
            <span>{{ staff[0].name }}</span>
            <span *ngIf="staff.length > 1">&</span>
            <span *ngIf="staff.length > 1">{{ staff[1].name }}</span>
            <span *ngIf="staff.length > 2" i18n>& others</span>
        </div>
    </div>
</ng-template>
<ng-template #author let-staff="staff">
    <div class="member-wrapper author" fxLayoutAlign="center center" fxLayout="column">
        <div fxFlex="row" fxLayoutAlign="center center" class="w-100" fxLayoutGap="10px">
            <ng-container *ngFor="let member of staff; let index = index">
                <ng-container *ngIf="index < 2">
                    <app-image-view
                        (click)="showManagers(staff)"
                        [ngClass]="{ 'img-comp': index === 1 }"
                        [scope]="AppScope.USERS"
                        [media_id]="member.media$"
                        [paddingBottom]="'111px'"
                    >
                    </app-image-view>
                </ng-container>
            </ng-container>
            <span class="remain-managers" (click)="showManagers(staff)" *ngIf="staff.length > 2"
                >+ {{ staff.length - 2 }}</span
            >
        </div>
        <div class="instructor" fxLayoutGap="5px" fxLayoutAlign="center center">
            <mat-icon>engineering</mat-icon>
            <ng-content select="[managerLabel]"></ng-content>
        </div>

        <div class="manager-name" fxLayoutAlign="center center" (click)="showManagers(staff)">
            <span>{{ staff[0].name }}</span>
            <span *ngIf="staff.length > 1">&</span>
            <span *ngIf="staff.length > 1">{{ staff[1].name }}</span>
            <span *ngIf="staff.length > 2" i18n>& others</span>
        </div>
    </div>
</ng-template>

<ng-template #shareTemp let-resource="resource">
    <button
        *ngIf="!(shareLoading | async); else shareLoadTemp"
        class="grey"
        fxFlex="0 0 auto"
        matTooltip="Share"
        i18n-matTooltip="@@share"
        mat-icon-button
        (click)="shareService.openShareDialog(resource, shareLoading)"
    >
        <mat-icon>share</mat-icon>
    </button>
</ng-template>
<ng-template #inviteBtn let-resource="resource">
    <button
        class="green"
        matTooltip="Invite a friend"
        i18n-matTooltip
        (click)="invite(resource)"
        mat-icon-button
        fxFlex="0 0 auto"
    >
        <mat-icon>outgoing_mail</mat-icon>
    </button>
</ng-template>
<ng-template #circles>
    <div class="circle-info">
        <div class="circle-item" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
            <div class="icon-wrapper">
                <app-language-selector
                    [language_id]="resource.language_id"
                    class="item-lang-flag"
                    fxFlex="0 0 auto"
                ></app-language-selector>
            </div>
            <span i18n>Language</span>
        </div>
        <ng-content select="[resourceCircles]"></ng-content>
        <div
            *ngIf="resource.public"
            class="circle-item"
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayoutGap="10px"
        >
            <div class="icon-wrapper">
                <mat-icon class="font">public</mat-icon>
            </div>
            <span i18n>Public</span>
        </div>
    </div>
</ng-template>

<ng-template #shareLoadTemp>
    <app-kd-logo-loading size="small"></app-kd-logo-loading>
</ng-template>
