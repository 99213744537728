import { Injectable } from '@angular/core';
import { Resource } from './resource.model';
import { User } from '@klickdata/core/user';
import { ResourceItem } from '@klickdata/core/resource-item';
import { ResourceTypes } from './types.enum';
import { SignupFormComponent } from '@klickdata/guest/src/signup/signup-form/signup-form.component';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { takeUntil, filter, switchMap, map, of, Observable, BehaviorSubject, first, tap, EMPTY, merge } from 'rxjs';
import { ResourceService } from './resource.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@klickdata/core/auth';
import { LoginSignupDialogComponent } from '@klickdata/guest/src/login-signup-dialog/login-signup-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class StartResourceService extends OnDestroyHandler {
    public resource: BehaviorSubject<Resource> = new BehaviorSubject<Resource>(null);
    constructor(
        protected mobile: MobileService,
        protected resourceService: ResourceService,
        protected router: Router,
        protected location: Location,
        protected dialog: MatDialog,
        protected message: MessageService,
        protected auth: AuthService
    ) {
        super();
    }
    public takeResource(resource: Resource, user: User, item?: ResourceItem): Observable<Resource> {
        if (this.auth.getNK3PlatformValue() === 'guest') {
            return this.dialog
                .open(LoginSignupDialogComponent, {
                    panelClass: 'signUp-wrapper',
                    backdropClass: 'signUp-backdrop',
                    id: 'signupDialog',
                    closeOnNavigation: true,
                    data: {
                        resourceId: resource.id,
                    },
                })
                .afterClosed()
                .pipe(
                    takeUntil(this.destroy),
                    filter((result) => !!result),
                    switchMap(() => this.handleEnrollment(resource, item))
                );
        } else {
            return this.handleEnrollment(resource, item);
        }
    }
    private handleEnrollment(resource: Resource, item?: ResourceItem): Observable<Resource> {
        if (resource.opportunity_id) {
            return this.askForEnroll(resource, false);
        }
        const obs =
            resource.user_collaboration && resource.enrollment
                ? of(resource)
                : resource.user_collaboration
                ? this.resourceService
                      .getResource(resource.id, 'enrollment')
                      .pipe(tap((res) => (res.user_collaboration = resource.user_collaboration)))
                : resource.enrollment
                ? this.resourceService
                      .getResource(resource.id, 'user_collaboration')
                      .pipe(tap((res) => (res.enrollment = resource.enrollment)))
                : this.resourceService.getResource(resource.id, 'enrollment,user_collaboration');

        return obs.pipe(
            first(),
            switchMap((resource) => {
                const enroll_approval_required = resource.user_collaboration?.enroll_approval_required;
                if (enroll_approval_required && resource.enrollment) {
                    if (
                        resource.enrollment.approval_status == 'rejected' ||
                        resource.enrollment.approval_status == 'pending'
                    ) {
                        this.informApprovalStatus(resource);
                        return of(resource);
                    } else if (resource.enrollment.approval_status === 'approved') {
                        return this.onAdminApproved(resource, item);
                    }
                } else {
                    return this.askForEnroll(resource, enroll_approval_required, item);
                }
            })
        );
    }

    private onAdminApproved(resource: Resource, item?: ResourceItem): Observable<Resource> {
        const msgBody = resource.enrollment.admin?.message?.body;
        const resourceType = ResourceTypes.label(resource.type_id, { capitalize: false });

        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: 'task_alt',
                iconClass: 'green',
                titleClass: 'green',
                title: $localize`Enrollment Request approved`,
                contentBody: $localize`The administrator has approved your request to enroll in the ${resourceType} ${resource.title} \n Good luck !`,
                secContentBody: msgBody
                    ? '<span>' + $localize`The administrator message: ` + `</span><span>${msgBody}</span>`
                    : null,
                secContentClass: msgBody ? 'reviewer-msg' : null,
                positiveBtn: $localize`Start `,
                negativeBtn: $localize`Cancel`,
            },
        });

        return this.mobile.getSideNavAction().pipe(
            filter((action) => action === SideNaveActionsTypes.POSITIVE),
            takeUntil(this.destroy),
            tap(() => this.startResource(resource, item)),
            map(() => resource)
        );
    }
    private informApprovalStatus(resource: Resource) {
        const msgBody = resource.enrollment.admin?.message?.body;

        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: resource.enrollment.approval_status == 'rejected' ? 'cancel' : 'pending_actions',
                iconClass: resource.enrollment.approval_status == 'rejected' ? 'red' : 'orange',
                titleClass: resource.enrollment.approval_status == 'rejected' ? 'red' : 'orange',
                title:
                    resource.enrollment.approval_status == 'rejected'
                        ? $localize`Enrollment Request rejected`
                        : $localize`Enrollment Request pending`,
                contentBody:
                    resource.enrollment.approval_status == 'rejected'
                        ? $localize`Unfortunately the administrator has rejected you request to enroll in the ${ResourceTypes.label(
                              resource.type_id,
                              { capitalize: false }
                          )} ${resource.title}`
                        : $localize`The administrator is still reviewing you request to enroll in the ${ResourceTypes.label(
                              resource.type_id,
                              { capitalize: false }
                          )} ${resource.title}`,

                secContentBody: msgBody
                    ? '<span>' + $localize`The administrator message: ` + `</span><span>${msgBody}</span>`
                    : null,
                secContentClass: msgBody ? 'reviewer-msg' : null,
                positiveBtn: $localize`Ok`,
                negativeBtn: $localize`Cancel`,
            },
        });
    }
    private askForEnroll(resource: Resource, approvalRequired: boolean, item?: ResourceItem): Observable<Resource> {
        const resourceNotTakenYet =
            !resource.opportunity_id ||
            (resource?.occasionStatus && !['ongoing', 'completed'].includes(resource?.occasionStatus));
        const actions = [{ label: $localize`Cancel`, value: 0, class: 'null-btn' }];
        actions.unshift(
            approvalRequired && resourceNotTakenYet
                ? { label: $localize`Request join`, value: 1, class: 'pos-btn' }
                : {
                      label: resourceNotTakenYet ? $localize`Start` : $localize`Continue`,
                      value: 2,
                      class: 'pos-btn',
                  }
        );

        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.RESOURCE_ENROLLMENT_REQUEST,
            data: {
                icon: approvalRequired ? 'task_alt' : 'play_circle',
                title: resourceNotTakenYet
                    ? approvalRequired
                        ? $localize`Enroll`
                        : $localize`Start`
                    : $localize`Continue`,
                inputDesc: approvalRequired ? $localize`Send message to admin requesting to join` : null,
                resource: resource,
                actions: actions,
                type: 'enrollRequest',
            },
        });

        return merge(this.mobile.getSideNavAction(), this.mobile.getSideNavResponseData()).pipe(
            filter((data) => SideNaveActionsTypes.isValid(data) || (data.type === 'enrollRequest' && data.response)),
            first(),
            switchMap((data) => {
                if (SideNaveActionsTypes.isValid(data) && data !== SideNaveActionsTypes.POSITIVE) {
                    return of(null);
                } else {
                    return data.response == 1
                        ? this.resourceService.userRequestPermission(resource.id, { body: data.value }, ['enrollment'])
                        : of(data);
                }
            }),
            tap((data) => {
                if (data && data.response == 2) {
                    this.startResource(resource, item);
                }
                if (data instanceof Resource) {
                    this.resource.next(data);
                    this.message.openMessage(MessageSavedComponent, $localize`Request sent to the admin.`);
                }
            }),
            map(() => resource)
        );
    }

    public startResource(resource: Resource, item?: ResourceItem) {
        this.router.navigate(['/player/resource', resource.id, ResourceTypes.playerRoute(resource.type_id)], {
            queryParams: {
                queryParams: item ? { position: item.play_position } : {},
                referrer: this.location.path().split('?')[0],
            },
        });
    }
    private registerEvent(res: Resource, user: User) {
        if (res.price) {
            this.mobile.updateSideNavSub({
                dataType: SideNaveDataTypes.EVENT_REGISTER,
                data:
                    user.role_value === 'guest'
                        ? {
                              resource: res,
                          }
                        : {
                              resource: res,
                              user: user,
                          },
            });
        } else {
            this.mobile.updateSideNavSub({
                dataType: SideNaveDataTypes.COURSE_REGISTER,
                data:
                    user.role_value === 'guest'
                        ? {
                              resource: res,
                          }
                        : {
                              resource: res,
                              user: user,
                          },
            });
        }
    }
    getResource(): Observable<Resource> {
        return this.resource.asObservable();
    }
    makeResourcePublic(resource: Resource): Observable<Resource> {
        if (resource.publication) {
            const publicationData = [
                {
                    type: 'approved',
                    icon: 'check_circle',
                    iconClass: 'blue',
                    titleClass: 'blue',
                    title: $localize`Publication request approved`,
                    contentBody: $localize`Well done, the administrator has approved you request to publication`,
                },
                {
                    type: 'rejected',
                    icon: 'cancel',
                    iconClass: 'red',
                    titleClass: 'red',
                    title: $localize`Publication request rejected`,
                    contentBody: $localize`Unfortunately the administrator has rejected you request to publication`,
                },
                {
                    type: 'pending',
                    icon: 'pending_actions',
                    iconClass: 'orange',
                    titleClass: 'orange',
                    title: $localize`Publication request pending`,
                    contentBody: $localize`The administrator is still reviewing you request to publication`,
                },
            ];

            const currentPub = publicationData.find((pub) => pub.type == resource?.publication?.approval_status);

            const msgBody = resource.publication.admin?.message?.body;
            this.mobile.updateSideNavSub({
                dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
                data: {
                    icon: currentPub.icon,
                    iconClass: currentPub.iconClass,
                    titleClass: currentPub.titleClass,
                    title: currentPub.title,
                    contentBody: currentPub.contentBody,

                    secContentBody: msgBody
                        ? '<span>' + $localize`The administrator message: ` + `</span><span>${msgBody}</span>`
                        : null,
                    secContentClass: msgBody ? 'reviewer-msg' : null,
                    positiveBtn: $localize`Ok`,
                    negativeBtn: $localize`Cancel`,
                },
            });
            return of(resource);
        } else {
            const actions = [
                { label: $localize`Request publication`, value: 1, class: 'pos-btn' },
                { label: $localize`Cancel`, value: 0, class: 'null-btn' },
            ];

            this.mobile.updateSideNavSub({
                dataType: SideNaveDataTypes.RESOURCE_ENROLLMENT_REQUEST,
                data: {
                    icon: 'public',
                    title: $localize`Request to make public`,
                    inputDesc: $localize`Send message to admin requesting to publication`,
                    resource: resource,
                    actions: actions,
                    type: 'publicationRequest',
                },
            });
            return this.mobile.getSideNavResponseData().pipe(
                filter((data) => data.type === 'publicationRequest' && data.response),
                switchMap((data) =>
                    this.resourceService.userRequestPermission(
                        resource.id,
                        { body: data.value },
                        ['publication'],
                        'publication_requested'
                    )
                ),
                tap(() => this.message.openMessage(MessageSavedComponent, $localize`Request sent to the admin.`))
            );
        }
    }
    publishResource(resource: Resource): Observable<Resource> {
        if (resource.publishment) {
            const publishmentData = [
                {
                    type: 'approved',
                    icon: 'check_circle',
                    iconClass: 'blue',
                    titleClass: 'blue',
                    title: $localize`Publish request approved`,
                    contentBody: $localize`Well done, the administrator has approved you request to publish`,
                },
                {
                    type: 'rejected',
                    icon: 'cancel',
                    iconClass: 'red',
                    titleClass: 'red',
                    title: $localize`Publish request rejected`,
                    contentBody: $localize`Unfortunately the administrator has rejected you request to publish`,
                },
                {
                    type: 'pending',
                    icon: 'pending_actions',
                    iconClass: 'orange',
                    titleClass: 'orange',
                    title: $localize`Publish request pending`,
                    contentBody: $localize`The administrator is still reviewing you request to publish`,
                },
            ];

            const currentPub = publishmentData.find((pub) => pub.type == resource?.publishment?.approval_status);

            const msgBody = resource.publishment.admin?.message?.body;
            this.mobile.updateSideNavSub({
                dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
                data: {
                    icon: currentPub.icon,
                    iconClass: currentPub.iconClass,
                    titleClass: currentPub.titleClass,
                    title: currentPub.title,
                    contentBody: currentPub.contentBody,

                    secContentBody: msgBody
                        ? '<span>' + $localize`The administrator message: ` + `</span><span>${msgBody}</span>`
                        : null,
                    secContentClass: msgBody ? 'reviewer-msg' : null,
                    positiveBtn: $localize`Ok`,
                    negativeBtn: $localize`Cancel`,
                },
            });
            return of(resource);
        } else {
            const actions = [
                { label: $localize`Request publishment`, value: 1, class: 'pos-btn' },
                { label: $localize`Cancel`, value: 0, class: 'null-btn' },
            ];

            this.mobile.updateSideNavSub({
                dataType: SideNaveDataTypes.RESOURCE_ENROLLMENT_REQUEST,
                data: {
                    icon: 'published_with_changes',
                    title: $localize`Request to publish`,
                    inputDesc: $localize`Send message to admin requesting to publish`,
                    resource: resource,
                    actions: actions,
                    type: 'publishmentRequest',
                },
            });
            return this.mobile.getSideNavResponseData().pipe(
                filter((data) => data.type === 'publishmentRequest' && data.response),
                switchMap((data) =>
                    this.resourceService.userRequestPermission(
                        resource.id,
                        { body: data.value },
                        ['publishment'],
                        'publish_requested'
                    )
                ),
                tap(() => this.message.openMessage(MessageSavedComponent, $localize`Request sent to the admin.`))
            );
        }
    }
}
