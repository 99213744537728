<div *ngIf="currentLanguage | async; let lang; else: loading" class="flag-btn-lang">
    <span *ngIf="lang.value" class="flag-icon flag-icon-{{ lang.flag }}"></span>
    <mat-icon *ngIf="!lang.value" color="primary">language</mat-icon>
    <span *ngIf="showLabel" class="label flag-label">{{ lang.label }}</span>
</div>

<ng-template #loading>
    <div fxFlex="0 0 100%" fxLayout="column" fxLayoutAlign="center center" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
