<div class="listing-wrapper {{ stylingClass }}">
    <h3 color="primary">
        <mat-icon>{{ typeIcon }}</mat-icon>
        {{ label }}
    </h3>
    <div class="block-parent" fxLayout="column">
        <!-- <app-table-toolbar class="toolbar" color="accent">
            <app-table-filter color="accent" #filter>
                <mat-toolbar color="accent" fxLayout="row">
                    <app-table-search class="align-space"></app-table-search>
                    <div fxFlex="1 1 100%"></div>
                    <app-language-filter fxLayout="row"></app-language-filter>
                    <app-table-menu [columns]="columns" fxHide="true" #tableMenu></app-table-menu>
                </mat-toolbar>
            </app-table-filter>

            <mat-progress-bar
                class="progress progress-table-loader"
                *ngIf="(dataSource.status | async) === 'loading'"
                mode="indeterminate"
            >
            </mat-progress-bar>
        </app-table-toolbar> -->
        <app-table-filter-expansion-panel
            [expanded]="false"
            class="wrapper-expansion"
            #filterExpansion
            [hasTopBorder]="false"
        >
            <app-table-filter
                [filtersTogglesInOnBtn]="true"
                [defaultFilter]="GlobalFilterProperty.SEARCH"
                [inGlobalFilter]="true"
                color="accent"
                #filter
            >
                <app-table-search
                    toggleBtnLabel="Schema"
                    i18n-toggleBtnLabel
                    [inGlobalFilter]="true"
                    class="align-space"
                    searchFilterRef
                ></app-table-search>
                <app-language-filter
                    [inGlobalFilter]="true"
                    #langFilter
                    fxFlex="0 0 auto"
                    fxLayout="row"
                    langFilterRef
                ></app-language-filter>
            </app-table-filter>
        </app-table-filter-expansion-panel>
        <mat-progress-bar
            class="progress progress-table-loader mb-1"
            *ngIf="(dataSource.status | async) === 'loading'"
            mode="indeterminate"
        >
        </mat-progress-bar>
        <mat-table
            #table
            [dataSource]="dataSource"
            [matSortActive]="'published'"
            matSortDirection="desc"
            matSort
            [class.mobileView]="isMobile"
        >
            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@title"> Title </mat-header-cell>
                <mat-cell *matCellDef="let resource" class="pr-0-m">
                    <ng-container *ngIf="!(isMobile | async)">
                        {{ resource.title }}
                    </ng-container>
                    <ng-container *ngIf="isMobile | async">
                        <div class="w-100" fxLayoutAlign="start start" fxLayoutGap="10px">
                            <div
                                class="mobile-cell-wrapper-title"
                                fxFlex="grow"
                                fxLayout="row"
                                fxLayoutGap="5px"
                                fxLayoutAlign="start start"
                            >
                                <mat-icon
                                    fxLayout="row"
                                    fxLayoutAlign="start center"
                                    fontSet="klickdata"
                                    [fontIcon]="resource.type_id | typeFontIcon"
                                    class="kd-icon-16"
                                ></mat-icon>
                                <span
                                    (click)="
                                        $event.stopPropagation();
                                        expanded = expanded == resource.id ? null : resource.id
                                    "
                                    class="title-mob"
                                    >{{ resource.title }}</span
                                >
                                <!-- <div fxLayoutAlign="start center" fxLayoutGap="5px">
                                    <mat-icon
                                    fxLayout="row" fxLayoutAlign="start center"
                                        fontSet="klickdata"
                                        [fontIcon]="resource.type_id | typeFontIcon"
                                        class="kd-icon"
                                    ></mat-icon>
                                    <span>
                                        {{ ResourceTypes.label(resource.type_id, { capitalize: true }) }}
                                    </span>
                                </div> -->
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <ng-container *ngIf="!resource.loading; else loadingTemp">
                                    <button
                                        class="tools-btn-mob"
                                        matTooltip="view"
                                        mat-icon-button
                                        (click)="
                                            $event.stopPropagation();
                                            expanded = expanded == resource.id ? null : resource.id
                                        "
                                        i18n-matTooltip="@@view"
                                    >
                                        <mat-icon>{{
                                            expanded == resource.id ? 'expand_less' : 'expand_more'
                                        }}</mat-icon>
                                    </button>
                                    <button
                                        class="tools-btn-mob"
                                        matTooltip="view"
                                        mat-icon-button
                                        (click)="
                                            $event.stopPropagation(); onDismissSheet.emit(true); openResource(resource)
                                        "
                                        i18n-matTooltip="@@view"
                                    >
                                        <mat-icon>visibility</mat-icon>
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="created_at">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@createdAt"> Created at </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span i18n="@@createdAt" class="mobile-label"> Created at </span>
                    {{ resource.created_at | kdDateFormat : 'YYYY-MM-DD' }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="author_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@createdBy"> Created by </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span i18n="@@createdBy" class="mobile-label"> Created by </span>
                    <ng-container *ngIf="resource.author$ | async; let loadedAuthor">
                        {{ $any(loadedAuthor).name }}
                    </ng-container>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="question_number">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@Q#"> Q# </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span class="mobile-label" i18n="@@Q#"> Q# </span>
                    {{ resource.number_of_items }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="published">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@publishedAt"> Published at </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span i18n="@@publishedAt" class="mobile-label"> Published at </span>
                    <span *ngIf="resource.published">
                        {{ resource.published | kdDateFormat : 'YYYY-MM-DD' }}
                    </span>
                    <span *ngIf="!resource.published" i18n="@@notPublishedYet"> Not yet. </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="language">
                <mat-header-cell *matHeaderCellDef i18n="@@language"> Language </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span i18n="@@language" class="mobile-label"> Language </span>
                    <ng-container *ngIf="resource.language_id">
                        <app-language-selector
                            [language_id]="resource.language_id"
                            class="icon-resource-builder"
                            fxFlex="0 0 auto"
                        ></app-language-selector>
                    </ng-container>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef i18n="@@type"> Type </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span i18n="@@type" class="mobile-label"> Type </span>
                    <ng-container>
                        <mat-icon
                            fontSet="klickdata"
                            [fontIcon]="resource.type_id | typeFontIcon"
                            class="kd-icon"
                        ></mat-icon>
                        {{ ResourceTypes.label(resource.type_id, { capitalize: true }) }}
                    </ng-container>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="tools">
                <mat-header-cell *matHeaderCellDef i18n="@@tools"> Tools </mat-header-cell>
                <mat-cell *matCellDef="let resource" (click)="$event.stopPropagation()">
                    <span i18n="@@tools" class="mobile-label mobile-label-tools"> Tools </span>
                    <ng-container *ngIf="!resource.loading; else loadingTemp">
                        <button
                            class="tools-btn"
                            matTooltip="view"
                            mat-icon-button
                            (click)="$event.stopPropagation(); onDismissSheet.emit(true); openResource(resource)"
                            i18n-matTooltip="@@view"
                        >
                            <mat-icon>visibility</mat-icon>
                        </button>
                        <!-- <button
                            class="tools-btn"
                            mat-icon-button
                            (click)="$event.stopPropagation()"
                        >
                            <mat-icon
                                fontSet="klickdata"
                                [fontIcon]="resource.type_id | typeFontIcon"
                                class="kd-icon"
                            ></mat-icon>
                        </button> -->
                    </ng-container>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
            <mat-row
                *matRowDef="let resource; columns: columns"
                [ngClass]="!resource.loading ? 'link' : 'disableIt'"
                [ngClass]="{ 'table-expanded': expanded == resource.id }"
                (click)="
                    $event.stopPropagation(); !resource.loading && onDismissSheet.emit(true); openResource(resource)
                "
            ></mat-row>
        </mat-table>
    </div>
    <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
</div>

<ng-template #loadingTemp>
    <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="center" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
