import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnInit,
    Output,
    ViewChild,
    Input,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { AppScope, ResourceTag } from '@klickdata/core/resource';
import {
    FilterOption,
    FilterSpecs,
    GlobalFilterColor,
    GlobalFilterProperty,
    SelectFilterOption,
    TableFilterComponent,
    TableSource,
} from '@klickdata/core/table';
import { User, UserService } from '@klickdata/core/user';
import { HrNotes, NotesContext, UserNotesService } from '@klickdata/core/user-notes';
import { Utils } from '@klickdata/core/util';
import { Observable, Subject } from 'rxjs';
import { filter, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { K3NotesListingService } from './k3-notes-listing.service';
import { MatTableBase } from 'apps/klickdata/src/app/shared/table/mat-table-base';

@Component({
    selector: 'app-k3-notes-listing',
    templateUrl: './k3-notes-listing.component.html',
    styleUrls: ['./k3-notes-listing.component.scss'],
    providers: [K3NotesListingService, { provide: MatTableBase, useExisting: K3NotesListingComponent }],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class K3NotesListingComponent extends MatTableBase<HrNotes> implements OnInit, AfterViewInit {
    @Output() public saving: EventEmitter<boolean> = new EventEmitter();
    @Input() pageSize = 25;
    // Take visibility away from notes listing.
    public columns = ['created_at', 'author', 'contact_type', 'notes', 'context', 'tags', 'tools'];
    public predefinedTimeSpentOptions: SelectFilterOption[];
    public userRoleOptions: SelectFilterOption[];
    public contactTypeOptions: SelectFilterOption[];
    public noteScopeOptions: SelectFilterOption[];
    public expandedRow: User | null;
    public expandedCell: User | null;
    public allManagersList$: Observable<User[]>;

    public isMobile: boolean;
    public noteVisibilityOptions: SelectFilterOption[];
    public noteContextOptions: FilterOption[] = [
        { property: 'none', label: $localize`:@@general:General` },
        { property: 'learner', label: $localize`:@@connection:Connection` },
        { property: 'client', label: $localize`:@@client:Client` },
    ];
    public managerFilterSpecs: FilterSpecs = {
        property: GlobalFilterProperty.MANAGERS,
        label: $localize`:@@owner:Owner`,
        icon: 'badge',
        color: { bg: GlobalFilterColor.C5, fg: GlobalFilterColor.WHITE },
    };
    public deadlineFilterSpecs: FilterSpecs = {
        property: GlobalFilterProperty.DEADLINE,
        items: [],
        composite: [
            { property: 'deadline_from', items: [] },
            { property: 'deadline_to', items: [] },
        ],
        label: $localize`:@@deadline:Deadline`,
        icon: 'event_busy',
        color: { bg: GlobalFilterColor.C4, fg: GlobalFilterColor.C1 },
        styleClass: 'global-date-filter-selector',
    };

    constructor(
        protected cdr: ChangeDetectorRef,
        protected mobile: MobileService,
        protected hrNotesService: K3NotesListingService,
        protected notesService: UserNotesService,
        protected message: MessageService,
        protected userService: UserService,
        protected route: ActivatedRoute,
        protected router: Router
    ) {
        super(cdr, mobile, hrNotesService);
        this.predefinedTimeSpentOptions = Utils.getPredefinedTimeSpentOptions();
        this.noteVisibilityOptions = Utils.getNotesVisibilityOptions();
        this.userRoleOptions = Utils.getUserRoleOptions();
        this.contactTypeOptions = Utils.getCustomerContactTypeOptions();
        this.noteScopeOptions = Utils.getNotesScopeOptions();
    }
    ngOnInit(): void {
        super.ngOnInit();
        // this.dataSource.service = this.hrNotesService;
    }
    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.route.queryParams.pipe(map((params) => params.id)).subscribe((id) => {
            if (id) {
                this.filter.setActiveFilterWithQuery(GlobalFilterProperty.SEARCH, id);
            } else {
                this.dataSource.refresh();
            }
        });
        this.allManagersList$ = this.userService
            .getLightMasterUsers({
                limit: 20,
                userRole: 'superadmin',
            })
            .pipe(shareReplay());
        this.route.queryParams.pipe(filter((params) => params.action === 'addNote')).subscribe(() =>
            setTimeout(() => {
                this.addNote();
                this.router.navigate([this.router.url.split('?')[0]]);
            }, 500)
        );
    }
    onTagClick(tag: ResourceTag) {
        this.filter.filterByModel(tag.getFilter(), true);
    }
    updateNoteTags(note: HrNotes, tagsIds: number[]) {
        this.notesService
            .updateHrNote({ id: note.id, tag_ids: tagsIds }, ['customer,tags,managers'])
            .pipe(takeUntil(this.destroy))
            .subscribe();
    }
    public addNote() {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.ADD_HR_NOTE,
            data: {
                icon: 'document_scanner',
                title: $localize`Add HR note`,
                placeholder: $localize`Type in your note`,
                btnLabel: $localize`Add note`,
                hasMediaUploader: true,
                hasContactType: true,
                noteContextOptions: this.noteContextOptions,
                noteVisibilityOptions: this.noteVisibilityOptions.map((opt) => opt.value),
                scope: AppScope.CUSTOMERS,
                sideNavType: 'create-hrNote',
            },
        });

        this.mobile
            .getSideNavResponseData()
            .pipe(
                filter((data) => data.sideNavType === 'create-hrNote'),
                switchMap((data) => this.notesService.addHrNote(this.prepareData(data), ['customer,tags,managers'])),
                takeUntil(this.destroy)
            )
            .subscribe((note) => {
                if (note) {
                    this.dataSource.add(note);
                    this.message.openMessage(MessageSavedComponent, $localize`Note added successfully`);
                }
            });
    }
    public editNote(hrNote: HrNotes) {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.ADD_HR_NOTE,
            data: {
                icon: 'document_scanner',
                title: $localize`Edit Note`,
                btnLabel: $localize`Edit note`,
                hasMediaUploader: true,
                scope: AppScope.CUSTOMERS,
                note: hrNote,
                hasContactType: true,
                noteContextOptions: this.noteContextOptions,
                noteVisibilityOptions: this.noteVisibilityOptions.map((opt) => opt.value),
                sideNavType: 'edit-hrNote',
            },
        });

        this.mobile
            .getSideNavResponseData()
            .pipe(
                filter((data) => data.sideNavType === 'edit-hrNote'),
                switchMap((data) =>
                    this.notesService.updateHrNote(this.prepareData(data, hrNote), ['customer,tags,managers'])
                ),
                takeUntil(this.destroy)
            )
            .subscribe((note) => {
                if (note) {
                    this.dataSource.replace(note);
                    this.message.openMessage(MessageSavedComponent, $localize`Note edited successfully`);
                }
            });
    }
    private prepareData(data: any, hrNote?: HrNotes) {
        delete data.sideNavType;
        const hrNoteData = hrNote?.id ? hrNote.getPayload(data) : new HrNotes(data).getPayload();
        return hrNoteData;
    }
    onSelectSignature(manager: User, note: HrNotes) {
        this.notesService
            .updateHrNote({ id: note.id, staff: { manager: [manager.id] } }, ['customer,tags,managers'])
            .pipe(takeUntil(this.destroy))
            .subscribe((updatednote) => this.dataSource.replace(updatednote));
    }
    updateCreatedAt(value: any, note: HrNotes) {
        const chosenDate = new DatePipe('en').transform(value, 'yyyy-MM-dd HH:mm');
        this.notesService
            .updateHrNote({ id: note.id, created_at: chosenDate }, ['customer,tags,managers'])
            .pipe(takeUntil(this.destroy))
            .subscribe((updatednote) => this.dataSource.replace(updatednote));
    }

    public addComment(hrNote: HrNotes) {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_TEXT_AREA,
            data: {
                icon: 'maps_ugc',
                title: $localize`Add comment`,
                placeholder: $localize`Type in your comment`,
                btnLabel: $localize`Add comment`,
                hasMediaUploader: true,
                scope: AppScope.CUSTOMERS,
                type: 'comment',
            },
        });
        this.mobile
            .getSideNavResponseData()
            .pipe(
                filter((data) => data.type === 'comment'),
                switchMap((data) =>
                    this.notesService.addHrNote(
                        {
                            parent_id: hrNote.id,
                            body: data.value,
                            media_ids: data.media_ids,
                        },
                        ['customer,tags,managers']
                    )
                ),
                takeUntil(this.destroy)
            )
            .subscribe((comment) => {
                if (comment) {
                    const data = comment.getData();
                    hrNote.comments = hrNote.comments?.length ? [...hrNote.comments, data] : [data];
                    this.cdr.markForCheck();
                    this.message.openMessage(MessageSavedComponent, $localize`Comment added successfully`);
                }
            });
    }
    public deleteNote(hrNote: HrNotes) {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: 'delete',
                title: $localize`Delete Note`,
                contentBody: $localize`Are you sure you want to delete this note ?`,
                positiveBtn: $localize`Delete Note`,
                negativeBtn: $localize`Cancel`,
            },
        });
        this.mobile
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap(() => this.notesService.deleteHrNote(hrNote.id))
            )
            .subscribe((res) => {
                if (res) {
                    this.dataSource.removeById(hrNote.id);
                    this.message.openMessage(MessageSavedComponent, $localize`Note deleted successfully`);
                }
            });
    }
    public showNoteMedia(hrNote: HrNotes) {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.MEDIA_ATTACHMENT,
            data: {
                mediaIds: hrNote.media_ids,
            },
        });
    }
    routeToContext(context: NotesContext) {
        if (context.user?.id) {
            this.router.navigate(['/master/customers/k3-connections/connection', context.user.id]);
        } else if (context.customer?.id) {
            this.router.navigate(['/master/customers/customer-manager', context.customer.id]);
        }
    }
    updateNotContatType(hrNote: HrNotes, value: string) {
        this.notesService
            .updateHrNote({ id: hrNote.id, contact_type: value }, ['customer,tags,managers'])
            .pipe(takeUntil(this.destroy))
            .subscribe((updatednote) => this.dataSource.replace(updatednote));
    }
    updateNotVisibility(hrNote: HrNotes, value: string) {
        this.notesService
            .updateHrNote({ id: hrNote.id, visibility: value }, ['customer,tags,managers'])
            .pipe(takeUntil(this.destroy))
            .subscribe((updatednote) => this.dataSource.replace(updatednote));
    }
}
