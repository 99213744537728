<div fxLayout="column" fxFlex="0 0 100%" class="hr-notes-wrapper">
    <app-table-filter-expansion-panel class="task-expansion" #filterExpansion [hasTopBorder]="false">
        <app-table-filter
            [filtersTogglesInOnBtn]="true"
            [cacheScope]="AppScope.CUSTOMERS"
            [inGlobalFilter]="true"
            color="accent"
            #filter
        >
            <app-table-search
                toggleBtnLabel="Note"
                i18n-toggleBtnLabel
                [inGlobalFilter]="true"
                class="align-space"
                searchFilterRef
            ></app-table-search>
            <app-select-filter
                [inGlobalFilter]="true"
                class="filter-space"
                label="All Time"
                i18n-label
                [property]="GlobalFilterProperty.TIME_SPENT"
                [options]="predefinedTimeSpentOptions"
                fixedTimeSpentFilterRef
            >
            </app-select-filter>
            <app-user-chip-search-filter
                [inGlobalFilter]="true"
                [isMaster]="true"
                [filterSpecs]="managerFilterSpecs"
                i18n-searchLabel
                searchLabel="Owner"
                i18n-chosingLabel
                chosingLabel="Choose owner"
                i18n-searchPlaceholder
                searchPlaceholder="Filter by owners"
                managersFilterRef
            ></app-user-chip-search-filter>
            <app-table-date-filter
                [filterSpecs]="deadlineFilterSpecs"
                [inGlobalFilter]="true"
                color="accent"
                deadlineFilterRef
            ></app-table-date-filter>
            <app-tag-chip-search-filter
                [inGlobalFilter]="true"
                fxFlex="50%"
                fxFlex.lt-md="100%"
                fxLayoutAlign="start"
                tagsFilterRef
            ></app-tag-chip-search-filter>
            <app-select-filter
                [inGlobalFilter]="true"
                class="filter-space"
                label="Visibility"
                i18n-label="@@visibility"
                [property]="GlobalFilterProperty.NOTE_VISIBILITY"
                [options]="noteVisibilityOptions"
                notesVisibilityRef
            >
            </app-select-filter>
            <app-select-filter
                [inGlobalFilter]="true"
                class="filter-space"
                label="Context"
                i18n-label="@@context"
                [property]="GlobalFilterProperty.NOTE_CONTEXT"
                [options]="noteScopeOptions"
                notesContextRef
            >
            </app-select-filter>
        </app-table-filter>
    </app-table-filter-expansion-panel>
    <!-- <mat-progress-bar
        class="progress-table-loader"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    ></mat-progress-bar> -->
    <mat-table
        #table
        [dataSource]="dataSource"
        matSortActive="created_at"
        matSortDirection="desc"
        matSort
        multiTemplateDataRows
    >
        <ng-container matColumnDef="notes">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Notes</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let hrNote">
                <span class="mobile-label" i18n>Notes:</span>
                <span [innerHTML]="hrNote.body | linkDetector" class="notes"></span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="created_at">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@date">Date</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let hrNote">
                <app-date-time-picker-item
                    (click)="$event.stopPropagation()"
                    *ngIf="!hrNote.loading"
                    [value]="hrNote.created_at"
                    (onDateTimeChanged)="updateCreatedAt($event, hrNote)"
                ></app-date-time-picker-item>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="icon">
            <mat-header-cell mat-sort-header *matHeaderCellDef> </mat-header-cell>
            <mat-cell *matCellDef="let hrNote">
                <ng-container *ngIf="hrNote.context">
                    <mat-icon matTooltip="Learner context" i18n-matTooltip *ngIf="hrNote.context.user">person</mat-icon>
                    <mat-icon matTooltip="Academy context" i18n-matTooltip *ngIf="hrNote.context.customer"
                        >account_balance</mat-icon
                    >
                    <mat-icon matTooltip="Resource context" i18n-matTooltip *ngIf="hrNote.context.resource"
                        >category</mat-icon
                    >
                    <mat-icon matTooltip="Task context" i18n-matTooltip *ngIf="hrNote.context.task">today</mat-icon>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="customer">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@customer">Customer</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let hrNote">
                <span class="customer-name">{{ hrNote.customer?.name }}</span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="tags">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Tags</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let note">
                <span *ngIf="isMobile" class="mobile-label" i18n> Tags </span>
                <!-- <div class="shadow-maker"></div>
                <div *ngIf="!!user.userAndTaskTags?.length" class="tags-wrapper">
                    <div
                        class="tags"
                        (click)="$event.stopPropagation(); onTagClick(tag)"
                        *ngFor="let tag of user.userAndTaskTags"
                    >
                        <span>{{ tag.name }}</span>
                    </div>
                </div> -->
                <app-table-tags-on-the-fly
                    (click)="$event.stopPropagation()"
                    [tags]="note.tags"
                    (onUpdateTags)="note?.id && updateNoteTags(note, $event)"
                    (onTagClick)="onTagClick($event)"
                ></app-table-tags-on-the-fly>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="author">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Signature</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let hrNote">
                <span class="mobile-label" i18n>Signature:</span>
                <ng-container *ngIf="allManagersList$ | async; let allManagers">
                    <app-signature-change-on-the-fly
                        class="customer-item-not-task"
                        (click)="$event.stopPropagation()"
                        (onSelectSignature)="onSelectSignature($event, hrNote)"
                        [manager]="hrNote.managers?.length && hrNote.managers[0]"
                        [allManagersList]="allManagers"
                    ></app-signature-change-on-the-fly>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="contact_type">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Contact type</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let hrNote">
                <span class="mobile-label" i18n>Type:</span>
                <app-item-change-on-the-fly
                    [capitalize]="true"
                    [selectionOptions]="contactTypeOptions"
                    [value]="hrNote.contact_type"
                    (onValueChange)="updateNotContatType(hrNote, $event)"
                ></app-item-change-on-the-fly>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="visibility">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Visibility</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let hrNote">
                <span class="mobile-label" i18n>Visibility:</span>
                <app-item-change-on-the-fly
                    [capitalize]="true"
                    [selectionOptions]="noteVisibilityOptions"
                    [value]="hrNote.visibility"
                    (onValueChange)="updateNotVisibility(hrNote, $event)"
                ></app-item-change-on-the-fly>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="context">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Context</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let hrNote">
                <span class="mobile-label" i18n>Context:</span>
                <app-notes-context-display
                    *ngIf="hrNote.context"
                    [context]="hrNote.context"
                    (onContextClick)="routeToContext(hrNote.context)"
                ></app-notes-context-display>
                <div *ngIf="!hrNote.context" i18n="@@general">General</div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="tools">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell class="btns-actions" *matCellDef="let hrNote">
                <button
                    *ngIf="hrNote.media_ids && hrNote.media_ids.length"
                    (click)="$event.stopPropagation(); showNoteMedia(hrNote)"
                    matTooltip="View attachment"
                    mat-icon-button
                    i18n-matTooltip="@@viewAttachment"
                    class="comment-add"
                >
                    <mat-icon>attachment</mat-icon>
                </button>
                <button
                    (click)="$event.stopPropagation(); editNote(hrNote)"
                    matTooltip="Edit"
                    mat-icon-button
                    i18n-matTooltip="@@edit"
                    class="comment-add"
                >
                    <mat-icon>edit</mat-icon>
                </button>
                <button
                    (click)="$event.stopPropagation(); deleteNote(hrNote)"
                    matTooltip="Delete"
                    mat-icon-button
                    i18n-matTooltip="@@delete"
                    class="comment-add"
                >
                    <mat-icon>delete</mat-icon>
                </button>
                <button
                    (click)="$event.stopPropagation(); addComment(hrNote)"
                    matTooltip="Add comments"
                    mat-icon-button
                    i18n-matTooltip="@@addComments"
                    class="comment-add"
                >
                    <mat-icon>maps_ugc</mat-icon>
                </button>
                <button
                    *ngIf="hrNote.comments?.length"
                    (click)="$event.stopPropagation(); expandedRow = expandedRow === hrNote ? null : hrNote"
                    matTooltip="View comments"
                    mat-icon-button
                    i18n-matTooltip="@@viewComments"
                    class="expand-btn"
                >
                    <mat-icon>{{ expandedRow === hrNote ? 'expand_less' : 'expand_more' }}</mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
            <mat-cell *matCellDef="let hrNote" [attr.colspan]="columns.length">
                <div class="example-element-detail" [@detailExpand]="hrNote == expandedRow ? 'expanded' : 'collapsed'">
                    <ng-container *ngIf="hrNote.comments">
                        <app-shared-hr-notes-comments [comments]="hrNote.comments"></app-shared-hr-notes-comments>
                    </ng-container>
                </div>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row
            *matRowDef="let hrNote; columns: columns"
            [class.example-expanded-row]="expandedRow === hrNote"
            class="example-element-row"
            (click)="hrNote.comments && (expandedRow = expandedRow === hrNote ? null : hrNote)"
        ></mat-row>
        <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></mat-row>
    </mat-table>
    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
</div>

<ng-template #loading>
    <div fxLayout="row" fxLayoutAlign="center center" class="spinner-container mt-2">
        <mat-spinner fxFlex="0 0 auto"></mat-spinner>
    </div>
</ng-template>
