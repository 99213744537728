import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';

@Component({
    selector: 'app-menu-side-login-register',
    templateUrl: './menu-side-login-register.component.html',
    styleUrls: ['./menu-side-login-register.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideLoginRegisterComponent implements OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    public accessMode: 'login' | 'signup' = 'login';
    constructor() {}

    ngOnInit(): void {}
}
