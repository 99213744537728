import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@klickdata/core/auth';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { AppScope, ResourceTag } from '@klickdata/core/resource';
import {
    Filter,
    GlobalFilterProperty,
    SelectFilterOption,
    TableFilterComponent,
    TableSource,
} from '@klickdata/core/table';
import { User, UserService } from '@klickdata/core/user';
import { HrNotes, UserNotesService } from '@klickdata/core/user-notes';
import { Utils } from '@klickdata/core/util';
import { Observable, Subject } from 'rxjs';
import { filter, first, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { TagRelatedScopesListingComponent } from '../tag-related-scopes-listing/tag-related-scopes-listing.component';
import { HrNotesListingService } from './hr-notes-listing.service';

@Component({
    selector: 'app-shared-hr-notes',
    templateUrl: './shared-hr-notes.component.html',
    styleUrls: ['./shared-hr-notes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [HrNotesListingService],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class SharedHrNotesComponent implements OnInit {
    @Input() public customerId: number;
    @Input() public user: User;
    @Input() public activeSorting: string;
    @Input() public activeSortingDir: 'asc' | 'desc' | '' = '';
    @Input() public scope = AppScope.NOTE;
    @Input() public hasContactType: boolean;
    @Input() public hasNoteContext: boolean;
    @Input() public noteVisibilityOptions: SelectFilterOption[] = [];
    @Output() public saving: EventEmitter<boolean> = new EventEmitter();
    public destroy: Subject<boolean> = new Subject<boolean>();
    public dataSource = new TableSource<HrNotes>();
    AppScope = AppScope;
    GlobalFilterProperty = GlobalFilterProperty;
    @Input() public columns = ['created_at', 'author', 'notes', 'tools'];
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    public predefinedTimeSpentOptions: SelectFilterOption[];
    public userRoleOptions: SelectFilterOption[];
    public contactTypeOptions: SelectFilterOption[];
    public isMobile: boolean;
    public expandedRow: User | null;
    public expandedCell: User | null;
    private authUser: User;
    public allManagersList$: Observable<User[]>;

    constructor(
        protected hrNotesService: HrNotesListingService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected auth: AuthService,
        protected cdr: ChangeDetectorRef,
        protected mobile: MobileService,
        protected notesService: UserNotesService,
        protected message: MessageService,
        protected userService: UserService,
        protected bottomSheet: MatBottomSheet
    ) {
        this.predefinedTimeSpentOptions = Utils.getPredefinedTimeSpentOptions();
        this.userRoleOptions = Utils.getUserRoleOptions();
        this.contactTypeOptions = Utils.getCustomerContactTypeOptions();
    }

    ngOnInit(): void {
        this.dataSource.service = this.hrNotesService;
        this.mobile
            .isMobile()
            .pipe(takeUntil(this.destroy))
            .subscribe((isMobile) => (this.isMobile = isMobile));
        this.auth
            .getUser()
            .pipe(first(), takeUntil(this.destroy))
            .subscribe((user) => (this.authUser = user));
    }
    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        if (this.filter) {
            if (this.user?.id) {
                this.filter.createOrUpdateFilter([
                    new Filter('user', [this.user.id]),
                    new Filter('eager', ['tags,managers']),
                ]);
                this.filter.setActiveFilter(GlobalFilterProperty.TIME_SPENT);
            }
            if (this.customerId) {
                this.filter.createOrUpdateFilter([new Filter('customer', [this.customerId])]);
                this.filter.setActiveFilter(GlobalFilterProperty.SEARCH);
            }
        }
        this.allManagersList$ = this.userService
            .getLightMasterUsers({
                limit: 20,
                userRole: 'superadmin',
                // role: ResourceStaffRoles.MANAGER,
            })
            .pipe(shareReplay());
    }

    onTagClick(tag: ResourceTag) {
        // this.filter.filterByModel(tag.getFilter(), true);

        this.bottomSheet.open(TagRelatedScopesListingComponent, {
            data: tag,
            panelClass: 'sheet-wrapper',
        });
    }

    updateNoteTags(note: HrNotes, tagsIds: number[]) {
        // this.taskService
        //     .update({ id: task.id, tag_ids: tagsIds }, ['tags', 'managers', 'customers'])
        //     .pipe(takeUntil(this.destroy))
        //     .subscribe((updatedTask) => this.dataSource.replace(updatedTask));

        this.notesService
            .updateHrNote({ id: note.id, tag_ids: tagsIds }, ['tags'])
            .pipe(takeUntil(this.destroy))
            .subscribe();
    }
    public addNote() {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.ADD_HR_NOTE,
            data: {
                icon: 'document_scanner',
                title: $localize`Add HR note`,
                placeholder: $localize`Type in your note`,
                btnLabel: $localize`Add note`,
                hasMediaUploader: true,
                hasContactType: this.hasContactType,
                noteVisibilityOptions: this.noteVisibilityOptions.map((opt) => opt.value),
                scope: this.user ? AppScope.USERS : AppScope.CUSTOMERS,
                sideNavType: 'create-hrNote',
            },
        });

        this.mobile
            .getSideNavResponseData()
            .pipe(
                filter((data) => data.sideNavType === 'create-hrNote'),
                switchMap((data) => this.notesService.addHrNote(this.prepareData(data), ['managers,tags'])),
                takeUntil(this.destroy)
            )
            .subscribe((note) => {
                if (note) {
                    this.dataSource.refresh();
                    this.message.openMessage(MessageSavedComponent, $localize`Note added successfully`);
                }
            });
    }
    public editNote(hrNote: HrNotes) {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.ADD_HR_NOTE,
            data: {
                icon: 'document_scanner',
                title: $localize`Edit Note`,
                btnLabel: $localize`Edit note`,
                hasMediaUploader: true,
                scope: this.user ? AppScope.USERS : AppScope.CUSTOMERS,
                hasContactType: this.hasContactType,
                noteVisibilityOptions: this.noteVisibilityOptions.map((opt) => opt.value),
                sideNavType: 'edit-hrNote',
                note: hrNote,
            },
        });

        this.mobile
            .getSideNavResponseData()
            .pipe(
                filter((data) => data.sideNavType === 'edit-hrNote'),
                switchMap((data) => this.notesService.updateHrNote(this.prepareData(data, hrNote), ['managers'])),
                takeUntil(this.destroy)
            )
            .subscribe((note) => {
                if (note) {
                    this.dataSource.refresh();
                    this.message.openMessage(MessageSavedComponent, $localize`Note edited successfully`);
                }
            });
    }
    private prepareData(data: any, hrNote?: HrNotes) {
        delete data.sideNavType;
        data = { ...data, ...(this.user ? { user_id: this.user.id } : { customer_id: this.customerId }) };
        const hrNoteData = hrNote?.id ? hrNote.getPayload(data) : new HrNotes(data).getPayload();
        return hrNoteData;
    }

    updateCreatedAt(value: string, note: HrNotes) {
        this.notesService.updateHrNote({ id: note.id, created_at: value }).pipe(takeUntil(this.destroy)).subscribe();
    }

    onSelectSignature(manager: User, note: HrNotes) {
        this.notesService
            .updateHrNote({ id: note.id, staff: { manager: [manager.id] } }, ['managers'])
            .pipe(takeUntil(this.destroy))
            .subscribe((updatedTask) => {
                this.dataSource.replace(updatedTask);
            });
    }
    public addComment(hrNote: HrNotes) {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_TEXT_AREA,
            data: {
                icon: 'maps_ugc',
                title: $localize`Add comment`,
                placeholder: $localize`Type in your comment`,
                btnLabel: $localize`Add comment`,
                hasMediaUploader: true,
                scope: this.user ? AppScope.USERS : AppScope.CUSTOMERS,
                type: 'comment',
            },
        });
        this.mobile
            .getSideNavResponseData()
            .pipe(
                filter((data) => data.type === 'comment'),
                switchMap((data) =>
                    this.notesService.addHrNote({
                        parent_id: hrNote.id,
                        body: data.value,
                        media_ids: data.media_ids,
                    })
                ),
                takeUntil(this.destroy)
            )
            .subscribe((comment) => {
                if (comment) {
                    const data = comment.getData();
                    hrNote.comments = hrNote.comments?.length ? [...hrNote.comments, data] : [data];
                    this.cdr.markForCheck();
                    this.message.openMessage(MessageSavedComponent, $localize`Comment added successfully`);
                }
            });
    }
    public deleteNote(hrNote: HrNotes) {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: 'delete',
                title: $localize`Delete Note`,
                contentBody: $localize`Are you sure you want to delete this note ?`,
                positiveBtn: $localize`Delete Note`,
                negativeBtn: $localize`Cancel`,
            },
        });
        this.mobile
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap(() => this.notesService.deleteHrNote(hrNote.id))
            )
            .subscribe((res) => {
                if (res) {
                    this.dataSource.removeById(hrNote.id);
                    this.message.openMessage(MessageSavedComponent, $localize`Note deleted successfully`);
                }
            });
    }
    public showNoteMedia(hrNote: HrNotes) {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.MEDIA_ATTACHMENT,
            data: {
                mediaIds: hrNote.media_ids,
            },
        });
    }
    updateNotContatType(hrNote: HrNotes, value: string) {
        this.notesService
            .updateHrNote({ id: hrNote.id, contact_type: value })
            .pipe(takeUntil(this.destroy))
            .subscribe((note) => {
                hrNote.contact_type = note.contact_type;
                this.cdr.markForCheck();
            });
    }
    getMappedContactType(selectedType: string) {
        return Utils.getCustomerContactTypeOptions().find((type) => type.value === selectedType)?.title;
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
