import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ApplicationService } from '@klickdata/core/application';
import { Capabilities } from '@klickdata/core/auth/src/capabilities/capabilities';
import { AuthService } from '@klickdata/core/auth/src/token/auth.service';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { CustomerLanding, CustomerService } from '@klickdata/core/customer';
import { GloalSearchService } from '@klickdata/core/global-search';
import { MessageService } from '@klickdata/core/message';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile/src/mobile.service';
import { AppScope } from '@klickdata/core/resource';
import { User } from '@klickdata/core/user';
import { LoginFormComponent } from '@klickdata/guest/src/login/login-form/login-form.component';
import { UserAllowedResourcesService } from '@klickdata/home/src/shared/user-allowed-resources.service';
import { Observable, of, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

enum Direction {
    Up = 'Up',
    Down = 'Down',
}
@Component({
    selector: 'app-header',
    templateUrl: 'header.component.html',
    styleUrls: ['header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [UserAllowedResourcesService],
})
export class HeaderComponent implements OnInit, OnChanges, OnDestroy {
    @Input() guest: boolean;
    @Input() onboarding: boolean;
    @Input() isHeaderWithOnlyLang: boolean;
    public capability = Capabilities;
    public authenticated: Observable<boolean>;
    public developer = false;
    public toggleSearch = false;
    public active: string;
    public icon: string;
    public notifications: string[] = [];
    public destroy: Subject<boolean> = new Subject<boolean>();
    private platform: string;
    public version$: Observable<{ version: string; date: string }>;
    public user$: Observable<User>;
    private _dialogRef: MatDialogRef<LoginFormComponent>;
    public isSearchBoxActive: Observable<boolean>;
    public customerLanding$: Observable<CustomerLanding>;
    public countdown = 0;

    constructor(
        protected auth: AuthService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected config: ConfigService,
        protected application: ApplicationService,
        protected customerService: CustomerService,
        protected mobile: MobileService,
        protected dialog: MatDialog,
        protected messageService: MessageService,
        public searchService: GloalSearchService,
        protected cdr: ChangeDetectorRef,
        protected userAllowedResourcesService: UserAllowedResourcesService
    ) {
        this.developer = config.config.buildMode === 'local';
        this.authenticated = this.auth.check();
        this.platform = this.auth.getNK3PlatformValue();
    }

    public ngOnInit() {
        this.isSearchBoxActive = this.searchService
            .getSearchBoxState()
            .pipe(tap((status) => (this.toggleSearch = status)));
        this.application
            .getNotifications()
            .pipe(takeUntil(this.destroy))
            .subscribe((notifications: string[]) => {
                this.notifications = notifications;
            });
        // this.customerLanding$ = this.auth.getCustomerLanding();
        this.customerLanding$ = this.customerService.getCustomerLogoType();
        this.active = this.router.url;
        this.version$ = this.application.getVersion();
        this.user$ = this.auth.getUser();
        // reset header position with every route change
        this.router.events.pipe(takeUntil(this.destroy)).subscribe((val) => {
            if (val instanceof NavigationEnd) {
                document.getElementById('scoller-head')?.classList.remove('slide-down');
            }
            if (val instanceof NavigationStart) {
                if (this._dialogRef) {
                    this._dialogRef.close();
                }
            }
        });
    }
    public ngOnChanges(changes: SimpleChanges): void {
        this.active = this.route.component['name'];
    }

    public onToggleMenu(): void {
        this.mobile.toggleMenu();
    }
    public onSearch() {
        this.router.navigate([`${this.platform === 'user' ? 'home' : this.platform}/search`], {});
    }
    addLink() {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.ADD_LINK_MATERIAL,
        });
    }
    public logout(): void {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: 'exit_to_app',
                title: $localize`Are you sure you want to quit ?`,
                contentBody: $localize`You are about to log out of KlickData KLMS.`,
                positiveBtn: $localize`Yes`,
                negativeBtn: $localize`No`,
            },
        });
        this.mobile
            .getSideNavAction()
            .pipe(filter((action) => action === SideNaveActionsTypes.POSITIVE))
            .subscribe((action) => {
                if (action) {
                    this.auth.logout();
                }
            });
    }

    /**
     * Reset authentication and reload.
     * Used for debugging.
     */
    public reset(): void {
        // this.auth.reset(); // Reload only reset authentication by logout.
        window.location.reload();
    }
    checkResAllowedforAU(): Observable<boolean> {
        return this.platform == 'user' || this.platform == 'guest'
            ? this.userAllowedResourcesService.checkResAllowed(AppScope.MATERIAL)
            : of(true);
    }
    public ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
        if (this._dialogRef) {
            this._dialogRef.close();
        }
    }
}
