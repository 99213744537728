import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'linkDetector',
})
export class LinkDetectorPipe implements PipeTransform {
    transform(value: string): string {
        const linkPattern = /(\bhttps?:\/\/\S+)/gi;
        return value.replace(linkPattern, '<a class="link-detector" href="$1" target="_blank">$1</a>');
    }
}
