<app-resource-details-info
    [resource]="resource"
    [user]="user"
    [customer]="customer"
    [resourceItems]="resourceItems"
    [resourceCategories$]="resourceCategories$"
    [resourceTags$]="resourceTags$"
    [resourceType]="ResourceTypes.parentType(resource.type_id)"
    [resourceIsAvailable]="resourceIsAvailable"
    resourceLabel="Course"
    i18n-resourceLabel
>
    <ng-container itemsHeader>
        <div fxLayoutGap="7px" fxLayoutAlign="start center">
            <mat-icon>category</mat-icon>
            <span i18n>Course include</span>
        </div>
    </ng-container>

    <ng-container resourceItemsSection>
        <div
            (click)="infoComp.takeResource(resource, user, item)"
            class="res-bull mt-1"
            *ngFor="let item of resourceItems; let index = index"
        >
            <div [matTooltip]="item.item_type_value" class="course-items-bull">
                <span class="index">{{ index + 1 }}</span>
                <ng-container [ngSwitch]="checkIfResourceOfType(item.item_type_value)">
                    <ng-container *ngSwitchCase="'course'">
                        <mat-icon fontSet="klickdata" class="kd-icon-stepper" fontIcon="kd-icon-player"></mat-icon>
                    </ng-container>
                    <ng-container *ngSwitchCase="'test'">
                        <mat-icon fontSet="klickdata" class="kd-icon-stepper" fontIcon="kd-icon-test"></mat-icon>
                    </ng-container>
                    <ng-container *ngSwitchCase="'survey'">
                        <mat-icon fontSet="klickdata" class="kd-icon-stepper" fontIcon="kd-icon-survey"></mat-icon>
                    </ng-container>
                    <ng-container *ngSwitchCase="'material'">
                        <mat-icon
                            fontSet="klickdata"
                            class="kd-icon-stepper event"
                            fontIcon="kd-icon-document"
                        ></mat-icon>
                    </ng-container>
                    <ng-container *ngSwitchCase="'event'">
                        <mat-icon class="event">event</mat-icon>
                    </ng-container>
                </ng-container>
                <app-image-view [smallSpinner]="true" [media_id]="item.media$" [proportional]="true"></app-image-view>
                <span class="res-item-name">{{ item.title || item.name }}</span>
            </div>
        </div>
    </ng-container>
    <span actionBtnLabel i18n="@@enroll">Enroll</span>
    <span managerLabel i18n="@@author">Author</span>
    <span resCodeLabel i18n>Course code</span>
    <ng-container resourceDates>
        <div
            *ngIf="resource.start_date || resource.end_date"
            class="item"
            fxLayout="column"
            fxLayoutAlign="start center"
            fxLayoutGap="5px"
        >
            <div class="item" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon>today</mat-icon>
                <div fxFlex="grow" class="item-title" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
                    <span i18n>Start</span>
                    <span
                        >{{ resource.start_date | kdDateFormat : 'MMM DD, YYYY HH:mm' }}&nbsp;{{
                            resource.time_zone
                        }}</span
                    >
                </div>
                <button
                    *ngIf="resource.end_date"
                    [ngClass]="{ flip: showDates }"
                    class="event-btn"
                    mat-icon-button
                    (click)="showDates = !showDates"
                >
                    <mat-icon>expand_less</mat-icon>
                </button>
            </div>
            <ng-container *ngIf="showDates">
                <div class="start-date" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                    <mat-icon>event</mat-icon>
                    <span>End</span>
                    <span
                        >{{ resource.end_date | kdDateFormat : 'MMM DD, YYYY HH:mm' }}&nbsp;{{
                            resource.time_zone
                        }}</span
                    >
                </div>
            </ng-container>
        </div>
    </ng-container>
    <ng-container resourceDuration>
        <div class="details-elem">
            <div class="pre">
                <mat-icon>timelapse</mat-icon>
                <span i18n="@@duration">Duration </span>
            </div>
            <span class="normal">{{ resource?.duration | formatIsoDuration : false }} </span>
        </div>
    </ng-container>
    <ng-container resourceType>
        <div fxLayoutAlign="center center">
            <div class="icon-wrapper">
                <mat-icon></mat-icon>
            </div>
            <span></span>
        </div>
    </ng-container>
    <ng-container resourceCircles>
        <div class="circle-item" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
            <div class="icon-wrapper">
                <mat-icon matListIcon fontSet="klickdata" fontIcon="kd-icon-task"></mat-icon>
            </div>
            <span i18n>Course</span>
        </div>
        <!-- <div
            class="circle-item"
            matTooltip="Course start date"
            i18n-matTooltip
            *ngIf="resource.start_date"
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayoutGap="10px"
        >
            <div class="icon-wrapper">
                <mat-icon>hourglass_top</mat-icon>
            </div>
            <span>{{ resource.start_date | kdDateFormat: 'MMM DD, YYYY' }}</span>
        </div> -->
        <!-- <div
            class="circle-item"
            matTooltip="Course end date"
            i18n-matTooltip
            *ngIf="resource.end_date"
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayoutGap="10px"
        >
            <div class="icon-wrapper">
                <mat-icon>hourglass_bottom</mat-icon>
            </div>
            <span>{{ resource.end_date | kdDateFormat: 'MMM DD, YYYY' }}</span>
        </div> -->
        <div
            class="circle-item"
            matTooltip="Course is always available"
            i18n-matTooltip
            *ngIf="resource.always_available"
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayoutGap="10px"
        >
            <div class="icon-wrapper">
                <mat-icon>event_available</mat-icon>
            </div>
            <span i18n>Available</span>
        </div>
    </ng-container>
</app-resource-details-info>
