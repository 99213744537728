import { Location } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@klickdata/core/auth';
import { ResourceTypeService } from '@klickdata/core/resource';
import { SectionItemComponent } from '../section-item/section-item.component';
import { takeUntil } from 'rxjs';

@Component({
    selector: 'app-resources-grid-item',
    templateUrl: './resources-grid-item.component.html',
    styleUrls: ['./resources-grid-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class ResourcesGridItemComponent extends SectionItemComponent implements AfterViewInit {
    @Input() showOccasion: boolean;

    constructor(
        protected router: Router,
        protected auth: AuthService,
        protected resourceTypeService: ResourceTypeService,
        protected cd: ChangeDetectorRef,
        protected location: Location,
        private ngZone: NgZone
    ) {
        super(router, auth, resourceTypeService, cd, location);
    }
    ngAfterViewInit(): void {
        setTimeout(() => {
            this.cd.detectChanges();
        }, 200);
    }
    public goToDetails(): void {
        this.ngZone.run(() => {
            this.auth
                .check()
                .pipe(takeUntil(this.destroy))
                .subscribe((authinticated) =>
                    this.router.navigate([
                        authinticated ? '/home/dashboard/resource-details/' : 'guest/resource-details',
                        this.resource.id,
                    ])
                );
        });
    }
}
