import { DatePipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveActionsTypes, SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { Resource, ResourceService, ResourceTypes } from '@klickdata/core/resource';
import { User } from '@klickdata/core/user';
import { HrNotes, HrNotesData, UserNotesService } from '@klickdata/core/user-notes';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import {
    BehaviorSubject,
    debounceTime,
    distinctUntilChanged,
    filter,
    Observable,
    of,
    share,
    switchMap,
    takeUntil,
} from 'rxjs';

@Component({
    selector: 'app-menu-side-quick-add-competence',
    templateUrl: './menu-side-quick-add-competence.component.html',
    styleUrls: ['./menu-side-quick-add-competence.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideQuickAddCompetenceComponent extends OnDestroyHandler implements OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    public resourceForm: FormGroup;
    public resourceCtrl = new FormControl('');
    public resourceSearch$: Observable<Resource[]>;
    public selectedResource: Resource;
    onSearchResource$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    constructor(
        protected fb: FormBuilder,
        protected resourceService: ResourceService,
        protected notesService: UserNotesService,
        protected changeRef: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {
        this.resourceForm = this.fb.group({
            title: ['', Validators.required],
            done_at: [],
            description: [''],
            note: [''],
            article_code: [''],
            language_id: ['', Validators.required],
            tag_ids: [[]],
        });
        this.resourceSearch$ = this.resourceForm.get('article_code').valueChanges.pipe(
            filter((term: string) => typeof term === 'string' && term != ''),
            debounceTime(300),
            distinctUntilChanged(),
            share(),
            switchMap((term) =>
                term.trim()
                    ? this.resourceService.getResources(
                          null,
                          {
                              articleCodeQuery: term,
                              assignedOrCustomer: this.navData.data.user.customer_id,
                              eager: 'tags',
                          },
                          10
                      )
                    : of(<Resource[]>[])
            ),
            takeUntil(this.destroy)
        );
    }
    onSelectResource(resource: Resource) {
        this.resourceForm.get('title').patchValue(resource.title);
        this.resourceForm.get('description').patchValue(resource.description);
        this.resourceForm.get('article_code').patchValue(resource.article_code);
        this.resourceForm.get('language_id').patchValue(resource.language_id);
        this.resourceForm.get('tag_ids').patchValue(resource.tag_ids);
        this.resourceForm.get('article_code').disable();
        if (!(this.navData.data.user as User).canEdit(resource)) {
            this.resourceForm.get('title').disable();
            this.resourceForm.get('description').disable();
            this.resourceForm.get('language_id').disable();
            this.resourceForm.get('tag_ids').disable();
        }
        this.selectedResource = resource;
        this.changeRef.markForCheck();
    }
    onRemove() {
        this.resourceForm.reset();
        this.resourceForm.get('title').enable();
        this.resourceForm.get('description').enable();
        this.resourceForm.get('language_id').enable();
        this.resourceForm.get('tag_ids').enable();
        this.resourceForm.get('article_code').enable();
        this.selectedResource = null;
        this.changeRef.markForCheck();
    }
    submit() {
        if (!this.resourceForm.valid) {
            return;
        }
        this.prepareData()
            .pipe(
                switchMap((data) =>
                    this.notesService
                        .addHrNote(this.prepareNotesData(), ['managers,tags'])
                        .pipe(
                            switchMap(() =>
                                this.selectedResource
                                    ? this.resourceService.update({ ...data, ...{ id: this.selectedResource.id } })
                                    : this.resourceService.store(data)
                            )
                        )
                ),
                takeUntil(this.destroy)
            )
            .subscribe((resource) =>
                this.onClose.emit({
                    action: SideNaveActionsTypes.POSITIVE,
                    data: { resource: resource, type: this.navData.data.type },
                })
            );
    }
    private prepareData() {
        let data = this.resourceForm.value;
        const formValues = this.resourceForm.value;
        data.occasion = {
            user_id: this.navData.data.user.id,
            done_at: formValues.done_at?.toLocaleString() ?? new Date(0).toISOString().toLocaleString(),
        };
        data.type_id = ResourceTypes.OTHER;
        delete data.article_code;
        return of(data);
    }

    private prepareNotesData(): HrNotesData {
        const title = this.resourceForm.value.title ? $localize`title: ${this.resourceForm.value.title}` : '';
        const sku = this.resourceForm.value.article_code ? $localize`SKU: ${this.resourceForm.value.article_code}` : '';
        const note = this.resourceForm.value.note ? $localize`note: ${this.resourceForm.value.note}` : '';
        const done = this.resourceForm.value.done_at
            ? $localize`date of completion: ${new DatePipe('en')
                  .transform(this.resourceForm.value.done_at, 'yyyy-MM-dd')
                  .toString()}`
            : '';

        let data: HrNotesData = {
            body: $localize`Added competence: ${[title, sku, done, note].filter(Boolean).join(', ')}`,
            staff: {
                manager: [this.navData.data.authUser.id],
            },
            tag_ids: this.resourceForm.value.tag_ids,
            created_at: new DatePipe('en').transform(Date.now(), 'yyyy-MM-dd').toString(),
            user_id: this.navData.data.user.id,
        };

        return new HrNotes(data).getPayload();
    }
}
