<div class="login" fxLayout="column">
    <ng-container *ngIf="accessMode === 'login'">
        <h3 i18n>Login</h3>
        <!-- <app-general-login-form></app-general-login-form> -->
        <p *ngIf="navData.data.canSignup" class="pointer red mt-0 register">
            <strong>
                <span class="pointer mt-0 red" i18n (click)="accessMode = 'signup'"> Create account </span>
            </strong>
        </p>
    </ng-container>
    <ng-container *ngIf="accessMode === 'signup'">
        <h3 i18n>Signup</h3>
        <!-- <app-general-signup-form [conditionsLines]="false"></app-general-signup-form> -->
        <p *ngIf="navData.data.canSignup" class="pointer red mt-0 register">
            <strong>
                <span class="pointer mt-0 red" i18n (click)="accessMode = 'login'"> Already has account </span>
            </strong>
        </p>
    </ng-container>
</div>
