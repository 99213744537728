import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import { MessageSentComponent, MessageService } from '@klickdata/core/message';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { User, UserMessage, UserMessageData, UserService } from '@klickdata/core/user';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { Observable, of } from 'rxjs';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-menu-side-email-multiple-users',
    templateUrl: './menu-side-email-multiple-users.component.html',
    styleUrls: ['./menu-side-email-multiple-users.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideEmailMultipleUsersComponent extends OnDestroyHandler implements OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    public mailForm: FormGroup;
    public loading: boolean;
    public selection = new SelectionModel<User>(true, []);
    constructor(
        protected fb: FormBuilder,
        protected userService: UserService,
        protected auth: AuthService,
        protected messageService: MessageService
    ) {
        super();
        this.mailForm = this.fb.group({
            subject: ['', Validators.required],
            body: ['', Validators.required],
        });
    }

    ngOnInit(): void {
        this.selection.setSelection(...this.users);
    }

    get users(): User[] {
        return this.navData.data.users?.length ? (this.navData.data.users as User[]) : [];
    }
    toggleSelection(user: User): void {
        this.selection.toggle(user);
    }

    // Check if a user is selected
    isSelected(user: User): boolean {
        return this.selection.isSelected(user);
    }

    // Get the list of selected users
    getSelectedUsers(): User[] {
        return this.selection.selected;
    }
    submitMail() {
        this.prepareUserMessage()
            .pipe(
                switchMap((msg) => this.userService.send(msg)),
                takeUntil(this.destroy)
            )
            .subscribe((res) => {
                this.loading = false;
                this.messageService.openMessage(MessageSentComponent);
                this.mailForm.reset();
                this.mailForm.patchValue({ body: '' });
                FormHelper.resetForm(this.mailForm);
                this.onClose.emit();
            });
    }
    protected prepareUserMessage(): Observable<UserMessageData> {
        const data = new UserMessage({
            ...this.mailForm.value,
        }).getData();
        if (this.users.length) {
            data.users_attach = this.selection.selected.map((user) => user.id);
        }
        return this.auth.getCustomer().pipe(
            first(),
            map((customer) => {
                data.customer = customer.id;
                return data;
            })
        );
    }
}
