<div class="res-listing-wrapper mobile-reso-listing-general" fxLayout="column" fxFlex="100%">
    <mat-accordion class="filters-acc res-list-general-acc" displayMode="flat">
        <mat-expansion-panel [expanded]="openFilters" #expansionPanel>
            <mat-expansion-panel-header> </mat-expansion-panel-header>
            <app-table-toolbar class="toolbar" color="accent">
                <app-table-filter
                    [cacheScope]="AppScope.RESOURCES"
                    [filtersTogglesInOnBtn]="true"
                    [inGlobalFilter]="true"
                    color="accent"
                    class="res-list-table-filter"
                    #filter
                >
                    <app-table-search [inGlobalFilter]="true" class="align-space" searchFilterRef></app-table-search>

                    <app-tag-chip-search-filter
                        [inGlobalFilter]="true"
                        [languageChange]="langFilter.filterChange"
                        fxFlex="50%"
                        fxFlex.lt-md="100%"
                        fxLayoutAlign="start"
                        tagsFilterRef
                    ></app-tag-chip-search-filter>
                    <app-category-chip-search-filter
                        [inGlobalFilter]="true"
                        fxFlex="50%"
                        fxFlex.lt-md="100%"
                        [scope_id]="type_scope_id"
                        categoriesFilterRef
                    ></app-category-chip-search-filter>

                    <app-table-date-filter [inGlobalFilter]="true" color="accent" dateFilterRef></app-table-date-filter>

                    <app-select-filter
                        *ngIf="master"
                        class="filter-space mr-2"
                        [label]="AppScope.label(type_scope_id, false) + ' status'"
                        i18n-label
                        [property]="{
                            name: GlobalFilterProperty.STATUS,
                            type: GlobalFilterPropertyType.STATUS_RESOURCE
                        }"
                        [options]="resourceSelectOptions"
                        selectFilterRef
                    ></app-select-filter>

                    <app-select-filter
                        *ngIf="!master"
                        [inGlobalFilter]="true"
                        class="filter-space mr-2"
                        [label]="AppScope.label(type_scope_id, false) + ' status'"
                        i18n-label
                        [property]="{
                            name: GlobalFilterProperty.STATUS,
                            type: GlobalFilterPropertyType.STATUS_RESOURCE
                        }"
                        [options]="resourceSelectOptions"
                        resourceStateFilterRef
                    ></app-select-filter>

                    <app-language-filter
                        [inGlobalFilter]="true"
                        #langFilter
                        fxFlex="0 0 auto"
                        fxLayout="row"
                        [prefLangIds]="(customer || (customer$ | async))?.pref_lang_ids"
                        langFilterRef
                    ></app-language-filter>
                    <app-select-filter
                        [inGlobalFilter]="true"
                        class="filter-space"
                        label="Access permission"
                        i18n-label
                        [property]="GlobalFilterProperty.PERMISSION_TYPE"
                        [options]="permissionTypeOptions"
                        resourceAccessPermissionsFilterRef
                    >
                    </app-select-filter>
                </app-table-filter>

                <mat-progress-bar
                    class="progress progress-table-loader"
                    *ngIf="(dataSource.status | async) === 'loading'"
                    mode="indeterminate"
                >
                </mat-progress-bar>
            </app-table-toolbar>
        </mat-expansion-panel>
    </mat-accordion>
    <div *ngIf="showActionHeader" class="header-replace">
        <div class="hover-wrapper top" fxLayoutAlign="space-around center">
            <ng-container *ngIf="!canRestoreResource" [ngTemplateOutlet]="selectiveTableActionBtns"></ng-container>

            <button
                *ngIf="canRestoreResource"
                class="tools-btn red"
                mat-raised-button
                [class.disableIt]="!authUser.isAdmin()"
                (click)="$event.stopPropagation(); restoreResource()"
            >
                <mat-icon class="material-icons-outlined">restore_from_trash</mat-icon>
                <span class="btn-label" i18n="@@restore">Restore</span>
            </button>
        </div>
    </div>
    <div class="table-wrapper">
        <mat-table
            #table
            [dataSource]="dataSource"
            [matSortActive]="author ? 'updated_at' : 'published'"
            matSortDirection="desc"
            matSort
            [class.mobileView]="isMobile"
            [class.showHeader]="showActionHeader"
            class="{{ tableContext }}"
        >
            <ng-container matColumnDef="select">
                <mat-header-cell *matHeaderCellDef>
                    <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        [aria-label]="checkboxLabel()"
                    >
                    </mat-checkbox>
                </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <mat-checkbox
                        *ngIf="!resource.isProcessing(); else loadingTemp"
                        (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(resource) : null"
                        [checked]="selection.isSelected(resource)"
                        [aria-label]="checkboxLabel(resource)"
                    ></mat-checkbox>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="columns">
                <mat-header-cell *matHeaderCellDef>
                    <div fxLayoutAlign="start center">
                        <span i18n="@@image">Image</span>
                    </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <app-image-view
                        *ngIf="!isMobile"
                        [media_id]="resource.media$"
                        [proportional]="true"
                        (click)="showImgModal(resource.media$)"
                    ></app-image-view>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@title"> Title </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <ng-container *ngIf="!isMobile">
                        <span [class.show-item-on-hover]="disableHoverEffect" class="original">{{
                            resource.title
                        }}</span>
                        <div [class.hide-item-on-hover]="disableHoverEffect" class="hover">
                            <div class="hover-wrapper" fxLayoutAlign="space-between center">
                                <ng-container
                                    *ngIf="!canRestoreResource"
                                    [ngTemplateOutlet]="tableCellActionBtns"
                                    [ngTemplateOutletContext]="{ resource: resource }"
                                ></ng-container>
                                <button
                                    class="tools-btn green-mobile"
                                    matTooltip="Restore"
                                    mat-icon-button
                                    *ngIf="canRestoreResource"
                                    [class.disableIt]="!resource.user_collaboration?.edit"
                                    (click)="
                                        resource.user_collaboration?.edit && $event.stopPropagation();
                                        authUser.canDelete(resource) && restoreResource(resource.id)
                                    "
                                    i18n-matTooltip
                                >
                                    <mat-icon>restore_from_trash</mat-icon>
                                </button>
                                <ng-container *ngIf="resource.last_publish">
                                    <button
                                        class="hover-tools-btn"
                                        *ngIf="!(shareLoading | async); else smallLoadingTemp"
                                        mat-icon-button
                                        matTooltip="Share"
                                        i18n-matTooltip="@@share"
                                        (click)="shareService.openShareDialog(resource, shareLoading)"
                                    >
                                        <mat-icon>share</mat-icon>
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                    <div
                        (click)="expandedRow = expandedRow === resource ? null : resource"
                        class="mobile-row-title"
                        *ngIf="isMobile"
                    >
                        <div class="mobile-img-wrapper">
                            <app-image-view
                                [smallSpinner]="true"
                                [media_id]="resource.media$"
                                [proportional]="true"
                            ></app-image-view>
                        </div>
                        <div class="info-main-wrapper">
                            <span class="item-title">{{ resource.title }}</span>
                            <div
                                [class.endRowBtn]="
                                    ResourceTypes.parentType(resource.type_id) === ResourceTypes.COURSE ||
                                    ResourceTypes.parentType(resource.type_id) === ResourceTypes.TEST ||
                                    ResourceTypes.parentType(resource.type_id) === ResourceTypes.SURVEY
                                "
                                fxLayout="row"
                                fxLayoutGap="5px"
                                fxLayoutAlign="start center"
                            >
                                <div
                                    class="nu-items"
                                    *ngIf="
                                        ResourceTypes.parentType(resource.type_id) === ResourceTypes.COURSE ||
                                        ResourceTypes.parentType(resource.type_id) === ResourceTypes.TEST ||
                                        ResourceTypes.parentType(resource.type_id) === ResourceTypes.SURVEY
                                    "
                                >
                                    <span>
                                        {{ resource.number_of_items }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutAlign="start start" class="mobile-tools-collapsed">
                            <button
                                class="expand-btn"
                                mat-icon-button
                                (click)="
                                    $event.stopPropagation(); expandedRow = expandedRow === resource ? null : resource
                                "
                            >
                                <mat-icon>
                                    {{ expandedRow === resource ? 'expand_less' : 'expand_more' }}
                                </mat-icon>
                            </button>
                            <button
                                class="tools-btn green-mobile"
                                *ngIf="resource.user_collaboration?.edit"
                                mat-icon-button
                                (click)="
                                    $event.stopPropagation();
                                    resource.user_collaboration?.edit && authUser.canEdit(resource) && browse(resource)
                                "
                                matTooltip="Edit"
                                i18n-matTooltip="@@edit"
                            >
                                <mat-icon class="material-icons-outlined">edit</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef>
                    <div fxLayoutAlign="start center">
                        <span i18n="@@status">Status</span>
                    </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <div
                        *ngIf="resource | resourceStatus : this.stats as resourceStatus"
                        [ngStyle]="{ color: resourceStatus.color }"
                        fxLayoutAlign="start center"
                    >
                        <span i18n="@@status" *ngIf="isMobile" class="mobile-label"> Status </span>
                        <mat-icon>
                            {{ resourceStatus.icon }}
                        </mat-icon>
                        &nbsp;
                        {{ resourceStatus.status }}
                        <!-- <ng-container *ngIf="!isAuPlatform">
                            <ng-container *ngIf="customer$ | async; let customer; else: smallLoading">
                                <div fxLayout="row" *ngIf="resource.last_publish">
                                    <div
                                        i18n-matTooltip
                                        matTooltip="Number of learners who have access"
                                        class="count-circle user-count"
                                        *ngIf="resource.total_users_count !== customer.users_count"
                                        (click)="specifyPublish(resource)"
                                    >
                                        {{ resource.total_users_count }}
                                    </div>
                                    <div
                                        i18n-matTooltip
                                        matTooltip="Number of academies who have access"
                                        class="count-circle cluster-count"
                                        *ngIf="
                                            resource.customer_id === customer.id &&
                                            resource.public &&
                                            resource.customers_count !== customer.cluster_count
                                        "
                                        (click)="specifyPublic(resource)"
                                    >
                                        {{ resource.customers_count }}
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container> -->
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="categories">
                <mat-header-cell *matHeaderCellDef i18n="@@categories"> Categories </mat-header-cell>
                <mat-cell
                    [class.no-display-cell]="!resource.categories_attached"
                    class="inset-shadow"
                    *matCellDef="let resource"
                    ><div class="shadow-maker"></div>
                    <span i18n="@@categories" *ngIf="isMobile" class="mobile-label"> Categories </span>
                    <mat-chip-list class="middle-details">
                        <mat-chip
                            class="cat-chip-item"
                            (click)="onCatClick(cat)"
                            *ngFor="let cat of resource.categories"
                            >{{ cat.title }}
                        </mat-chip>
                    </mat-chip-list>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="tags">
                <mat-header-cell *matHeaderCellDef i18n="@@tags"> Tags </mat-header-cell>
                <mat-cell
                    [class.no-display-cell]="!resource.tags_attached"
                    class="inset-shadow"
                    *matCellDef="let resource"
                >
                    <div class="shadow-maker"></div>
                    <mat-chip-list *ngIf="resource.tags$ | async; let tags" class="middle-details">
                        <mat-chip
                            class="tag-chip-item"
                            (click)="onTagClick(tag)"
                            fxLayout="row"
                            fxLayoutAlign="start center"
                            *ngFor="let tag of sortTagsAlphabetical(tags)"
                            ><span>{{ tag.name }}</span>
                            <app-language-selector
                                class="flag tag-chip-lang-select"
                                fxFlex="0 0 auto"
                                [language_id]="tag.language_id"
                            >
                            </app-language-selector
                        ></mat-chip>
                    </mat-chip-list>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="certificate">
                <mat-header-cell *matHeaderCellDef i18n="@@certificate"> Certificate </mat-header-cell>
                <mat-cell *matCellDef="let resource" [ngStyle]="{ color: (resource | resourceCertificate).color }">
                    <span i18n="@@certificate" *ngIf="isMobile" class="mobile-label"> Certificate </span>
                    <mat-icon>
                        {{ (resource | resourceCertificate).icon }}
                    </mat-icon>
                    &nbsp;
                    {{ (resource | resourceCertificate).certificate }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="participation">
                <mat-header-cell *matHeaderCellDef i18n="@@participation"> Participation </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span i18n="@@participation" *ngIf="isMobile" class="mobile-label"> participation </span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="created_at">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@created"> Created </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span i18n="@@created" *ngIf="isMobile" class="mobile-label"> Created </span>
                    {{ resource.created_at | kdDateFormat : 'YYYY-MM-DD' }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="started">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@started"> Started </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span i18n="@@created" *ngIf="isMobile" class="mobile-label"> Created </span>
                    {{ resource.created_at | kdDateFormat : 'YYYY-MM-DD' }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="completed">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@completed"> Completed </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span i18n="@@created" *ngIf="isMobile" class="mobile-label"> completed </span>
                    {{ resource.created_at | kdDateFormat : 'YYYY-MM-DD' }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="type_id">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@created"> Type </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span i18n="@@type" *ngIf="isMobile" class="mobile-label"> Type </span>
                    <div fxLayoutAlign="start center" fxLayoutGap="5px">
                        <ng-container *ngIf="!resource.isProcessing()">
                            <mat-icon *ngIf="!resource.prompt_uuid">{{
                                ResourceTypes.materialTypeIcon({ resType: resource.type_id })
                            }}</mat-icon>
                            <span *ngIf="!resource.prompt_uuid">{{
                                ResourceTypes.materialType(resource.type_id)
                            }}</span>
                            <mat-icon *ngIf="resource.prompt_uuid">smart_toy</mat-icon>
                            <span *ngIf="resource.prompt_uuid" i18n>AI Prompt</span>
                            <ng-container *ngIf="resource.loading">
                                <app-kd-logo-loading size="small"></app-kd-logo-loading>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="resource.isProcessing()">
                            <mat-icon class="ai-icon">smart_toy</mat-icon>
                        </ng-container>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="taken">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@taken"> Taken </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span i18n="@@taken" *ngIf="isMobile" class="mobile-label"> Taken </span>
                    {{ resource.taken_times }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="published">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@publishedAt"> Published at </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span i18n="@@publishedAt" *ngIf="isMobile" class="mobile-label"> Published at </span>
                    <span *ngIf="resource.published">
                        {{ resource.published | kdDateFormat : 'YYYY-MM-DD' }}
                    </span>
                    <span *ngIf="!resource.published" i18n="@@notPublishedYet"> Not yet. </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="done">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@done"> Done </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span i18n="@@done" *ngIf="isMobile" class="mobile-label"> Done </span>
                    <span *ngIf="resource.done">
                        {{ resource.done | kdDateFormat : 'YYYY-MM-DD' }}
                    </span>
                    <span *ngIf="!resource.done" i18n="@@notYet">Not yet</span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="article_code">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@articleNumber">
                    Article number
                </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span i18n="@@articleNumber" *ngIf="isMobile" class="mobile-label"> Article number </span>
                    <span *ngIf="resource.article_code">
                        {{ resource.article_code }}
                    </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="result">
                <mat-header-cell *matHeaderCellDef i18n="@@result"> Results </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span i18n="@@result" *ngIf="isMobile" class="mobile-label"> Results </span>
                    <span *ngIf="resource.done">
                        {{ resource.done }}
                    </span>
                    <span *ngIf="!resource.done" i18n="@@notYet">Not yet</span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="updated_at">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@updated"> Updated </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span i18n="@@updated" *ngIf="isMobile" class="mobile-label"> Updated </span>
                    {{ resource.updated_at | kdDateFormat : 'YYYY-MM-DD' }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="author_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@author"> Author </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span i18n="@@author" *ngIf="isMobile" class="mobile-label"> Author </span>
                    <ng-container *ngIf="resource.author$ | async; let loadedAuthor">
                        <span
                            matTooltip="Author is the person who put all the content in the course together from various sources"
                            i18n-matTooltip
                            class="author-nm"
                            (click)="$event.stopPropagation(); authUser.isAdmin() && onAuthorClick(loadedAuthor)"
                            >{{ $any(loadedAuthor).name }}</span
                        >
                    </ng-container>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="customer_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@customer"> Customer </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span i18n="@@customer" *ngIf="isMobile" class="mobile-label"> Customer </span>
                    <ng-container *ngIf="resource.customer | async; let loadedCustomer">
                        {{ $any(loadedCustomer).name }}
                    </ng-container>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="question_number">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@Q#"> Q# </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span i18n="@@Q#" *ngIf="isMobile" class="mobile-label"> Q# </span>
                    {{ resource.number_of_items }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="priority">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@priority"> Priority </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span i18n="@@priority" *ngIf="isMobile" class="mobile-label"> Priority </span>
                    <app-item-change-on-the-fly
                        [capitalize]="true"
                        [selectionOptions]="resourcePrioOptions"
                        [value]="resource.priority"
                        (onValueChange)="resource.loading = true; updateResourcePrio(resource, $event)"
                    ></app-item-change-on-the-fly>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="sorting_code">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@sortingCode"> Sorting code </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span i18n="@@sortingCode" *ngIf="isMobile" class="mobile-label"> Sorting code </span>
                    <app-item-change-on-the-fly
                        type="number"
                        [value]="resource.sorting_code"
                        (onValueChange)="resource.loading = true; updateResourceSortingCode(resource, $event)"
                    ></app-item-change-on-the-fly>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="certifier">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@certifier"> Certifier </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span i18n="@@certifier" *ngIf="isMobile" class="mobile-label"> Certifier </span>
                    <span>
                        {{ resource.author_name || $any(resource.author$ | async)?.name }}
                    </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="language">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    <div fxLayoutAlign="start center">
                        <span i18n="@@lang">Lang</span>
                    </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span i18n="@@lang" *ngIf="isMobile" class="mobile-label"> Lang </span>
                    <ng-container *ngIf="resource.language_id">
                        <app-language-selector
                            [language_id]="resource.language_id"
                            class="icon-resource-builder"
                            fxFlex="0 0 auto"
                        ></app-language-selector>
                    </ng-container>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="tools">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@tools"> Tools </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span i18n="@@tools" *ngIf="isMobile" class="mobile-label"> Tools </span>
                    <ng-container
                        *ngIf="!canRestoreResource"
                        [ngTemplateOutlet]="tableCellActionBtns"
                        [ngTemplateOutletContext]="{ resource: resource }"
                    ></ng-container>
                    <button
                        class="tools-btn green-mobile"
                        matTooltip="Restore"
                        mat-icon-button
                        *ngIf="canRestoreResource"
                        [class.disableIt]="!resource.user_collaboration?.edit"
                        (click)="
                            $event.stopPropagation();
                            resource.user_collaboration?.edit &&
                                authUser.canDelete(resource) &&
                                restoreResource(resource.id)
                        "
                        i18n-matTooltip
                    >
                        <mat-icon>restore_from_trash</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="edit_btn">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@edit"> Edit </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span i18n="@@edit" *ngIf="isMobile" class="mobile-label"> Edit </span>
                    <ng-container *ngIf="resource.loading">
                        <div class="loaded-wrapper">
                            <ng-template [ngTemplateOutlet]="smallLoading"></ng-template></div
                    ></ng-container>
                    <button
                        *ngIf="
                            ResourceTypes.parentType(resource.type_id) !== ResourceTypes.E_COURSE && !resource.loading
                        "
                        class="tools-btn green-mobile"
                        [class.disableIt]="!resource.user_collaboration?.edit"
                        mat-icon-button
                        (click)="
                            $event.stopPropagation();
                            resource.user_collaboration?.edit && authUser.canEdit(resource) && browse(resource)
                        "
                        matTooltip="Edit"
                        i18n-matTooltip="@@edit"
                    >
                        <mat-icon class="material-icons-outlined">edit</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="extra">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span *ngIf="isMobile" class="mobile-label"></span>
                    <ng-container
                        *ngIf="!canRestoreResource"
                        [ngTemplateOutlet]="tableCellActionBtns"
                        [ngTemplateOutletContext]="{ resource: resource }"
                    ></ng-container>
                    <button
                        class="tools-btn green-mobile table-cell-mob-btn"
                        matTooltip="Restore"
                        mat-icon-button
                        *ngIf="canRestoreResource && resource.user_collaboration?.edit"
                        [class.disableIt]="!resource.user_collaboration?.edit"
                        (click)="
                            $event.stopPropagation();
                            resource.user_collaboration?.edit &&
                                authUser.canDelete(resource) &&
                                restoreResource(resource.id)
                        "
                        i18n-matTooltip
                    >
                        <mat-icon>restore_from_trash</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
            <mat-row
                [ngClass]="!resource.loading ? 'link' : 'disableIt'"
                (click)="$event.stopPropagation(); onRowClicked(resource)"
                *matRowDef="let resource; columns: columns"
                [class.res-expanded-row]="expandedRow === resource"
            ></mat-row>
        </mat-table>
    </div>
</div>
<mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>

<ng-template #loadingTemp>
    <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="center" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
<ng-template #rowHover>
    <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="center" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
<ng-template #smallLoadingTemp>
    <app-kd-logo-loading class="res-lst-loader" size="small"></app-kd-logo-loading>
</ng-template>
<ng-template #tableCellActionBtns let-resource="resource">
    <button
        *ngIf="
            resource.user_collaboration?.tutor &&
            (tableTools.includes(ResourceTableActions.SENDCOURSE) || type_scope_id === AppScope.COURSE) &&
            authUser.canAssign(resource) &&
            ResourceTypes.parentType(resource.type_id) !== ResourceTypes.SCORM
        "
        class="tools-btn green-mobile table-cell-mob-btn"
        matTooltip="Assign"
        mat-icon-button
        [class.disableIt]="!authUser.canAssign(resource)"
        (click)="$event.stopPropagation(); authUser.canAssign(resource) && sendCoursePlan(resource)"
        i18n-matTooltip="@@assign"
    >
        <mat-icon *ngIf="!resource.assign_id">assignment_ind</mat-icon>
        <img *ngIf="resource.assign_id" src="assets/images/edit-assign.svg" width="24" alt="Edit Assign" />
    </button>
    <button
        class="tools-btn green-mobile table-cell-mob-btn"
        matTooltip="Access control"
        mat-icon-button
        *ngIf="
            !isAuPlatform &&
            tableTools.includes(ResourceTableActions.ACCESS_CONTROL) &&
            authUser.canSetAccessControls(resource) &&
            (type_scope_id === AppScope.COURSE ||
                type_scope_id === AppScope.MATERIAL ||
                type_scope_id === AppScope.TEST)
        "
        [class.disableIt]="!authUser.canSetAccessControls(resource)"
        (click)="$event.stopPropagation(); authUser.canSetAccessControls(resource) && authorizeUsers(resource)"
        i18n-matTooltip
    >
        <mat-icon>manage_accounts</mat-icon>
    </button>
    <button
        class="tools-btn green-mobile table-cell-mob-btn"
        matTooltip="Collect"
        mat-icon-button
        *ngIf="
            tableTools.includes(ResourceTableActions.COLLECT) &&
            authUser.canCollect(resource) &&
            ResourceTypes.parentType(resource.type_id) !== ResourceTypes.SCORM
        "
        [class.disableIt]="!authUser.canCollect(resource)"
        (click)="$event.stopPropagation(); authUser.canCollect(resource) && showCollections(resource)"
        i18n-matTooltip
    >
        <mat-icon>source</mat-icon>
    </button>

    <button
        class="tools-btn table-cell-mob-btn"
        matTooltip="Recommend"
        mat-icon-button
        *ngIf="
            tableTools.includes(ResourceTableActions.ASSIGN) &&
            ResourceTypes.parentType(resource.type_id) !== ResourceTypes.SCORM
        "
        [class.disableIt]="!authUser.canRecommend(resource)"
        (click)="$event.stopPropagation(); authUser.canRecommend(resource) && showRecommends(resource)"
        i18n-matTooltip
    >
        <mat-icon>recommend</mat-icon>
    </button>
    <!-- <button
        class="tools-btn"
        matTooltip="Assignments"
        mat-icon-button
        *ngIf="tableTools.includes(ResourceTableActions.ASSIGN)"
        [class.disableIt]="!authUser.canAssign(resource)"
        (click)="$event.stopPropagation(); authUser.canAssign(resource) && showAssignments(resource)"
        i18n-matTooltip
    >
        <mat-icon>assignment</mat-icon>
    </button> -->

    <button
        *ngIf="tableTools.includes(ResourceTableActions.DUPLICATE) && !isAuPlatform"
        class="tools-btn table-cell-mob-btn"
        mat-icon-button
        matTooltip="Duplicate"
        (click)="duplicate(resource)"
        i18n-matTooltip="@@duplicate"
    >
        <mat-icon>content_copy</mat-icon>
    </button>

    <button
        *ngIf="
            resource.user_collaboration?.publish &&
            tableTools.includes(ResourceTableActions.PUBLISH) &&
            !resource.last_publish &&
            authUser.canPublish(resource)
        "
        class="tools-btn red table-cell-mob-btn"
        mat-icon-button
        matTooltip="Specify which learners and groups who will have access and Publish"
        [class.disableIt]="!authUser.canPublish(resource)"
        (click)="$event.stopPropagation(); authUser.canPublish(resource) && publish(true, resource)"
        i18n-matTooltip="@@clickToPublish"
    >
        <mat-icon class="material-icons-outlined">unpublished</mat-icon>
    </button>

    <button
        *ngIf="
            resource.user_collaboration?.publish &&
            tableTools.includes(ResourceTableActions.UNPUBLISH) &&
            resource?.last_publish &&
            authUser.canUnPublished(resource)
        "
        class="tools-btn green-mobile table-cell-mob-btn"
        mat-icon-button
        matTooltip="Specify which learners and groups who will have access and Publish"
        [class.disableIt]="!authUser.canUnPublished(resource)"
        (click)="$event.stopPropagation(); authUser.canUnPublished(resource) && publish(false, resource)"
        i18n-matTooltip="@@clickToUnpublish"
    >
        <mat-icon class="material-icons-outlined">published_with_changes</mat-icon>
    </button>

    <!-- <button
        *ngIf="tableTools.includes(ResourceTableActions.PUBLIC) && !resource.public && authUser.canPublicize(resource)"
        class="tools-btn red table-cell-mob-btn"
        mat-icon-button
        matTooltip="Click to make public"
        [class.disableIt]="!authUser.canPublicize(resource)"
        (click)="$event.stopPropagation(); authUser.canPublicize(resource) && public(true, resource)"
        i18n-matTooltip="@@clickToMakePublic"
    >
        <mat-icon>public_off</mat-icon>
    </button> -->
    <ng-container *ngIf="!(downloadLoading | async); else smallLoadingTemp">
        <button
            *ngIf="tableTools.includes(ResourceTableActions.DOWNLOAD) && authUser.canDownload(resource)"
            class="tools-btn table-cell-mob-btn"
            mat-icon-button
            matTooltip="Click to download"
            [class.disableIt]="!authUser.canDownload(resource)"
            (click)="$event.stopPropagation(); authUser.canDownload(resource) && download(resource)"
            i18n-matTooltip="@@clickToMakeDownload"
        >
            <mat-icon>save_alt</mat-icon>
        </button>
    </ng-container>
    <!-- <button
        *ngIf="
            tableTools.includes(ResourceTableActions.UNPUBLIC) && resource.public && authUser.canUnPublicized(resource)
        "
        class="tools-btn green-mobile table-cell-mob-btn"
        mat-icon-button
        matTooltip="Click to make unpublic"
        [class.disableIt]="!authUser.canUnPublicized(resource)"
        (click)="$event.stopPropagation(); authUser.canUnPublicized(resource) && public(false, resource)"
        i18n-matTooltip="@@clickToMakeUnPublic"
    >
        <mat-icon>public</mat-icon>
    </button> -->
    <button
        class="tools-btn green-mobile table-cell-mob-btn"
        [class.disableIt]="!resource.user_collaboration?.edit"
        mat-icon-button
        [ngClass]="{ 'hide-this': ResourceTypes.parentType(resource.type_id) !== ResourceTypes.MATERIAL && isMobile }"
        (click)="
            $event.stopPropagation();
            resource.user_collaboration?.edit && authUser.canEdit(resource) && browse(resource)
        "
        matTooltip="Edit"
        i18n-matTooltip="@@edit"
        *ngIf="ResourceTypes.parentType(resource.type_id) !== ResourceTypes.E_COURSE && !isMobile"
    >
        <mat-icon class="material-icons-outlined">edit</mat-icon>
    </button>
    <app-language-selector
        fxHide.gt-sm="true"
        [language_id]="resource.language_id"
        class="icon-resource-builder"
        fxFlex="0 0 auto"
    ></app-language-selector>
    <button
        class="tools-btn btn-view table-cell-mob-btn"
        mat-icon-button
        (click)="$event.stopPropagation(); view(resource)"
        matTooltip="View"
        i18n-matTooltip="@@view"
    >
        <mat-icon>visibility</mat-icon>
    </button>
    <button
        class="tools-btn btn-view table-cell-mob-btn"
        mat-icon-button
        (click)="$event.stopPropagation(); inviteFriend(resource)"
        matTooltip="Invite a friend"
        i18n-matTooltip
    >
        <mat-icon>outgoing_mail</mat-icon>
    </button>
    <button
        *ngIf="ResourceTypes.parentType(resource.type_id) === ResourceTypes.SCORM"
        class="tools-btn btn-view table-cell-mob-btn"
        mat-icon-button
        (click)="$event.stopPropagation(); onDownload.emit(resource)"
        matTooltip="Download SCORM"
        i18n-matTooltip="@@downloadSCORM"
    >
        <mat-icon>save_alt</mat-icon>
    </button>
    <button
        *ngIf="ResourceTypes.parentType(resource.type_id) != ResourceTypes.EVENT"
        class="tools-btn btn-view table-cell-mob-btn"
        mat-icon-button
        (click)="$event.stopPropagation(); play(resource)"
        matTooltip="Start"
        i18n-matTooltip="@@start"
    >
        <mat-icon>play_circle</mat-icon>
    </button>

    <button
        fxHide.lt-md="true"
        class="tools-btn table-cell-mob-btn"
        [class.disableIt]="!resource.user_collaboration?.edit"
        mat-icon-button
        (click)="
            $event.stopPropagation();
            resource.user_collaboration?.edit && authUser.canDelete(resource) && remove(resource)
        "
        matTooltip="Delete"
        i18n-matTooltip="@@delete"
        *ngIf="!isAuPlatform && ResourceTypes.parentType(resource.type_id) !== ResourceTypes.E_COURSE"
    >
        <mat-icon class="material-icons-outlined">delete</mat-icon>
    </button>
    <ng-container *ngIf="resource.last_publish">
        <button
            class="tools-btn share-tools-btn table-cell-mob-btn"
            mat-icon-button
            (click)="shareService.openShareDialog(resource, shareLoading)"
            matTooltip="Share"
            i18n-matTooltip="@@share"
            *ngIf="!(shareLoading | async); else smallLoadingTemp"
        >
            <mat-icon>share</mat-icon>
        </button>
    </ng-container>
</ng-template>
<ng-template #selectiveTableActionBtns>
    <button
        class="tools-btn red"
        mat-raised-button
        matTooltip="Specify which learners and groups who will have access and Publish"
        [class.disableIt]="allResourcesArePublished || authUser.preventChangeLibraryResStatus(isKolResources)"
        (click)="$event.stopPropagation(); publishMultiple(true)"
        i18n-matTooltip="@@clickToPublish"
        *ngIf="SelectiveMultiRowsAction.includes(ResourceTableActions.UNPUBLISH)"
    >
        <mat-icon class="material-icons-outlined">published_with_changes</mat-icon>
        <span class="btn-label" i18n="@@publish">Publish</span>
    </button>

    <button
        class="tools-btn"
        mat-raised-button
        matTooltip="Specify which learners and groups who will have access and Publish"
        [class.disableIt]="allResourcesAreNotPublished || authUser.preventChangeLibraryResStatus(isKolResources)"
        (click)="$event.stopPropagation(); publishMultiple(false)"
        i18n-matTooltip="@@clickToUnpublish"
        *ngIf="SelectiveMultiRowsAction.includes(ResourceTableActions.PUBLISH)"
    >
        <mat-icon class="material-icons-outlined">unpublished</mat-icon>
        <span class="btn-label" i18n="@@unpublish">Unpublish</span>
    </button>

    <button
        class="tools-btn red"
        mat-raised-button
        matTooltip="Click to make public"
        [class.disableIt]="allResourcesArePubliced || authUser.preventChangeLibraryResStatus(isKolResources)"
        (click)="$event.stopPropagation(); public(true)"
        i18n-matTooltip="@@clickToMakePublic"
        *ngIf="SelectiveMultiRowsAction.includes(ResourceTableActions.UNPUBLIC)"
    >
        <mat-icon>public</mat-icon>
        <span class="btn-label" i18n="@@public">Public</span>
    </button>

    <button
        class="tools-btn"
        mat-raised-button
        matTooltip="Click to make unpublic"
        [class.disableIt]="allResourcesAreNotPubliced || authUser.preventChangeLibraryResStatus(isKolResources)"
        (click)="$event.stopPropagation(); public(false)"
        i18n-matTooltip="@@clickToMakeUnPublic"
        *ngIf="SelectiveMultiRowsAction.includes(ResourceTableActions.PUBLIC)"
    >
        <mat-icon>public_off</mat-icon>
        <span class="btn-label" i18n="@@unpublic">Unpublic</span>
    </button>
    <button
        class="tools-btn course-create"
        mat-raised-button
        matTooltip="Click to create course"
        [class.disableIt]="!allResourcesArePublished"
        (click)="$event.stopPropagation(); createCourse()"
        i18n-matTooltip="@@clickToCreateCourse"
        *ngIf="SelectiveMultiRowsAction.includes(ResourceTableActions.CREATE_COURSE)"
    >
        <mat-icon
            fxFlex="0 0 auto"
            color="primary"
            fontSet="klickdata"
            fontIcon="kd-icon-task"
            class="course-plan-icon kd-icon-24 no-shadow"
        ></mat-icon>
        <span class="btn-label" i18n>Create course</span>
    </button>

    <button
        class="tools-btn"
        mat-raised-button
        (click)="$event.stopPropagation(); remove()"
        matTooltip="Delete"
        i18n-matTooltip="@@delete"
        *ngIf="SelectiveMultiRowsAction.includes(ResourceTableActions.DELETE)"
    >
        <mat-icon class="material-icons-outlined">delete</mat-icon>
        <span class="btn-label" i18n="@@delete">Delete</span>
    </button>
    <ng-container *ngIf="!(downloadLoading | async); else smallLoadingTemp">
        <button
            class="tools-btn"
            mat-raised-button
            (click)="$event.stopPropagation(); downloadMany()"
            matTooltip="Download"
            i18n-matTooltip="@@download"
            *ngIf="SelectiveMultiRowsAction.includes(ResourceTableActions.DOWNLOAD)"
        >
            <mat-icon class="material-icons-outlined">save_alt</mat-icon>
            <span class="btn-label" i18n="@@download">Download</span>
        </button>
    </ng-container>
</ng-template>
<ng-template #smallLoading>
    <div fxLayout="row" fxLayoutAlign="center center" class="spinner-container mt-2">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>

<!-- Popup for Download Options -->
<!-- <ng-template #downloadPopup>
    <h3 mat-dialog-title>Download Options</h3>
    <mat-dialog-actions>
        <button mat-raised-button color="primary" (click)="downloadMany('xlsx')">CSV</button>
        <button mat-raised-button color="primary" (click)="downloadMany('pdf')">PDF</button>
        <button mat-raised-button color="primary" (click)="downloadMany('docx')">Document</button>
    </mat-dialog-actions>
</ng-template> -->
