import { Injectable } from '@angular/core';
import { PaginatorResponse, ResponseData } from '@klickdata/core/http';
import { TableHttpHelpers, TableHttpService, TableSource } from '@klickdata/core/table';
import { HrNotes, HrNotesData, UserNotesService } from '@klickdata/core/user-notes';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class K3NotesListingService extends UserNotesService implements TableHttpService<HrNotes> {
    public fetchData(source: TableSource<HrNotes>): Observable<ResponseData<HrNotes[]>> {
        const req = this.builder.get<HrNotesData[]>(this.notesUrl);

        TableHttpHelpers.managePagination(source.paginator, req);
        TableHttpHelpers.manageSort(source.sort, req);
        TableHttpHelpers.mapFilter(source.filter, req);
        req.putEager(['customer,tags,managers']);

        return req.request().pipe(
            map((res: PaginatorResponse<HrNotesData[]>) => {
                return {
                    data: res.data.map((hrNotes) => this.mapHrNotes(hrNotes)),
                    notify: res.notify,
                    more: res.more,
                    paginator: res.paginator,
                };
            })
        );
    }
}
