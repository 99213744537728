import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotesContext } from '@klickdata/core/user-notes';

@Component({
    selector: 'app-notes-context-display',
    templateUrl: './notes-context-display.component.html',
    styleUrls: ['./notes-context-display.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesContextDisplayComponent implements OnInit {
    @Input() context: NotesContext;
    @Output() onContextClick: EventEmitter<boolean> = new EventEmitter<boolean>();
    constructor() {}

    ngOnInit(): void {}
}
