import { animate, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { AuthService } from '@klickdata/core/auth';
import { GloalSearchService, GlobalSearchData } from '@klickdata/core/global-search';
import { MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { MobileService } from '@klickdata/core/mobile';
import { ResourceService, AppScope } from '@klickdata/core/resource';
import { BehaviorSubject, combineLatest, fromEvent, Observable, Subject } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    finalize,
    first,
    map,
    shareReplay,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { ResourceListingSheetComponent } from '../resource-listing-sheet/resource-listing-sheet.component';
@Component({
    selector: 'app-search',
    templateUrl: 'search.component.html',
    styleUrls: ['search.component.scss'],
    animations: [
        trigger('enterAnimation', [
            transition(':enter', [
                style({ transform: 'translateY(50%)', opacity: 0 }),
                animate('150ms', style({ transform: 'translateY(0)', opacity: 1 })),
            ]),
            transition(':leave', [
                style({ transform: 'translateY(0)', opacity: 1 }),
                animate('150ms', style({ transform: 'translateY(50%)', opacity: 0 })),
            ]),
        ]),
        trigger('fadeAnimation', [
            transition(':enter', [style({ opacity: 0 }), animate(600)]),

            transition(':leave', animate(600, style({ opacity: 0 }))),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit, OnDestroy, AfterViewInit {
    public searchControl = new FormControl();
    @Input() inOnboarding = false;
    @Input() guestMode = false;
    @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;
    @ViewChild(MatAutocomplete) autocomplete: MatAutocomplete;
    public suggestedResults$: Observable<GlobalSearchData[]>;
    public loading: boolean;
    public loadingSuggestedResults: boolean;
    private platform: string;
    public isMobile: Observable<boolean>;
    AppScope = AppScope;
    public searchTerm: string;
    private destroy: Subject<boolean> = new Subject<boolean>();
    private authenticated: boolean;
    private currentDataPage: number;
    private isLastPage: boolean;
    @ViewChild('searchInput') searchInput: ElementRef;
    protected paginatorPage: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    constructor(
        protected location: Location,
        protected fb: FormBuilder,
        protected resourceService: ResourceService,
        protected searchService: GloalSearchService,
        protected ref: ChangeDetectorRef,
        protected auth: AuthService,
        protected router: Router,
        protected bottomSheet: MatBottomSheet,
        protected mobile: MobileService,
        protected messageService: MessageService
    ) {
        this.platform = this.auth.getNK3PlatformValue();
        this.auth
            .check()
            .pipe(first(), takeUntil(this.destroy))
            .subscribe((value) => (this.authenticated = value));
    }

    ngOnInit() {
        this.isMobile = this.mobile.isMobile().pipe(shareReplay());
        this.suggestedResults$ = combineLatest([
            this.searchControl.valueChanges.pipe(
                debounceTime(300),
                distinctUntilChanged(),
                filter((query: string) => !!query?.length),
                tap((query) => {
                    this.loading = true;
                    this.searchTerm = query;
                    this.searchService.lastSerchTerm.next(this.searchTerm);
                    this.ref.markForCheck();
                })
            ),
            this.paginatorPage.asObservable(),
        ]).pipe(
            switchMap(([query, page]) =>
                this.searchService.getSuggestedSearch(query.trim(), page).pipe(
                    tap((data) => {
                        if (!data.data.length) {
                            this.searchTerm = null;
                        }
                        this.currentDataPage = data.paginator.current_page;
                        this.isLastPage = data.paginator.current_page === data.paginator.total_pages;
                    }),
                    map((res) => res.data),
                    finalize(() => {
                        this.loading = false;
                        this.loadingSuggestedResults = false;
                    })
                )
            )
        );
    }
    public ngAfterViewInit() {
        this.searchService.lastSerchTerm.asObservable().subscribe((query) => {
            query && query.length ? this.searchControl.setValue(query) : this.searchInput.nativeElement.focus();
        });
    }
    public showSuggestedResults(result: GlobalSearchData) {
        this.searchService.activateSearchBox(false);
        this.searchControl.setValue(this.searchTerm);
        if (AppScope.isResource(result.scope_id)) {
            switch (result.scope_id) {
                case AppScope.COURSE:
                case AppScope.E_COURSE:
                    if (this.authenticated && result.item) {
                        this.router.navigate(['/home/courses/', result.artnr], {
                            queryParams: { position: result.item.position },
                        });
                    } else {
                        this.router.navigate([
                            this.authenticated ? '/home/dashboard/resource-details/' : 'guest/resource-details',
                            result.id,
                        ]);
                    }
                    break;
                default:
                    this.router.navigate([
                        this.authenticated ? '/home/dashboard/resource/' : 'guest/resource',
                        result.id,
                    ]);
            }

            // User & groups results can be under admin or master
        } else if (result.scope_id === AppScope.USERS) {
            if (this.auth.getNK3PlatformValue() !== 'master') {
                this.router.navigate(['/admin/accounts/users/users/', result.id]);
            } else {
                this.router.navigate(['/master/customers/', result.customer_id, 'accounts'], {
                    queryParams: { user_id: result.id },
                });
            }
        } else if (result.scope_id === AppScope.GROUPS) {
            if (this.auth.getNK3PlatformValue() !== 'master') {
                this.router.navigate(['/admin/accounts/groups/', result.id]);
            } else {
                this.router.navigate(['/master/customers/', result.customer_id, 'accounts'], {
                    queryParams: { group_id: result.id },
                });
            }
        } else if (result.scope_id === AppScope.CUSTOMERS) {
            this.router.navigate(['/master/customers/', result.id]);
        } else if (result.scope_id === AppScope.QUOTE) {
            this.router.navigate(['/admin/content/quotes/', result.id]);
        } else if (result.scope_id === AppScope.POST) {
            this.router.navigate(['/admin/content/posts/', result.id]);
        } else {
            this.bottomSheet.open(ResourceListingSheetComponent, {
                data: {
                    id: result.id,
                    label: result.label,
                    scope_id: result.scope_id,
                },
                panelClass: 'sheet-wrapper',
            });
        }
    }
    public autocompleteScroll() {
        /**
         * Deprecated load more action in suggested
         * results based on discussion with KL on 211026
         */
        return;
        setTimeout(() => {
            if (this.autocomplete && this.autocompleteTrigger && this.autocomplete.panel) {
                fromEvent(this.autocomplete.panel.nativeElement, 'scroll')
                    .pipe(
                        map(() => this.autocomplete.panel.nativeElement.scrollTop),
                        takeUntil(this.autocompleteTrigger.panelClosingActions || this.destroy)
                    )
                    .subscribe(() => {
                        const scrollTop = this.autocomplete.panel.nativeElement.scrollTop;
                        const scrollHeight = this.autocomplete.panel.nativeElement.scrollHeight;
                        const elementHeight = this.autocomplete.panel.nativeElement.clientHeight;
                        const atBottom = scrollHeight === scrollTop + elementHeight;
                        if (atBottom && !this.isLastPage) {
                            this.loadSuggestedNextPage();
                        }
                    });
            }
        });
    }
    public loadSuggestedNextPage() {
        this.loadingSuggestedResults = true;
        this.paginatorPage.next(this.currentDataPage);
    }
    public onSubmit() {
        this.autocompleteTrigger.closePanel();
        if (this.searchTerm?.length) {
            this.searchControl.setValue(this.searchTerm);
            this.router.navigate(
                [
                    `${this.platform === 'user' ? 'home' : this.platform}/${
                        this.inOnboarding ? 'start/search' : this.guestMode ? 'guest-landing/search' : 'search'
                    }`,
                ],
                {
                    queryParams: { scope: 'all', q: this.searchTerm },
                }
            );
        } else {
            this.messageService.openMessage(MessageErrorComponent, $localize`No results found`);
        }
        if (this.inOnboarding || this.guestMode) {
            setTimeout(() => window.scrollTo({ top: 800, behavior: 'smooth' }), 500);
        }
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
