<div *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon>email</mat-icon>
        <div i18n>Email</div>
    </div>
    <form [formGroup]="mailForm" fxFlex="0 0 100%" fxLayout="row wrap">
        <mat-form-field fxFlex="1 0 100%">
            <input matInput placeholder="Subject/Title" i18n-placeholder formControlName="subject" />
            <mat-error i18n="@@aSubjectIsRequired">A subject is required!</mat-error>
        </mat-form-field>
        <app-text-editor
            [subject]="mailForm.value.subject"
            (onSubject)="mailForm.controls['subject'].patchValue($event)"
            formControlName="body"
            fieldName="userWelcomeMessage"
            title="Content"
            i18n-title
            fxFlex="1 0 100%"
        >
            <mat-error i18n="@@messageBodyError">Message body is required!</mat-error>
        </app-text-editor>
        <div class="users-wrapper" *ngIf="!!users.length">
            <div class="seletec-users" i18n>Email Selected:</div>

            <div *ngFor="let user of users">
                <mat-checkbox [checked]="isSelected(user)" (change)="toggleSelection(user)">
                    <span>{{ user.name }}</span>
                </mat-checkbox>
            </div>
        </div>

        <div fxFlex="1 0 100%" fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap=".5em" class="mt-1">
            <ng-container *ngIf="loading">
                <app-saving-button></app-saving-button>
            </ng-container>
            <button *ngIf="!loading" mat-button (click)="onClose.emit()" i18n="@@cancel">Cancel</button>
            <button
                [disabled]="!mailForm.valid"
                *ngIf="!loading"
                mat-raised-button
                color="primary"
                (click)="submitMail()"
                i18n="@@sendEmail"
            >
                Send e-mail
            </button>
        </div>
    </form>
</div>
