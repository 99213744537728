import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { Customer } from '@klickdata/core/customer';
import { K3CrmService } from '@klickdata/core/k3-crm';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { ResourceTag, ResourceTagData } from '@klickdata/core/resource';
import { ResourceStaffRoles } from '@klickdata/core/resource/src/types.enum';
import { GlobalFilterColor, GlobalFilterProperty, GlobalFilterPropertyType } from '@klickdata/core/table';
import { FilterSpecs, SelectFilterOption } from '@klickdata/core/table/src/table-filter/filter';
import { TaskService } from '@klickdata/core/task';
import { User, UserData } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { PreferredCommunicationPipe } from 'apps/klickdata/src/app/shared/pipes/preferred-communication.pipe';
import { MatTableBase } from 'apps/klickdata/src/app/shared/table/mat-table-base';
import { Observable } from 'rxjs';
import { filter, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { UserListingService } from '../user-listing.service';

@Component({
    selector: 'app-connection-listing-general',
    templateUrl: './connection-listing-general.component.html',
    styleUrls: ['./connection-listing-general.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [UserListingService, { provide: MatTableBase, useExisting: ConnectionListingGeneralComponent }],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ConnectionListingGeneralComponent extends MatTableBase<User> implements OnInit, AfterViewInit {
    @Input() public columns = [
        'select',
        'priority',
        'created_by',
        'deadline',
        'assignee',
        'tags',
        'updated_at',
        'last_login',
        'pref',
        'tools',
    ];
    @Input() cacheScope = this.AppScope.USERS;
    @Input() customer: Customer;
    @Input() authUser: User;
    @Input() defaultSorting = 'updated_at';
    public noteVisibilityOptions: SelectFilterOption[];
    public expandedElement: User | null;
    public expandedExtra: User | null;
    public taskPrioOptions: SelectFilterOption[];
    @Output() onCustomerClick: EventEmitter<number> = new EventEmitter<number>();
    @Output() onEditConnection: EventEmitter<number> = new EventEmitter<number>();
    public allManagersList$: Observable<User[]>;
    public showActionHeader: boolean;
    public userStatusOptions: SelectFilterOption[];
    GlobalFilterPropertyType = GlobalFilterPropertyType;
    public selection = new SelectionModel<User>(true, []);
    public assigneeFilterSpecs: FilterSpecs = {
        property: GlobalFilterProperty.ASSIGNEES,
        label: $localize`:@@assignee:Assignee`,
        icon: 'assignment_ind',
        color: { bg: GlobalFilterColor.C2, fg: GlobalFilterColor.WHITE },
    };
    public managerFilterSpecs: FilterSpecs = {
        property: GlobalFilterProperty.MANAGERS,
        label: $localize`:@@owner:Owner`,
        icon: 'badge',
        color: { bg: GlobalFilterColor.C5, fg: GlobalFilterColor.WHITE },
    };
    public deadlineFilterSpecs: FilterSpecs = {
        property: GlobalFilterProperty.DEADLINE,
        items: [],
        composite: [
            { property: 'deadline_from', items: [] },
            { property: 'deadline_to', items: [] },
        ],
        label: $localize`:@@deadline:Deadline`,
        icon: 'event_busy',
        color: { bg: GlobalFilterColor.C4, fg: GlobalFilterColor.C1 },
        styleClass: 'global-date-filter-selector',
    };

    constructor(
        protected listService: UserListingService,
        protected mobileService: MobileService,
        protected cdr: ChangeDetectorRef,
        protected taskService: TaskService,
        protected k3CrmService: K3CrmService
    ) {
        super(cdr, mobileService, listService);
        this.taskPrioOptions = Utils.getTaskPrioOptions();
        this.noteVisibilityOptions = Utils.getNotesVisibilityOptions();
        this.userStatusOptions = Utils.getUserStatusOptions();
    }

    public ngOnInit() {
        super.ngOnInit();
        this.mobileService
            .isMobile()
            .pipe(
                takeUntil(this.destroy),
                filter((isMob) => isMob && this.columns.indexOf('assignee') !== -1)
            )
            .subscribe(() => {
                this.columns.splice(this.columns.indexOf('assignee'), 1);
                this.columns.unshift('assignee');
                this.cdr.markForCheck();
            });
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.allManagersList$ = this.listService
            .getLightMasterUsers({
                limit: 20,
                userRole: 'superadmin',
                // role: ResourceStaffRoles.MANAGER,
            })
            .pipe(shareReplay());
        this.selection.changed.pipe(takeUntil(this.destroy)).subscribe((values) => {
            this.showActionHeader = values.source.selected.length > 0;
            this.cdr.markForCheck();
        });
    }
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.data.forEach((row) => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: User): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
    }
    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }
    emailSelectedUsers() {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.EMAIL_MULTIPLE_USERS,
            data: {
                type: 'emailMultipleUsers',
                users: this.selection.selected,
            },
        });
    }

    updateDeadline(value: string, user: User) {
        this.updateTaskOfUser({ deadline: value }, user);
    }

    onCommunicationClick(communications) {
        const prefPipe = new PreferredCommunicationPipe().transform(communications);
        Utils.onCommunicationClick(prefPipe.key, prefPipe.value);
    }
    // updateTaskPrio(user: User, prio: any) {
    //     this.updateTaskOfUser({ priority: prio }, user);
    // }
    onTagClick(tag: ResourceTagData) {
        this.filter.filterByModel(new ResourceTag(tag).getFilter(), true);
    }
    onSelectPriority(prio: string, user: User, isUserPrio: boolean) {
        const userData = isUserPrio
            ? {
                  id: user.id,
                  priority: prio,
              }
            : {
                  id: user.id,
                  task: {
                      ...user.task,
                      ...{ priority: prio },
                  },
              };
        this.listService
            .update(userData, ['task,managers,tags'])
            .pipe(takeUntil(this.destroy))
            .subscribe((updatedUser) => {
                user.loading = false;
                this.dataSource.replace(updatedUser);
                this.cdr.markForCheck();
            });
    }
    onSelectSignature(manager: User, user: User, isUserSign: boolean) {
        const userData: UserData = isUserSign
            ? { id: user.id, staff: { manager: [manager.id] } }
            : {
                  id: user.id,
                  task: { ...(user.task ? user.task : {}), ...{ staff: { manager: [manager.id] } } },
              };
        this.listService
            .update(userData, ['task,tags,managers'])
            .pipe(takeUntil(this.destroy))
            .subscribe((updatedUser) => {
                user.loading = false;
                this.dataSource.replace(updatedUser);
                this.cdr.markForCheck();
            });
    }
    updateTaskOfUser(task: any, user: User) {
        this.listService
            .update(
                {
                    id: user.id,
                    task: { ...(user.task ? user.task : {}), ...task },
                },
                ['task,tags,managers']
            )
            .pipe(takeUntil(this.destroy))
            .subscribe((updatedUser) => {
                user.loading = false;
                user.task = updatedUser.task;
                this.cdr.markForCheck();
            });
    }
    onUserUpdate(user: User): void {
        this.dataSource.replace(user);
        this.expandedElement = user;
        this.cdr.markForCheck();
    }
    public addNextContact() {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.ADD_NEXT_CONTACT,
            data: {
                type: 'addNextContact',
                role: ResourceStaffRoles.CONTACT,
                title: $localize`Add new connection`,
                educatorLabel: $localize`Connection`,
                positiveBtnText: $localize`Add connection`,
                customer: this.customer,
            },
        });

        this.mobileService
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap(() => this.mobileService.getSideNavResponseData()),
                filter((data) => data.type === 'addNextContact'),
                takeUntil(this.destroy),
                map((data) => data.value)
            )
            .subscribe((educator) => {
                this.dataSource.replace(new User(educator));
                this.cdr.markForCheck();
            });
    }
    public addContact() {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.ADD_EDUCATOR,
            data: {
                type: 'addContact',
                role: ResourceStaffRoles.CONTACT,
                title: $localize`Add new contact`,
                educatorLabel: $localize`Contact`,
                positiveBtnText: $localize`Add contact`,
            },
        });

        this.mobileService
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap(() => this.mobileService.getSideNavResponseData()),
                filter((data) => data.type === 'addContact'),
                takeUntil(this.destroy),
                map((data) => data.value)
            )
            .subscribe((educator) => {
                this.dataSource.add(new User(educator));
            });
    }
    // onSelectSignature(manager: User, user: User) {
    //     // Signature in the listing is related to user not task
    //     // this.updateTaskOfUser({ staff: { manager: [manager.id] } }, user);
    //     this.listService
    //         .update(
    //             {
    //                 id: user.id,
    //                 staff: { manager: [manager.id] },
    //             },
    //             ['task']
    //         )
    //         .pipe(takeUntil(this.destroy))
    //         .subscribe((updatedUser) => {
    //             user.loading = false;
    //             user.task = updatedUser.task;
    //             this.cdr.markForCheck();
    //         });
    // }
    editContact(user: User) {
        this.onEditConnection.emit(user.id);
    }
    shareAction(user: User, action: string) {}
    emailContact(user: User) {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.SEND_MAIL,
            data: user,
        });
    }
}
