import { NGX_MAT_DATE_FORMATS, NgxMatDatetimePicker } from '@angular-material-components/datetime-picker';
import { DatePipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { APP_MOMENT_DATE_FORMATS } from '@klickdata/core/application/src/dates/app-date-format';
import { Subject, debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs';

@Component({
    selector: 'app-date-time-picker-item',
    templateUrl: './date-time-picker-item.component.html',
    styleUrls: ['./date-time-picker-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: NGX_MAT_DATE_FORMATS, useValue: APP_MOMENT_DATE_FORMATS }],
    // providers: [{ provide: NGX_MAT_DATE_FORMATS, useValue: APP_MOMENT_DATE_TIME_FORMATS }],
})
export class DateTimePickerItemComponent implements OnInit, OnDestroy {
    @ViewChild('input') input: ElementRef<HTMLInputElement>;
    @Input() set value(dateTime: any) {
        if (dateTime) {
            this.updateTimeHover(dateTime);
            this.dateTime.setValue(dateTime ? new Date(dateTime) : null, { emitEvent: false });
        }
    }
    @Input() mode: 'dateTime' | 'date' = 'dateTime'; // Default mode is 'dateTime'
    @Input() enableClear = false;
    @Input() showTimeHover: boolean = false; // Show time icon on hover
    dateTime = new FormControl(); // Reactive form control for date and time

    // Define references for datetimePicker and datePicker
    @ViewChild('datetimePicker') datetimePicker!: NgxMatDatetimePicker<any>;
    @ViewChild('datePicker') datePicker!: MatDatepicker<any>;

    public destroy: Subject<boolean> = new Subject<boolean>();
    @Output() onDateTimeChanged: EventEmitter<string> = new EventEmitter<string>();
    constructor(protected cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.dateTime.valueChanges
            .pipe(takeUntil(this.destroy), debounceTime(300), distinctUntilChanged(this.isDateEqual))
            .subscribe((value: string) => {
                const dateStr = this.dateFormat(value);
                this.updateTimeHover(dateStr);
                this.onDateTimeChanged.emit(dateStr);
            });
    }

    private updateTimeHover(time: any): void {
        const date = time ? new Date(time) : null;
        this.showTimeHover = this.mode === 'dateTime' && !!date && !(date.getHours() === 0 && date.getMinutes() === 0);
        this.cdr.markForCheck();
    }

    private dateFormat(value) {
        const chosenDate = new DatePipe('en').transform(value, this.datetimePicker ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd');
        return chosenDate;
    }

    /**
     * Compares two date values to determine if they are equal.
     * @param d1 First date
     * @param d2 Second date
     * @returns True if the dates are equal
     */
    private isDateEqual(d1: any, d2: any): boolean {
        if (!d1 || !d2) return false;
        return new Date(d1).getTime() === new Date(d2).getTime();
    }

    openPicker(): void {
        if (this.datePicker) {
            this.datePicker.open();
        }
        if (this.datetimePicker) {
            this.datetimePicker.open();
        }
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
