<div fxLayout="column" fxFlex="0 0 100%">
    <app-table-filter-expansion-panel class="connections-expansion" #filterExpansion [hasTopBorder]="true">
        <app-table-filter
            [filtersTogglesInOnBtn]="filtersTogglesInOnBtn"
            [cacheScope]="cacheScope"
            [inGlobalFilter]="true"
            color="accent"
            #filter
        >
            <app-table-search [inGlobalFilter]="true" class="align-space" searchFilterRef></app-table-search>
            <app-table-date-filter
                [filterSpecs]="deadlineFilterSpecs"
                [inGlobalFilter]="true"
                color="accent"
                deadlineFilterRef
            ></app-table-date-filter>
            <app-tag-chip-search-filter
                [inGlobalFilter]="true"
                fxFlex="50%"
                fxFlex.lt-md="100%"
                fxLayoutAlign="start"
                tagsFilterRef
            ></app-tag-chip-search-filter>
            <app-select-filter
                [inGlobalFilter]="true"
                class="filter-space"
                label="Status"
                i18n-label="@@status"
                [property]="{ name: GlobalFilterProperty.STATUS, type: GlobalFilterPropertyType.STATUS_USER }"
                [options]="userStatusOptions"
                userStatusRef
            >
            </app-select-filter>
            <app-select-filter
                [inGlobalFilter]="true"
                class="filter-space"
                label="Prio"
                i18n-label="@@prio"
                [property]="GlobalFilterProperty.TASK_PRIO"
                [options]="taskPrioOptions"
                taskPrioRef
            ></app-select-filter>
        </app-table-filter>
    </app-table-filter-expansion-panel>
    <mat-progress-bar
        class="progress-table-loader mt-1"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    >
    </mat-progress-bar>
    <div *ngIf="showActionHeader" class="header-replace">
        <div class="hover-wrapper top" fxLayoutAlign="start center">
            <button class="tools-btn" mat-button (click)="$event.stopPropagation(); emailSelectedUsers()">
                <mat-icon>email</mat-icon>
                <span class="btn-label" i18n="@@EmailSelected">Email selected</span>
            </button>
        </div>
    </div>
    <mat-table
        #table
        [dataSource]="dataSource"
        [matSortActive]="defaultSorting"
        matSortDirection="desc"
        matSort
        [class.mobileView]="isMobile"
        [class.showHeader]="showActionHeader"
        multiTemplateDataRows
    >
        <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
                <!-- <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    [aria-label]="checkboxLabel()"
                >
                </mat-checkbox> -->
            </mat-header-cell>
            <mat-cell *matCellDef="let user">
                <mat-checkbox
                    *ngIf="!user.isProcessing(); else loadingTemp"
                    (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(user) : null"
                    [checked]="selection.isSelected(user)"
                    [aria-label]="checkboxLabel(user)"
                ></mat-checkbox>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="assignee">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@name"> Name </mat-header-cell>
            <mat-cell *matCellDef="let user">
                <div *ngIf="!isMobile" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <app-image-view
                        [ngStyle]="{ minWidth: '45px' }"
                        [isCircularImg]="true"
                        [media_id]="user.media"
                        paddingBottom="45px"
                        (click)="$event.stopPropagation(); editContact(user)"
                    ></app-image-view>
                    <ng-container [ngTemplateOutlet]="userName" [ngTemplateOutletContext]="{ user: user }">
                    </ng-container>
                </div>
                <div fxFlex="grow" *ngIf="isMobile" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <app-image-view
                        [ngStyle]="{ minWidth: '45px' }"
                        [isCircularImg]="true"
                        [media_id]="user.media"
                        paddingBottom="45px"
                        (click)="$event.stopPropagation(); editContact(user)"
                    ></app-image-view>
                    <div class="mobile-main-row" fxFlex="grow" fxLayout="column" fxLayoutAlign="center start">
                        <div class="w-100" fxFlex="grow" fxLayoutAlign="start center">
                            <ng-container [ngTemplateOutlet]="userName" [ngTemplateOutletContext]="{ user: user }">
                            </ng-container>
                            <!-- <app-item-change-on-the-fly
                                *ngIf="user.task"
                                [capitalize]="true"
                                [selectionOptions]="taskPrioOptions"
                                [value]="user.task.priority"
                                (onValueChange)="user.loading = true; updateTaskPrio(user, $event)"
                            ></app-item-change-on-the-fly> -->
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px" class="signature-wrap">
                                <app-item-change-on-the-fly
                                    class="customer-item-not-task"
                                    [capitalize]="true"
                                    [selectionOptions]="taskPrioOptions"
                                    [value]="user.priority"
                                    (onValueChange)="user.loading = true; onSelectPriority($event, user, true)"
                                ></app-item-change-on-the-fly>
                                <ng-container *ngIf="user.task">
                                    <span>/</span>
                                    <app-item-change-on-the-fly
                                        class="customer-item-task"
                                        [capitalize]="true"
                                        [selectionOptions]="taskPrioOptions"
                                        [value]="user.task.priority"
                                        (onValueChange)="user.loading = true; onSelectPriority($event, user, false)"
                                    ></app-item-change-on-the-fly>
                                </ng-container>
                            </div>
                            <button
                                [matTooltip]="authUser?.getActivationMailInfo(user)?.tooltip"
                                [ngClass]="authUser?.getActivationMailInfo(user)?.class"
                                mat-icon-button
                                (click)="$event.stopPropagation(); user.customer_published && emailContact(user)"
                            >
                                <mat-icon>email</mat-icon>
                            </button>
                            <button
                                class="expand-extra-btn"
                                mat-icon-button
                                (click)="
                                    $event.stopPropagation();
                                    expandedElement = null;
                                    expandedExtra = expandedExtra === user ? null : user
                                "
                            >
                                <mat-icon>
                                    {{ expandedExtra === user ? 'expand_less' : 'expand_more' }}
                                </mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="customer">
            <mat-header-cell *matHeaderCellDef i18n="@@customer"> Customer </mat-header-cell>
            <mat-cell *matCellDef="let user">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@customer"> Customer </span>
                <div
                    class="customer-wrap-chips"
                    (click)="$event.stopPropagation(); onCustomerClick.next(user.customer.id)"
                >
                    {{ user.customer?.name }}
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="created_by">
            <mat-header-cell *matHeaderCellDef i18n> Sign </mat-header-cell>
            <mat-cell *matCellDef="let user">
                <span *ngIf="isMobile" class="mobile-label" i18n> Sign </span>
                <!-- <ng-container *ngIf="allManagersList$ | async; let allManagers">
                    <app-signature-change-on-the-fly
                        (click)="$event.stopPropagation()"
                        (onSelectSignature)="onSelectSignature($event, user)"
                        [manager]="!!user.managers?.length && user.managers[0]"
                        [allManagersList]="allManagers"
                    ></app-signature-change-on-the-fly>
                </ng-container> -->

                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px" class="signature-wrap">
                    <ng-container *ngIf="allManagersList$ | async; let allManagers">
                        <app-signature-change-on-the-fly
                            class="customer-item-not-task"
                            (click)="$event.stopPropagation()"
                            (onSelectSignature)="onSelectSignature($event, user, true)"
                            [manager]="!!user.managers?.length && user.managers[0]"
                            [allManagersList]="allManagers"
                        ></app-signature-change-on-the-fly>
                        <ng-container *ngIf="user.task">
                            <span>/</span>
                            <app-signature-change-on-the-fly
                                class="customer-item-task"
                                (click)="$event.stopPropagation()"
                                (onSelectSignature)="onSelectSignature($event, user, false)"
                                [manager]="!!user.task?.managers?.length && user.task?.managers[0]"
                                [allManagersList]="allManagers"
                            ></app-signature-change-on-the-fly>
                        </ng-container>
                    </ng-container>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="deadline">
            <mat-header-cell mat-sort-header *matHeaderCellDef i18n>Contact Date</mat-header-cell>
            <mat-cell *matCellDef="let user">
                <span *ngIf="isMobile" class="mobile-label" i18n>Contact Date</span>
                <app-date-time-picker-item
                    (click)="$event.stopPropagation()"
                    *ngIf="!user.loading"
                    [value]="user.task?.deadline"
                    (onDateTimeChanged)="updateDeadline($event, user)"
                ></app-date-time-picker-item>
                <!-- <app-date-time-picker-item fxHide [value]="user.task[0]?.deadline"></app-date-time-picker-item> -->
                <!-- <div class="contact-date-wrap">
                    <mat-form-field *ngIf="!user.loading">
                        <input
                            matInput
                            (focus)="$event.stopPropagation(); contactDate.open()"
                            (blur)="$event.stopPropagation(); contactDate.open()"
                            [value]="user.task[0]?.deadline | kdDateFormat : 'YYYY-MM-DD'"
                            (dateChange)="user.loading = true; select($event, user)"
                            [matDatepicker]="contactDate"
                        />
                        <mat-datepicker-toggle matIconSuffix [for]="contactDate">
                            <mat-icon matDatepickerToggleIcon>edit_calendar</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #contactDate disabled="false"></mat-datepicker>
                    </mat-form-field>
                </div> -->
                <ng-container *ngIf="user.loading">
                    <app-kd-logo-loading size="small"></app-kd-logo-loading>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="updated_at">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n> Last Edit </mat-header-cell>
            <mat-cell *matCellDef="let user">
                <span *ngIf="isMobile" class="mobile-label" i18n> Last Edit </span>
                {{ user.updated_at | kdDateFormat : 'YYYY-MM-DD' }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="phone">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n> Phone </mat-header-cell>
            <mat-cell *matCellDef="let user">
                <span *ngIf="isMobile" class="mobile-label" i18n> Phone </span>
                {{ user.phone || user.communications?.phone?.value }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="position_relation">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n> Position/Relation </mat-header-cell>
            <mat-cell *matCellDef="let user">
                <span *ngIf="isMobile" class="mobile-label" i18n> Position/Relation </span>
                <div fcLayout="column">
                    <span>{{ user.position }}</span>
                    <span>{{ user.relation }}</span>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="last_login">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n> Last Login </mat-header-cell>
            <mat-cell *matCellDef="let user">
                <span *ngIf="isMobile" class="mobile-label" i18n> Last Login </span>
                <span *ngIf="user.last_login">{{ user.last_login | kdDateFormat : 'YYYY-MM-DD' }}</span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="tags">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Tags</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let user">
                <span *ngIf="isMobile" class="mobile-label" i18n> Tags </span>
                <div class="shadow-maker"></div>
                <div *ngIf="!!user.tags?.length" class="tags-wrapper">
                    <div class="tags" (click)="$event.stopPropagation(); onTagClick(tag)" *ngFor="let tag of user.tags">
                        <span>{{ tag.name }}</span>
                    </div>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="priority">
            <mat-header-cell mat-sort-header *matHeaderCellDef i18n>Prio</mat-header-cell>
            <mat-cell *matCellDef="let user">
                <span *ngIf="isMobile" class="mobile-label" i18n>Prio</span>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px" class="signature-wrap">
                    <app-item-change-on-the-fly
                        class="customer-item-not-task"
                        [capitalize]="true"
                        [selectionOptions]="taskPrioOptions"
                        [value]="user.priority"
                        (onValueChange)="user.loading = true; onSelectPriority($event, user, true)"
                    ></app-item-change-on-the-fly>
                    <ng-container *ngIf="user.task">
                        <span>/</span>
                        <app-item-change-on-the-fly
                            class="customer-item-task"
                            [capitalize]="true"
                            [selectionOptions]="taskPrioOptions"
                            [value]="user.task.priority"
                            (onValueChange)="user.loading = true; onSelectPriority($event, user, false)"
                        ></app-item-change-on-the-fly>
                    </ng-container>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="pref">
            <mat-header-cell *matHeaderCellDef i18n>Prefer</mat-header-cell>
            <mat-cell *matCellDef="let user">
                <span *ngIf="isMobile" class="mobile-label" i18n>Prefer</span>
                <div *ngIf="user.communications" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <button
                        [matTooltip]="(user.communications | preferredCommunication).label"
                        i18n-matTooltip
                        mat-icon-button
                        (click)="$event.stopPropagation(); onCommunicationClick(user.communications)"
                    >
                        <img
                            class="social-media-icon"
                            [src]="'assets/images/' + (user.communications | preferredCommunication).key + '.svg'"
                        />
                    </button>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tools">
            <mat-header-cell *matHeaderCellDef i18n="@@tools"> Tools </mat-header-cell>
            <mat-cell *matCellDef="let user" (click)="$event.stopPropagation()">
                <button
                    [matTooltip]="authUser?.getActivationMailInfo(user)?.tooltip"
                    [ngClass]="authUser?.getActivationMailInfo(user)?.class"
                    mat-icon-button
                    (click)="$event.stopPropagation(); user.customer_published && emailContact(user)"
                >
                    <mat-icon>email</mat-icon>
                </button>
                <button matTooltip="Edit" mat-icon-button (click)="$event.stopPropagation(); editContact(user)">
                    <mat-icon>edit</mat-icon>
                </button>

                <button
                    *ngIf="!user.loading"
                    (click)="$event.stopPropagation(); expandedElement = expandedElement === user ? null : user"
                    matTooltip="View users"
                    mat-icon-button
                    i18n-matTooltip="@@viewUsers"
                >
                    <mat-icon>{{ expandedElement === user ? 'expand_less' : 'expand_more' }}</mat-icon>
                </button>

                <ng-container *ngIf="user.loading">
                    <app-kd-logo-loading size="small"></app-kd-logo-loading>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
            <mat-cell *matCellDef="let user" [attr.colspan]="columns.length">
                <div
                    class="example-element-detail"
                    [@detailExpand]="user == expandedElement ? 'expanded' : 'collapsed'"
                >
                    <div *ngIf="user == expandedElement" fxLayout="column">
                        <div class="expand-info mt-1" fxLayoutGap="1em" fxLayoutAlign="space-between center">
                            <div class="tags-wrapper" fxLayoutGap="10px" fxLayoutAlign="start center">
                                <!-- <ng-container *ngIf="user.task"> -->
                                <app-task-tags-handler
                                    [user]="user"
                                    (onUserUpdate)="onUserUpdate($event)"
                                ></app-task-tags-handler>
                                <!-- </ng-container> -->
                            </div>
                            <!-- <div fxLayoutGap="5px" fxLayoutAlign="start center">
                                <button
                                    matTooltip="Edit"
                                    i18n-matTooltip="@@edit"
                                    mat-icon-button
                                    (click)="$event.stopPropagation(); editContact(user)"
                                >
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </div> -->
                        </div>
                        <div class="divider"></div>
                        <!-- <app-task-notes [authUser]="authUser" [taskId]="user.task[0].id"></app-task-notes> -->
                        <app-shared-hr-notes
                            *ngIf="user == expandedElement"
                            [user]="user"
                            [hasContactType]="true"
                            [columns]="['created_at', 'author', 'contact_type', 'notes', 'tools']"
                            [noteVisibilityOptions]="noteVisibilityOptions"
                            class="customer-hr-notes-parent"
                        ></app-shared-hr-notes>
                    </div>
                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row
            [ngClass]="!user.loading ? 'link' : 'disableIt'"
            [ngClass]="{ 'expanded-extra': expandedExtra === user }"
            [class.example-expanded-row]="expandedElement === user"
            [class.loading-row]="user.loading"
            (click)="user.task && (expandedElement = expandedElement === user ? null : user)"
            *matRowDef="let user; columns: columns"
        ></mat-row>
        <mat-row
            *matRowDef="let row; columns: ['expandedDetail']"
            [ngClass]="{ 'expanded-extra-row': expandedElement === row }"
            class="example-detail-row"
        ></mat-row>
    </mat-table>
    <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
</div>

<ng-template #loadingTemp>
    <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="center" class="spinner-container">
        <app-kd-logo-loading sizeee="small"></app-kd-logo-loading>
    </div>
</ng-template>
<ng-template #userName let-user="user">
    <span
        [matTooltip]="(user | connectionStatus)?.label"
        [ngStyle]="{
            color: (user | connectionStatus)?.fg,
            background: (user | connectionStatus)?.bg
        }"
        class="user-name"
        fxFlex="grow"
        (click)="$event.stopPropagation(); editContact(user)"
        >{{ user.name }}</span
    >
</ng-template>
