import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SideNaveActionsTypes, SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { CourseItemsActionsOnNext, TakeResActionValue } from '@klickdata/player/src/next-step.service';

@Component({
    selector: 'app-menu-side-course-material-item-done',
    templateUrl: './menu-side-course-material-item-done.component.html',
    styleUrls: ['./menu-side-course-material-item-done.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideCourseMaterialItemDoneComponent {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    SideNaveActionsTypes = SideNaveActionsTypes;
    public feedback = new FormControl();
    public showInput = false;
    public doneBtnClicked = false;
    public auDoneMaterial = false;
    TakeResActionValue = TakeResActionValue;
    constructor(protected cdr: ChangeDetectorRef) {}
    onClickAction(action: CourseItemsActionsOnNext) {
        if (this.navData.data.showCustomBtnAfterClickDone && action.value == TakeResActionValue.CONTINUE) {
            this.doneBtnClicked = true;
            this.cdr.markForCheck();
        } else {
            this.onClose.emit(
                action.value
                    ? {
                          data: { type: this.navData.data.type, response: action.value, feedback: this.feedback.value },
                      }
                    : null
            );
        }
    }
    public get canGoToNextMandatory(): boolean {
        return this.navData.data?.actions?.find((action) => action.value == TakeResActionValue.GO_TO_NEXT_MANDATORY);
    }
}
