import { ChangeDetectionStrategy, Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@klickdata/core/auth';
import { CustomerLanding } from '@klickdata/core/customer';
import { Observable } from 'rxjs';
@Component({
    selector: 'app-login-signup-dialog',
    templateUrl: './login-signup-dialog.component.html',
    styleUrls: ['./login-signup-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginSignupDialogComponent implements OnInit {
    public displayMode: 'login' | 'signup' = 'login';
    public customerLanding$: Observable<CustomerLanding>;
    constructor(
        private router: Router,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<LoginSignupDialogComponent>,
        protected auth: AuthService
    ) {}

    ngOnInit(): void {
        this.customerLanding$ = this.auth.getCustomerLanding();
    }
    onLoginOrSignupSuccess() {
        this.dialogRef.close(true);
        this.router.navigate(['/home/dashboard/resource-details/' + this.data.resourceId], {
            queryParams: { start: true },
        });
    }
}
