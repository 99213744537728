import { Injectable } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { PaginatorResponse } from '@klickdata/core/http';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { LanguageService } from '@klickdata/core/localization';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { Filter } from '@klickdata/core/table';
import { Observable, Subject } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { SectionTypes } from './section-types.enum';
import { Section, SectionData } from './section.model';
import { Utils } from '@klickdata/core/util';

@Injectable({
    providedIn: 'root',
})
export class SectionService {
    protected apiURL: string;
    protected guestApiURL: string;
    private resourcesURL: string;
    protected user_id: Observable<number>;
    protected customer_id: Observable<number>;
    SectionTypes = SectionTypes;
    private _refreshFolders: Subject<number> = new Subject<number>();
    private _openFolderAssign: Subject<Section> = new Subject<Section>();

    constructor(
        protected builder: RequestBuilderService,
        protected config: ConfigService,
        protected mediaService: MediaService,
        protected langaugeService: LanguageService,
        protected auth: AuthService
    ) {
        this.apiURL = `${this.config.config.apiUrl}sections`;
        this.guestApiURL = `${this.config.config.apiUrl}guest/sections`;
        this.resourcesURL = `${this.config.config.apiUrl}resources`;
        this.user_id = this.auth.getUser().pipe(
            first(),
            map((user) => user.id)
        );

        this.customer_id = this.auth.getCustomer().pipe(
            first(),
            map((customer) => customer.id)
        );
    }

    public getSections(filters?: Filter<string | number>[]): Observable<Section[]> {
        return this.builder
            .get<SectionData[]>(this.apiURL)
            .filters(filters)
            .param('translations', 1)
            .request()
            .pipe(map((res) => this.mapSections(res.data)));
    }

    public getSectionsByCollectionId(
        colId: number,
        param: {
            query?: string;
            page?: number;
            limit?: number;
            sort?: Filter<string>;
            dir?: Filter<string>;
        }
    ): Observable<PaginatorResponse<Section[]>> {
        const request = this.builder
            .get<SectionData[]>(this.apiURL)
            .param('collection', colId)
            .param('translations', 1);

        if (param.sort && param.sort.items?.length) {
            request.param('sort', Array.isArray(param.sort.items) ? param.sort.items.join() : param.sort.items);
        }

        if (param.dir && param.dir.items?.length) {
            request.param(param.dir.property, param.dir.items.join());
        }

        if (param.query?.length) {
            request.param('query', param.query);
        }
        request.page(param.page);
        request.limit(param.limit || 25);

        return request.request().pipe(
            map((res: PaginatorResponse<SectionData[]>) => ({
                data: this.mapSections(res.data),
                paginator: res.paginator,
            }))
        );
    }

    public getUnPublicCustomerSectionsWithPaginator(
        params: {
            page?: number;
            limit?: number;
            query?: string;
        },
        customerId?: number
    ): Observable<PaginatorResponse<Section[]>> {
        const req = this.builder
            .get<SectionData[]>(this.apiURL)
            .param('customer', customerId || this.customer_id)
            .param('public', 0);
        if (params && params.page) {
            req.page(params.page);
        }
        if (params && params.limit) {
            req.limit(params.limit);
        }
        if (params.query) {
            req.param('query', params.query);
        }
        return req.request().pipe(
            map((res: PaginatorResponse<SectionData[]>) => ({
                data: this.mapSections(res.data),
                paginator: res.paginator,
            }))
        );
    }

    public getCustomerRelatedPublicSectionsWithPaginator(
        params: {
            page?: number;
            limit?: number;
            query?: string;
        },
        customerId?: number
    ): Observable<PaginatorResponse<Section[]>> {
        const req = this.builder
            .get<SectionData[]>(this.apiURL)
            .param('assignedOrCustomer', customerId || this.customer_id)
            .param('public', 1);
        if (params && params.page) {
            req.page(params.page);
        }
        if (params && params.limit) {
            req.limit(params.limit);
        }
        if (params.query) {
            req.param('query', params.query);
        }
        return req.request().pipe(
            map((res: PaginatorResponse<SectionData[]>) => ({
                data: this.mapSections(res.data),
                paginator: res.paginator,
            }))
        );
    }

    public getUserSectionsWithPaginator(params?: {
        userId?: number;
        page?: number;
        limit?: number;
    }): Observable<PaginatorResponse<Section[]>> {
        const req = this.builder.get<SectionData[]>(this.apiURL).param('user', params.userId);
        if (params && params.page) {
            req.page(params.page);
        }
        if (params && params.limit) {
            req.limit(params.limit);
        }
        return req.request().pipe(
            map((res: PaginatorResponse<SectionData[]>) => ({
                data: this.mapSections(res.data),
                paginator: res.paginator,
            }))
        );
    }

    public getSectionsAvailableForUserFromGroupOrOpen(userId: number): Observable<Section[]> {
        return this.builder
            .get<SectionData[]>(this.apiURL)
            .param('activeByCustomer', this.customer_id)
            .param('availableForUserFromGroupOrOpen', userId)
            .request()
            .pipe(map((res) => this.mapSections(res.data)));
    }

    public getSectionsAvailableForUser(): Observable<Section[]> {
        const req = this.builder
            .get<SectionData[]>(this.apiURL)
            .param('activeByCustomer', this.customer_id)
            .param('availableForUser', this.user_id);

        return req.request().pipe(map((res) => this.mapSections(res.data)));
    }
    public getSectionsAvailableForUserWithPaginator(params?: {
        page?: number;
        limit?: number;
    }): Observable<PaginatorResponse<Section[]>> {
        const req = this.builder
            .get<SectionData[]>(this.apiURL)
            .param('activeByCustomer', this.customer_id)
            .param('availableForUser', this.user_id);
        if (params && params.page) {
            req.page(params.page);
        }
        if (params && params.limit) {
            req.limit(params.limit);
        }

        return req.request().pipe(
            map((res: PaginatorResponse<SectionData[]>) => ({
                data: this.mapSections(res.data),
                paginator: res.paginator,
            }))
        );
    }
    public getGuestSections(params?: { page?: number; limit?: number }): Observable<PaginatorResponse<Section[]>> {
        const req = this.builder.get<SectionData[]>(this.guestApiURL).param('short_name', Utils.getSubdomain());
        // const req = this.builder.get<SectionData[]>(this.guestApiURL).param('short_name', 'kunskap');
        if (params && params.page) {
            req.page(params.page);
        }
        if (params && params.limit) {
            req.limit(params.limit);
        }

        return req.request().pipe(
            map((res: PaginatorResponse<SectionData[]>) => ({
                data: this.mapSections(res.data),
                paginator: res.paginator,
            }))
        );
    }

    public getSectionById(sectionId: number): Observable<Section> {
        return this.builder
            .get<SectionData[]>(`${this.apiURL}/${sectionId}`)
            .param('customer', this.customer_id)
            .request()
            .pipe(map((res) => this.createSection(res.data[0])));
    }
    public getSectionByIds(ids: number[]): Observable<PaginatorResponse<Section[]>> {
        return this.builder
            .get<SectionData[]>(`${this.apiURL}`)
            .param('ids', ids.join())
            .request()
            .pipe(
                map((res: PaginatorResponse<SectionData[]>) => ({
                    data: this.mapSections(res.data),
                    paginator: res.paginator,
                }))
            );
    }

    public store(section: SectionData) {
        return this.builder
            .post<SectionData>(`${this.apiURL}`, section)
            .request()
            .pipe(map((res) => this.createSection(res.data)));
    }

    /**
     * Method for storing multiple sections at a time.
     *
     * @param sections
     */

    public update(section: SectionData): Observable<Section> {
        return section.customer_id
            ? this.updateObs(section)
            : this.customer_id.pipe(
                  switchMap((customer_id) => this.updateObs({ ...section, customer_id: customer_id }))
              );
    }

    private updateObs(section: SectionData): Observable<any> {
        return this.builder
            .put<SectionData>(`${this.apiURL}/${section.id}`, section)
            .request()
            .pipe(map((res) => this.createSection(res.data)));
    }

    public destroy(section: Section) {
        return this.builder.delete<{ success: boolean }>(`${this.apiURL}/${section.id}`).request();
    }

    public restore(id: number): any {
        return this.builder
            .put<SectionData>(`${this.apiURL}/${id}/restore`, null)
            .request()
            .pipe(map((res) => this.createSection(res.data)));
    }

    protected mapSections(data: SectionData[]): Section[] {
        return data.map((item) => this.createSection(item));
    }

    protected createSection(data: SectionData): Section {
        const section = new Section(data);
        if (section.pref_lang_ids?.length) {
            section.pref_langs = this.langaugeService.getLanguagesByKeys(section.pref_lang_ids);
        }

        section.resourceCount$ = this.builder
            .get<number>(`${this.resourcesURL}/stats/count`)
            .param('section', data.id)
            .request()
            .pipe(map((res) => res.data));
        return section;
    }

    public refreshFolders(section_id: number) {
        this._refreshFolders.next(section_id);
    }

    public openFolderAssign(section: Section) {
        this._openFolderAssign.next(section);
    }

    public getRefreshFolder(): Observable<number> {
        return this._refreshFolders.asObservable();
    }

    public getOpenFolderAssign(): Observable<Section> {
        return this._openFolderAssign.asObservable();
    }
}
