import { AppScope } from '@klickdata/core/resource';
import { ChangeDetectionStrategy, Component, Input, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { UserNotesService } from '@klickdata/core/user-notes';
import { HrNotes } from '@klickdata/core/user-notes/src/hr-notes.model';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-shared-hr-notes-comments',
    templateUrl: './shared-hr-notes-comments.component.html',
    styleUrls: ['./shared-hr-notes-comments.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedHrNotesCommentsComponent implements OnInit, OnDestroy {
    @Input() comments: HrNotes[];
    @Input() public scope = AppScope.NOTE;
    public destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        protected mobile: MobileService,
        protected notesService: UserNotesService,
        protected message: MessageService,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {}

    public showCommentMedia(comment: HrNotes) {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.MEDIA_ATTACHMENT,
            data: {
                mediaIds: comment.media_ids,
            },
        });
    }

    updateComment(value: string, comment: HrNotes) {
        this.notesService.updateHrNote({ id: comment.id, created_at: value }).pipe(takeUntil(this.destroy)).subscribe();
    }

    public editComment(comment: HrNotes) {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_TEXT_AREA,
            data: {
                icon: 'maps_ugc',
                title: $localize`Edit Comment`,
                btnLabel: $localize`Edit comment`,
                hasMediaUploader: true,
                scope: this.scope,
                type: 'commentEdit',
                value: comment.body,
                media_ids: comment.media_ids,
            },
        });

        this.mobile
            .getSideNavResponseData()
            .pipe(
                filter((data) => data.type === 'commentEdit'),
                switchMap((data) =>
                    this.notesService.updateHrNote({ id: comment.id, body: data.value, media_ids: data.media_ids })
                ),
                takeUntil(this.destroy)
            )
            .subscribe((comment) => {
                if (comment) {
                    this.comments.splice(
                        this.comments.findIndex((com) => com.id === comment.id),
                        1,
                        comment
                    );
                    this.cdr.markForCheck();
                    this.message.openMessage(MessageSavedComponent, $localize`Note edited successfully`);
                }
            });
    }
    public deleteComment(comment: HrNotes) {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: 'delete',
                title: $localize`Delete Comment`,
                contentBody: $localize`Are you sure you want to delete comment?`,
                positiveBtn: $localize`Delete Comment`,
                negativeBtn: $localize`Cancel`,
            },
        });
        this.mobile
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap(() => this.notesService.deleteHrNote(comment.id))
            )
            .subscribe((res) => {
                if (res) {
                    this.comments.splice(
                        this.comments.findIndex((com) => com.id === comment.id),
                        1
                    );
                    this.cdr.markForCheck();
                    this.message.openMessage(MessageSavedComponent, $localize`Comment deleted successfully`);
                }
            });
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
