import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormatIsoDurationPipe } from './format-iso-duration.pipe';
import { MaterialTypeLabelPipe } from './material-type-label.pipe';
import { NameSignPipe } from './name-sign.pipe';
import { ObjectLengthPipe } from './object-length.pipe';
import { RemoveDuplicationFromArrayPipe } from './remove-duplication-from-array.pipe';
import { ResourceCertificatePipe } from './resource-certificate.pipe';
import { ResourceStatusPipe } from './resource-status.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { TypeFontIconPipe } from './type-font-icon.pipe';
import { RoundPipe } from './round.pipe';
import { InnerHtmlPipe } from './inner-html.pipe';
import { MediaToUrlPipe } from './media-to-url.pipe';
import { CurrencyTypePipe } from './currency-type.pipe';
import { CastPipe } from './cast.pipe';
import { SpaceToNewLineStringPipe } from './space-to-new-line-string.pipe';
import { LangFlagFromKeyPipe } from './lang-flag-from-key.pipe';
import { PublicAcademyPipe } from './public-academy.pipe';
import { NotificationTypePipe } from './notification-type.pipe';
import { CaseStatusPipe } from './case-status.pipe';
import { CaseStatusActionsPipe } from './case-status-actions.pipe';
import { UserRoleIconPipe } from './user-role-icon.pipe';
import { KdDateFormatPipe } from './kd-date-format.pipe';
import { ResourceExaminationTypesPipe } from './resource-examination-types.pipe';
import { PermissionAccessBadgePipe } from './permission-access-badge.pipe';
import { CapitalizeFirstLetterPipe } from './capitalize-first-letter.pipe';
import { ResourceLogEventPipe } from './resource-log-event.pipe';
import { ResourceProgressPipe } from './resource-progress.pipe';
import { ActionLogEventPipe } from './action-log-event.pipe';
import { ResourceDatesStatusPipe } from './resource-dates-status.pipe';
import { TaskTypePipe } from './task-type.pipe';
import { DateFormatLocalizedPipe } from './date-format-localized.pipe';
import { QuotesTypePipe } from './quotes-type.pipe';
import { PreferredCommunicationPipe } from './preferred-communication.pipe';
import { ClientStatusPipe } from './client-status.pipe';
import { ConnectionStatusPipe } from './connection-status.pipe';
import { FormBuilderItemIconPipe } from './form-builder-item-icon.pipe';
import { LinkDetectorPipe } from './link-detector.pipe';
import { QuestionStylePipe } from './question-style.pipe';
import { ColorsCodesByNamesPipe } from './colors-codes-by-names.pipe';
import { ResourceFieldLabelsPipe } from './resource-field-labels.pipe';
import { ResourcePermissionPipe } from './permission-permission.pipe';
import { CrmItemUserStatusPipe } from './crm-item-user-status.pipe';

@NgModule({
    declarations: [
        ObjectLengthPipe,
        SafeUrlPipe,
        SafeHtmlPipe,
        RemoveDuplicationFromArrayPipe,
        TypeFontIconPipe,
        MaterialTypeLabelPipe,
        FormatIsoDurationPipe,
        ResourceStatusPipe,
        ResourceFieldLabelsPipe,
        ResourceCertificatePipe,
        NameSignPipe,
        RoundPipe,
        InnerHtmlPipe,
        MediaToUrlPipe,
        CurrencyTypePipe,
        CastPipe,
        SpaceToNewLineStringPipe,
        LangFlagFromKeyPipe,
        PublicAcademyPipe,
        NotificationTypePipe,
        CaseStatusPipe,
        CaseStatusActionsPipe,
        UserRoleIconPipe,
        KdDateFormatPipe,
        ResourceExaminationTypesPipe,
        PermissionAccessBadgePipe,
        CapitalizeFirstLetterPipe,
        ResourceLogEventPipe,
        ResourceProgressPipe,
        ActionLogEventPipe,
        ResourceDatesStatusPipe,
        TaskTypePipe,
        DateFormatLocalizedPipe,
        QuotesTypePipe,
        PreferredCommunicationPipe,
        ClientStatusPipe,
        ConnectionStatusPipe,
        FormBuilderItemIconPipe,
        LinkDetectorPipe,
        QuestionStylePipe,
        ColorsCodesByNamesPipe,
        ResourcePermissionPipe,
        CrmItemUserStatusPipe,
    ],
    imports: [CommonModule],
    exports: [
        ObjectLengthPipe,
        SafeUrlPipe,
        SafeHtmlPipe,
        RemoveDuplicationFromArrayPipe,
        TypeFontIconPipe,
        MaterialTypeLabelPipe,
        FormatIsoDurationPipe,
        ResourceStatusPipe,
        ResourceFieldLabelsPipe,
        ResourceCertificatePipe,
        NameSignPipe,
        InnerHtmlPipe,
        MediaToUrlPipe,
        CastPipe,
        CurrencyTypePipe,
        SpaceToNewLineStringPipe,
        LangFlagFromKeyPipe,
        PublicAcademyPipe,
        NotificationTypePipe,
        CaseStatusPipe,
        CaseStatusActionsPipe,
        UserRoleIconPipe,
        KdDateFormatPipe,
        ResourceExaminationTypesPipe,
        PermissionAccessBadgePipe,
        CapitalizeFirstLetterPipe,
        ResourceLogEventPipe,
        ResourceProgressPipe,
        ActionLogEventPipe,
        ResourceDatesStatusPipe,
        TaskTypePipe,
        DateFormatLocalizedPipe,
        QuotesTypePipe,
        PreferredCommunicationPipe,
        ClientStatusPipe,
        ConnectionStatusPipe,
        FormBuilderItemIconPipe,
        LinkDetectorPipe,
        QuestionStylePipe,
        ColorsCodesByNamesPipe,
        ResourcePermissionPipe,
        CrmItemUserStatusPipe,
    ],
})
export class PipesModule {}
