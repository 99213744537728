import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SideNaveActionsTypes, SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';

@Component({
    selector: 'app-menu-side-resource-enrollment-approve',
    templateUrl: './menu-side-resource-enrollment-approve.component.html',
    styleUrls: ['./menu-side-resource-enrollment-approve.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideResourceEnrollmentApproveComponent {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    SideNaveActionsTypes = SideNaveActionsTypes;
    public value = new FormControl();
    constructor() {}
}
