import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '@klickdata/core/util';

@Pipe({
    name: 'preferredCommunication',
})
export class PreferredCommunicationPipe implements PipeTransform {
    transform(communications: {}): { value: string; preferred: boolean; key: string; label?: string } | null {
        for (const key in communications) {
            if (communications.hasOwnProperty(key)) {
                const com = communications[key];
                const prefCom = !Array.isArray(com) ? (com.preferred ? com : com.find((item) => item.preferred)) : null;

                if (prefCom) {
                    return this.joinLabel(key, prefCom); 
                }
            }
        }

        // Fallback to the first available communication
        const keys = Object.keys(communications);
        if (keys.length > 0) {
            const key = keys[0];
            const com = communications[key];
            const defaultCom = Array.isArray(com) ? com[0] : com;
            return this.joinLabel(key, defaultCom);
        }

        // Return null if no communications are available
        return null;
    }

    private joinLabel(
        key: string,
        com: { value: string; preferred: boolean }
    ): { value: string; preferred: boolean; key: string; label?: string } {
        const label = Utils.getSocialMedias().find((sm) => sm.key === key)?.name; // Optional chaining for safety
        return { ...com, key, label };
    }
}
