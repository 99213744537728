import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Filter } from '@klickdata/core/table';
import { UserActionHistory } from '@klickdata/core/user';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
    selector: 'app-resources-grid-base',
    templateUrl: './resources-grid-base.component.html',
    styleUrls: ['./resources-grid-base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourcesGridBaseComponent implements OnInit {
    @Input() tableFilterItems: Filter<any>[];
    @Input() showOccasion: boolean;
    @Input() hasThumbnailHoverEffect: boolean;
    @Input() guestMode: boolean;
    @Input() label: string;
    @Input() sectionId: number;
    @Output() onPaginatorPageChange: EventEmitter<{ pageSize: number; pageIndex: number }> = new EventEmitter<{
        pageSize: number;
        pageIndex: number;
    }>();
    @Output() onEmptyResources: EventEmitter<boolean> = new EventEmitter<boolean>();
    public pageEvent: Subject<PageEvent> = new Subject<PageEvent>();
    public resBlockWidth: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    private lastParentWidth: number;
    private resourcesCountInRow: number;
    constructor(protected storage: LocalStorageService) {}
    ngOnInit(): void {}

    onResize(width: number) {
        if (width == this.lastParentWidth) return;
        this.lastParentWidth = width;
        let limit = 0;
        if (width < 479) {
            limit = 3;
        } else if (width > 480 && width < 839) {
            limit = 4;
        } else if (width > 840 && width < 1147) {
            limit = 5;
        } else if (width > 1148 && width < 1787) {
            limit = 6;
        } else if (width > 1788 && width < 2386) {
            limit = 7;
        } else {
            limit = 8;
        }
        const sectionsPagData: any[] = this.storage.retrieve(UserActionHistory.HOME_TAB_OVERVIEW) ?? [];
        let cachedSectionPage;
        if (!!sectionsPagData.find((data) => data.sectionId == this.sectionId)) {
            cachedSectionPage = {
                pageSize: sectionsPagData.find((data) => data.sectionId == this.sectionId).pageSize,
                pageIndex: sectionsPagData.find((data) => data.sectionId == this.sectionId).pageIndex,
                length: 0,
            };
        }
        if (!this.resourcesCountInRow) {
            this.resourcesCountInRow = limit;
            this.resBlockWidth.next((width - 10) / this.resourcesCountInRow);
            this.pageEvent.next({
                pageIndex: cachedSectionPage?.pageIndex ?? 0,
                pageSize: cachedSectionPage?.pageSize ?? this.resourcesCountInRow,
                length: 0,
            });
        }
        if (!!this.resourcesCountInRow && this.resourcesCountInRow != limit) {
            this.resourcesCountInRow = limit;
            this.resBlockWidth.next((width - 10) / this.resourcesCountInRow);
            this.pageEvent.next({
                pageIndex: cachedSectionPage?.pageIndex ?? 0,
                pageSize: this.resourcesCountInRow,
                length: 0,
            });
        }
    }
}
