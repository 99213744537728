<div
    [matTooltipDisabled]="!showTimeHover"
    [matTooltip]="dateTime.value | kdDateFormat : (mode === 'dateTime' ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD')"
    class="dates-time-picker"
    fxLayout="column"
>
    <mat-form-field class="small-field mt-1" appearance="fill">
        <input
            *ngIf="mode === 'dateTime'; else datePickerInput"
            #input
            matInput
            [formControl]="dateTime"
            [ngxMatDatetimePicker]="datetimePicker"
            (focus)="openPicker()"
        />
        <ng-template #datePickerInput>
            <input #input matInput [formControl]="dateTime" [matDatepicker]="datePicker" (focus)="openPicker()" />
        </ng-template>
        <mat-icon *ngIf="showTimeHover" class="hover-time">watch_later</mat-icon>
        <mat-icon
            *ngIf="enableClear && dateTime.value"
            matDatepickerToggleIcon
            class="reset-picker"
            (click)="dateTime.setValue(null)"
            >clear</mat-icon
        >
        <mat-datepicker-toggle matSuffix [for]="$any(datePicker ?? datetimePicker)"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker *ngIf="mode === 'dateTime'" color="primary" #datetimePicker>
            <ng-template>
                <span>Done</span>
            </ng-template>
        </ngx-mat-datetime-picker>
        <mat-datepicker *ngIf="mode === 'date'" #datePicker></mat-datepicker>
    </mat-form-field>
</div>
